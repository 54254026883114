import React, {useState, useEffect, useContext, useRef} from 'react';

import chimera from '../chimera';
import { NavLink } from "react-router-dom";
import LoadingSpinner from './LoadingSpinner';
import qblogo from "../images/qblogo.png";
import syncrologo from "../images/syncrologo.png";
import unitylogo from "../images/unitylogo.png";
import pax8logo from "../images/pax8logo.png";
import duologo from "../images/duologo.png";
import Modal from "./Modal";
import ModalContext from "./ModalContext";
import BannerContext, { BannerLog } from './BannerLogContext';
import NotesInterface from './NotesInterface';
import PushSync from './PushSync';
import Pagination from './Pagination';
import ExportModal from './ExportModal';
import TaggingInterface from './TaggingInterface';
import ServiceTypesModal from './ServiceTypesModal';
import SetActiveModal from './SetActiveModal';

const CustomerRow = props => {

    return (
        <div className="row border-top">
            <div className="col-1 d-flex align-items-center justify-content-center">
                <input className=" form-check-input" type="checkbox" checked={props.isChecked} name={props.customer.accountNumber} onChange={props.handleChange}/>
            </div>
            <div className="col-9 col-md-6 col-xxl-4 text-start border-start">
                <NavLink className="text-body text-decoration-none w-100 d-inline-block hover-blue" to={`/customers/${props.customer.accountNumber}`}>
                    <b>{props.customer.displayName}</b>
                    {props.customer.formerlyKnownAs.join('; ') ? <><br/><span className="text-muted">{props.customer.formerlyKnownAs.join('; ')}</span></> : null}
                </NavLink>
            </div>
            <div className="d-none d-md-block col-md-5 col-xxl-3 border-start">
                <div className="row h-100">
                    <div className="col d-flex align-items-center justify-content-center">
                        <i className={props.customer.serviceTypes.internet ? "fas fa-check" : "fas fa-ban"}/>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                        <i className={props.customer.serviceTypes.voip ? "fas fa-check" : "fas fa-ban"}/>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                        <i className={props.customer.serviceTypes.msp ? "fas fa-check" : "fas fa-ban"}/>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                        <i className={props.customer.serviceTypes.video ? "fas fa-check" : "fas fa-ban"}/>
                    </div>
                </div>
            </div>
            <div className="border-start d-none d-xxl-block col-xxl-2">
                <div className="row">
                    <span>{props.customer.assignedTechnicians.primary.email ? `${props.customer.assignedTechnicians.primary.first} ${props.customer.assignedTechnicians.primary.last}` : '(None)'}</span>
                </div>
            </div>
            <div className="border-start d-none d-xxl-block col-xxl-2">
                <div className="row">
                    <span>{props.customer.assignedTechnicians.backup.email ? `${props.customer.assignedTechnicians.backup.first} ${props.customer.assignedTechnicians.backup.last}` : '(None)'}</span>
                </div>
            </div>
        </div>
    )
}

/**
 * @param {object} customer the Customer object
 * @param {string} searchTerm string
 * @returns {boolean} (bool) whether the customer is a positive result for the search term
 */
function searchByName(customer, searchTerm){
    const regex = makeSearchRegex(searchTerm.trim());
    return customer.displayName.match(regex) || customer.formerlyKnownAs.join('; ').match(regex);
}

/**
 * @param {object} customer the Customer object
 * @param {string} searchTerm string
 * @returns {boolean} (bool) whether the customer is a positive result for the search term
 */
function searchByAddress(customer, searchTerm){
    const regex = makeSearchRegex(searchTerm.replace(/,/g, '').trim());
    return chimera.addressStr(customer.serviceAddress).replace(/,/g, '').match(regex)  // sanitize to increase likelihood of matches
        || chimera.addressStr(customer.shippingAddress).replace(/,/g, '').match(regex) // sanitize to increase likelihood of matches
        || chimera.addressStr(customer.billingAddress).replace(/,/g, '').match(regex)  // sanitize to increase likelihood of matches
}

/**
 * @param {object} customer the Customer object
 * @param {string} searchTerm string
 * @returns {boolean} (bool) whether the customer is a positive result for the search term
 */
function searchByCid(customer, searchTerm){
    for(const cid of customer.technical.cids) {
        if(cid.includes(searchTerm)) return true;
    }
    return false;
}

/**
 * @param {object} customer the Customer object
 * @param {string} searchTerm string
 * @returns {boolean} (bool) whether the customer is a positive result for the search term
 */
function searchByIp(customer, searchTerm){
    return chimera.ipInArray(customer.technical.ips, searchTerm);
}

function makeSearchRegex(term){
    return new RegExp(`([^A-Za-z0-9]|^)${term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}`, 'ig');
}

const CustomersBody = props => {
    const [customers, setCustomers] = useState(null);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [selectedNums, setSelectedNums] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState(null);
    const [allChecked, setAllChecked] = useState(false);
    const [internetFilter, setInternetFilter] = useState("Any");
    const [voipFilter, setVoipFilter] = useState("Any");
    const [mspFilter, setMspFilter] = useState("Any");
    const [videoFilter, setVideoFilter] = useState("Any");
    const [primaryTechFilter, setPrimaryTechFilter] = useState("Any");
    const [backupTechFilter, setBackupTechFilter] = useState("Any");
    const [searchOption, setSearchOption] = useState("name");
    const [tags, setTags] = useState(null);
    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const [includeInactive, setIncludeInactive] = useState('No');
    const [users, setUsers] = useState(null);
    const selectAll = useRef(null);
    const modalContext = useContext(ModalContext);
    const banners = useContext(BannerContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if(customers !== null) {
            // Isolate keyword terms from normal search terms so they aren't treated as part of a search for a customer name
            const digest = chimera.digestQuery(query);
            const searchTerm = digest.searchTerm;
            //const keywordTerms = digest.keywordTerms;
            let newFilteredCustomers = searchTerm === "" || searchOption === "tags" ? customers : customers.filter((customer) => getSearchFilter()(customer, searchTerm));
            /**if(keywordTerms.length > 0) {
                if(digest.keywords.ip !== "") {
                    newFilteredCustomers = newFilteredCustomers.filter(customer => chimera.ipInArray(customer.technical.ips, digest.keywords.ip));
                }
            }**/
            if(internetFilter !== "Any") newFilteredCustomers = newFilteredCustomers.filter(customer => customer.serviceTypes.internet === (internetFilter === "True" ? true : false));
            if(voipFilter !== "Any") newFilteredCustomers = newFilteredCustomers.filter(customer => customer.serviceTypes.voip === (voipFilter === "True" ? true : false));
            if(mspFilter !== "Any") newFilteredCustomers = newFilteredCustomers.filter(customer => customer.serviceTypes.msp === (mspFilter === "True" ? true : false));
            if(videoFilter !== "Any") newFilteredCustomers = newFilteredCustomers.filter(customer => customer.serviceTypes.video === (videoFilter === "True" ? true : false));
            if(primaryTechFilter !== "Any") newFilteredCustomers = newFilteredCustomers.filter(customer => customer.assignedTechnicians.primary.email === primaryTechFilter);
            if(backupTechFilter !== "Any") newFilteredCustomers = newFilteredCustomers.filter(customer => customer.assignedTechnicians.backup.email === backupTechFilter);
            if(searchOption === "tags" && selectedTagIds.length > 0) newFilteredCustomers = newFilteredCustomers.filter(customer => {
                for(const tagId of selectedTagIds) {
                    if(customer.tags.includes(tagId)) return true;
                }
                return false;
            })
            setFilteredCustomers(newFilteredCustomers);
        }
        else {
            setFilteredCustomers(null);
            chimera.CommercialCustomer.getAll(signal, includeInactive === 'Yes')
            .then(customers => {
                setCustomers(customers);
            })
            .catch(e => {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('danger', <span><b>Error:</b> Error loading customers.</span>);
                }
            })
        }
        return () => {
            controller.abort();
        }
    }, [customers, query, internetFilter, voipFilter, mspFilter, videoFilter, primaryTechFilter, backupTechFilter, searchOption, selectedTagIds]);

    useEffect(() => {
        setCustomers(null);
    }, [includeInactive]);

    useEffect(() => {
        if(selectAll.current && filteredCustomers !== null && selectedNums.length === filteredCustomers.length) {
            setAllChecked(true);
            selectAll.current.indeterminate = false;
        }
        else if(selectAll.current && selectedNums.length === 0) {
            setAllChecked(false);
            selectAll.current.indeterminate = false;
        }
        else if(selectAll.current) {
            setAllChecked(true);
            selectAll.current.indeterminate = true;
        }
    }, [selectedNums]);

    useEffect(() => {
        const savedBanners = sessionStorage.getItem("banners");
        if(savedBanners) {
            banners.setBanners(JSON.parse(savedBanners));
            sessionStorage.removeItem("banners");
        }
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if(tags === null) {
            chimera.callAPI(signal, '/api/tags')
            .then(newTags => {setTags(newTags); setSelectedTagIds([])})
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read Tags, filter will be unavailable', 'Error');
            })
        }
        return () => {
            controller.abort();
        }
    }, [tags]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if(users === null) {
            chimera.callAPI(signal, '/api/users')
            .then(newUsers => setUsers(newUsers))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read Users, Technician filters will be unavailable', 'Error');
            })
        }
        return () => {
            controller.abort();
        }
    }, [users]);

    const getSearchFilter = () => {
        switch(searchOption) {
            case "address":
                return searchByAddress;
            case "ip":
                return searchByIp;
            case "cid":
                return searchByCid;
            case 'tags':
                return 
            default:
                // happens when `searchOption` == 'name'
                return searchByName;
        }
    }

    const handleChange = event => {
        if(event.target.name === "search") {
            setQuery(event.target.value);
            setPage(1);
        }
        else if(event.target.name === "selectAll"){
            if(allChecked) {
                // Uncheck all
                setSelectedNums([]);
            }
            else {
                // Check all
                let newSelectedNums = [];
                for(const customer of filteredCustomers) {
                    newSelectedNums.push(customer.accountNumber);
                }
                setSelectedNums(newSelectedNums);
            }
            setAllChecked(!allChecked);
            if(selectAll.current) {
                selectAll.current.indeterminate = false;
            }
        }
        else if(event.target.name === "internetFilter") {
            setInternetFilter(event.target.value);
        }
        else if(event.target.name === "voipFilter") {
            setVoipFilter(event.target.value);
        }
        else if(event.target.name === "mspFilter") {
            setMspFilter(event.target.value);
        }
        else if(event.target.name === "videoFilter") {
            setVideoFilter(event.target.value);
        }
        else if(event.target.name === "primaryTechFilter") {
            setPrimaryTechFilter(event.target.value);
        }
        else if(event.target.name === "backupTechFilter") {
            setBackupTechFilter(event.target.value);
        }
        else if(event.target.name === "includeInactive") {
            setIncludeInactive(event.target.value);
        }
        else if(event.target.name === "searchOption") {
            setSearchOption(event.target.value);
        }
        else if(event.target.name.includes('tag-check')) {
            const tagIndex = event.target.name.split('tag-check')[1];
            const tagId = tags[tagIndex]._id;
            if(event.target.checked && !selectedTagIds.includes(tagId)) {
                setSelectedTagIds([...selectedTagIds, tagId]);
            }
            else if(selectedTagIds.includes(tagId)) {
                setSelectedTagIds(selectedTagIds.filter(id => id !== tagId));
            }
        }
        else if(event.target.type === "checkbox") {
            if(event.target.checked && !selectedNums.includes(event.target.name)) {
                setSelectedNums(nums => [...nums, event.target.name]);
            }
            else {
                let newSelectedNums = [];
                for(const num of selectedNums) {
                    if(num !== event.target.name) {
                        newSelectedNums.push(num);
                    }
                }
                setSelectedNums(newSelectedNums);
            }
        }
    }

    const isChecked = customer => {
        return selectedNums.includes(customer.accountNumber);
    }

    const deleteSelected = event => {
        event.preventDefault();
        const loadingModal = 
            <Modal choices={[]} dismiss={(event) => {event.preventDefault(); modalContext.setModal(null)}}>
                <LoadingSpinner size={100}/>
            </Modal>;
        const choices = [
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Delete From Chimera Only</span>,
                func: async(e) => {
                    e.preventDefault();
                    modalContext.setModal(loadingModal);
                    let nDeletions = 0;
                    for(const customer of filteredCustomers) {
                        if(selectedNums.includes(customer.accountNumber)) {
                            if(await chimera.deleteCustomer(customer, {chimera: true}, banners)) {
                                nDeletions++;
                                if(selectedNums.length <= 10) {
                                    if (banners) banners.addBanner('info', `Successfully deleted customer # ${customer.accountNumber} (${customer.displayName})`);
                                }
                            }
                        }
                    }
                    if(selectedNums.length > 10) {
                        banners.addBanner('info', 'Successfully deleted customers');
                    }
                    
                    const nPagesBefore = Math.ceil(filteredCustomers.length / 20);
                    const nPagesAfter = Math.ceil((filteredCustomers.length - nDeletions) / 20);
                    if(nPagesAfter < nPagesBefore) {
                        setPage(page - (nPagesBefore - nPagesAfter));
                    }
                    setCustomers(null);
                    modalContext.setModal(null);
                }
            },
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Delete Everywhere</span>,
                func: async(e) => {
                    e.preventDefault();
                    modalContext.setModal(loadingModal);
                    let nDeletions = 0;
                    for(const customer of filteredCustomers) {
                        if(selectedNums.includes(customer.accountNumber)) {
                            if(await chimera.deleteCustomer(customer, {everywhere: true}, banners)) {
                                nDeletions++;
                                if(selectedNums.length <= 10) {
                                    if (banners) banners.addBanner('info', `Successfully deleted customer # ${customer.accountNumber} (${customer.displayName})`);
                                }
                            }
                        }
                    }
                    if(selectedNums.length > 10) {
                        banners.addBanner('info', 'Successfully deleted customers');
                    }
                    
                    const nPagesBefore = Math.ceil(filteredCustomers.length / 20);
                    const nPagesAfter = Math.ceil((filteredCustomers.length - nDeletions) / 20);
                    if(nPagesAfter < nPagesBefore) {
                        setPage(page - (nPagesBefore - nPagesAfter));
                    }
                    setCustomers(null);
                    modalContext.setModal(null);
                }
            },
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;No, Cancel</span>,
                func: (e) => {
                    e.preventDefault();
                    modalContext.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[1].func}>
            <h3>Delete Customer{selectedNums.length > 1 ? "s" : ""}?</h3>
            <p><strong>
                Are you sure you want to delete {selectedNums.length} selected customer{selectedNums.length > 1 ? "s" : ""}? <span className="text-danger">This operation cannot be undone.</span>
            </strong></p>
        </Modal>
        modalContext.setModal(modal);
    }

    const openNotesInterface = event => {
        event.preventDefault();
        if(selectedNums.length === 1) {
            let customer = null;
            for(const cust of filteredCustomers) {
                if(cust.accountNumber === selectedNums[0]) customer = cust;
            }
            const choices = [
                {
                    btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                    btnColor: "secondary",
                    func: (event) => {
                        event.preventDefault();
                        modalContext.setModal(null);
                    }
                }
            ]
            const modal = <Modal choices={choices} dismiss={choices[0].func}>
                <NotesInterface refId={customer._id}/>
            </Modal>
            modalContext.setModal(modal);
        }
    }

    const openPushModal = event => {
        event.preventDefault();
        let selectedCustomers = [];
        for(const acctNum of selectedNums) {
            for(const customer of filteredCustomers) {
                if(customer.accountNumber === acctNum) {
                    selectedCustomers.push(customer);
                    break;
                }
            }
        }
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: "secondary",
                func: (event) => {
                    event.preventDefault();
                    modalContext.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <PushSync customers={selectedCustomers}/>
        </Modal>
        modalContext.setModal(modal);
    }

    const openExportModal = (event) => {
        event.preventDefault();
        const objects = filteredCustomers.filter(customer => isChecked(customer));
        modalContext.setModal(<ExportModal objects={objects} model="customer"/>);
    }

    const openTaggingModal = (event) => {
        event.preventDefault();
        let selectedCustomers = [];
        for(const acctNum of selectedNums) {
            for(const customer of filteredCustomers) {
                if(customer.accountNumber === acctNum) {
                    selectedCustomers.push(customer);
                    break;
                }
            }
        }
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: "secondary",
                func: (event) => {
                    event.preventDefault();
                    modalContext.setModal(null);
                }
            }
        ]
        const callback = () => {
            modalContext.setModal(null);
            setCustomers(null);
        }
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <TaggingInterface customers={selectedCustomers} callback={callback}/>
        </Modal>
        modalContext.setModal(modal);
    }

    const openServiceTypesModal = (event) => {
        event.preventDefault();
        let selectedCustomers = [];
        for(const acctNum of selectedNums) {
            for(const customer of filteredCustomers) {
                if(customer.accountNumber === acctNum) {
                    selectedCustomers.push(customer);
                    break;
                }
            }
        }
        const onClose = () => {
            setCustomers(null);
        }
        modalContext.setModal(<ServiceTypesModal customerIds={selectedCustomers.map(customer => customer._id.toString())} modalContext={modalContext} onClose={onClose}/>);
    }

    const openSetActiveModal = (event) => {
        event.preventDefault();
        let selectedCustomers = [];
        for(const acctNum of selectedNums) {
            for(const customer of filteredCustomers) {
                if(customer.accountNumber === acctNum) {
                    selectedCustomers.push(customer);
                    break;
                }
            }
        }
        const onClose = () => {
            setCustomers(null);
        }
        modalContext.setModal(<SetActiveModal customers={selectedCustomers} modalContext={modalContext} onClose={onClose}/>);
    }

    return (
        <>
        {filteredCustomers !== null ? 
            <>
            <div className="d-flex flex-column mb-3">
                <div className="d-flex flex-row">
                    <select className="form-select w-fit me-1" name="searchOption" onChange={handleChange} value={searchOption}>
                        <option value="name">Name</option>
                        <option value="address">Address</option>
                        <option value="ip">IP</option>
                        <option value="cid">CID</option>
                        <option value="tags" disabled={tags === null}>Tags</option>
                    </select>
                    {searchOption !== "tags" ? 
                    <input className="form-control" type="text" name="search" placeholder="Search customers..." value={query} onChange={handleChange}/>
                    :
                    <>
                    {tags.map((tag, i) => <div key={i}><input type="checkbox" name={`tag-check${i}`} className="btn-check" autoComplete='off' id={`tag-check${i}`} checked={selectedTagIds.includes(tag._id)} onClick={handleChange}/>
                        <label className="btn btn-outline-secondary btn-sm me-1 pt-2" htmlFor={`tag-check${i}`}>{tag.name}</label>
                    </div>)}
                    </>
                    }
                </div>
                {query !== "" ? <span className="text-muted text-start">Results: {filteredCustomers.length}</span> : null}
                <div className="row">
                    <div className="col d-flex flex-column align-items-center">
                        <b>Internet:</b>
                        <select className="form-select w-fit" name="internetFilter" value={internetFilter} onChange={handleChange}>
                            <option value="Any">Any</option>
                            <option value="True">True</option>
                            <option value="False">False</option>
                        </select>
                    </div>
                    <div className="col d-flex flex-column align-items-center">
                        <b>VoIP:</b>
                        <select className="form-select w-fit" name="voipFilter" value={voipFilter} onChange={handleChange}>
                            <option value="Any">Any</option>
                            <option value="True">True</option>
                            <option value="False">False</option>
                        </select>
                    </div>
                    <div className="col d-flex flex-column align-items-center">
                        <b>MSP:</b>
                        <select className="form-select w-fit" name="mspFilter" value={mspFilter} onChange={handleChange}>
                            <option value="Any">Any</option>
                            <option value="True">True</option>
                            <option value="False">False</option>
                        </select>
                    </div>
                    <div className="col d-flex flex-column align-items-center">
                        <b>Video:</b>
                        <select className="form-select w-fit" name="videoFilter" value={videoFilter} onChange={handleChange}>
                            <option value="Any">Any</option>
                            <option value="True">True</option>
                            <option value="False">False</option>
                        </select>
                    </div>
                    <div className="col d-flex flex-column align-items-center">
                        <b>Include Inactive?:</b>
                        <select className="form-select w-fit" name="includeInactive" value={includeInactive} onChange={handleChange}>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                    <div className="col d-flex flex-column align-items-center">
                        <b>ITSE:</b>
                        <select className="form-select w-fit" name="primaryTechFilter" value={primaryTechFilter} onChange={handleChange}>
                            <option value="Any">Any</option>
                            {users !== null ? 
                            <>
                            {users.filter(user => user.groups.includes('Engineer')).map((user, i) => <option key={i} value={user.email}>{user.first} {user.last}</option>)}
                            </>
                            :null}
                        </select>
                    </div>
                    <div className="col d-flex flex-column align-items-center">
                        <b>ITSS:</b>
                        <select className="form-select w-fit" name="backupTechFilter" value={backupTechFilter} onChange={handleChange}>
                            <option value="Any">Any</option>
                            {users !== null ? 
                            <>
                            {users.filter(user => user.groups.includes('Engineer')).map((user, i) => <option key={i} value={user.email}>{user.first} {user.last}</option>)}
                            </>
                            :null}
                        </select>
                    </div>
                </div>
            </div>
            {filteredCustomers.length > 0 ?
                <>
                <div className="row mb-3">
                    <div className="col d-flex justify-content-start">
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="actionsBtn" data-bs-toggle="dropdown" aria-expanded="false" disabled={selectedNums.length === 0}>
                                Actions
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="actionsBtn">
                                <li>
                                    <button className="btn dropdown-item" onClick={openTaggingModal}>
                                        Set Tags
                                    </button>
                                </li>
                                <li>
                                    <button className="btn dropdown-item" onClick={openPushModal}>
                                        Push
                                    </button>
                                </li>
                                <li>
                                    <button className="btn dropdown-item" onClick={openNotesInterface} disabled={selectedNums.length !== 1}>
                                        Open Notes
                                    </button>
                                </li>
                                <li>
                                    <button className="btn dropdown-item" onClick={openServiceTypesModal}>
                                        Sync Service Types
                                    </button>
                                </li>
                                <li>
                                    <button className="btn dropdown-item" onClick={openExportModal}>
                                        Export
                                    </button>
                                </li>
                                <li>
                                    <button className="btn text-danger dropdown-item" onClick={openSetActiveModal}>
                                        Toggle Active/Inactive
                                    </button>
                                </li>
                                <li>
                                    <button className="btn text-danger dropdown-item" onClick={deleteSelected}>
                                        Delete
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col d-flex justify-content-end">
                        <NavLink className="btn btn-primary" to="/customers/new">
                            <i className="fas fa-plus"/>
                            &nbsp;Add Customer
                        </NavLink>
                    </div>
                </div>
                <Pagination page={page} nPages={Math.ceil(filteredCustomers.length / 20)} setPage={setPage}/>
                <div className="section-outline pb-0 mb-3">
                    <div className="row pb-2">
                        <div className="col-1 d-flex align-items-center justify-content-center">
                            <input className="form-check-input" name="selectAll" type="checkbox" onChange={handleChange} ref={selectAll} checked={allChecked}/>
                        </div>
                        <div className="col-11 col-md-6 col-xxl-4">
                            <div className="row text-start">
                                <b>Customer</b>
                            </div>
                        </div>
                        <div className="d-none d-md-block col-md-5 col-xxl-3">
                            <div className="row">
                                <div className="col">
                                    <b>ISP</b>
                                </div>
                                <div className="col">
                                    <b>VoIP</b>
                                </div>
                                <div className="col">
                                    <b>MSP</b>
                                </div>
                                <div className="col">
                                    <b>Video</b>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-xxl-block col-xxl-2">
                            <div className="row">
                                <b>ITSE</b>
                            </div>
                        </div>
                        <div className="d-none d-xxl-block col-xxl-2">
                            <div className="row">
                                <b>ITSS</b>
                            </div>
                        </div>
                    </div>
                    {filteredCustomers.sort((a, b) => a.displayName < b.displayName ? -1 : 1).slice((page * 20) - 20, (page * 20)).map((customer, i) => <CustomerRow key={i} customer={customer} isChecked={isChecked(customer)} handleChange={handleChange}/>)}
                </div>
                <Pagination page={page} nPages={Math.ceil(filteredCustomers.length / 20)} setPage={setPage}/>
                </>
            :
                <>
                <h3>No Results</h3>
                <p>
                    0 customers match your query.
                </p>
                </>
            }
            </>
        :
            <LoadingSpinner size={100}/>
        }
        </>
    )
}

const Customers = props => {
    return (
        <div className="container pb-5">
            <h1>Customers</h1>
            <BannerLog>
                <CustomersBody />
            </BannerLog>
        </div>
    )
}

export default Customers;