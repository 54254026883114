import SmartForm, {exactOptions} from '../../SmartForm';
import useSmartForm from '../../../hooks/useSmartForm';
import usePushToCustomer from '../../../hooks/usePushToCustomer';
import chimera from '../../../chimera';
import { useEffect, useRef, forwardRef } from 'react';

const VideoOrderForm = forwardRef(({type, order, parentSetModified, parentSetObj, parentSetSavedObj, onClose}, ref) => {
    const DEFAULT_ORDER = {
        type,
        customer: {
            name: '',
            ref: '',
            qbId: '',
            locationNickname: ''
        },
        status: 'New',
        estiamteStatus: 'Pending',
        serviceAddress: chimera.DEFAULT_ADDRESS,
        shippingAddress: chimera.DEFAULT_ADDRESS,
        billingAddress: chimera.DEFAULT_ADDRESS,
        poc: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },
        businessPhone: '',
        disconnectDate: '',
        notes: [],
        attachments: []
    }

    const smartFormProps = useSmartForm({initialState: order ? order : DEFAULT_ORDER, apiBase: '/api/videoorders'});
    const {workingObj, setWorkingObj, handleSave} = smartFormProps;
    const {pushToCustomer, selectedCustomer, setSelectedCustomer} = usePushToCustomer(workingObj, handleSave);
    const isFirstCustomerLoad = useRef(true);

    useEffect(() => {
        if(((selectedCustomer && selectedCustomer.accountNumber === workingObj.customer.ref) || (!workingObj.customer.ref && !selectedCustomer)) && isFirstCustomerLoad.current) {
            isFirstCustomerLoad.current = false;
        }
        else if(!isFirstCustomerLoad.current && selectedCustomer && selectedCustomer.accountNumber === workingObj.customer.ref && workingObj.customer.locationRef && workingObj.customer.locationRef !== 'ADD_NEW_LOCATION') {
            // auto-populate according to selectedCustomer
            const location = selectedCustomer.locations.find(loc => loc._id === workingObj.customer.locationRef);
            if(location) {
                const newWorkingObj = JSON.parse(JSON.stringify(workingObj));
                newWorkingObj.serviceAddress = location.serviceAddress;
                newWorkingObj.shippingAddress = location.shippingAddress;
                newWorkingObj.poc = location.poc;
                newWorkingObj.billingAddress = selectedCustomer.billingAddress;
                newWorkingObj.businessPhone = location.businessPhone;
                setWorkingObj(newWorkingObj);
            }
        }
    }, [selectedCustomer, workingObj.customer.ref, workingObj.customer.locationRef]);

    const mapFunc = (customer, locationIndex) => {
        customer.locations[locationIndex].serviceTypes.video = workingObj.type !== "Disconnect" ? true : false;
        if(workingObj.type !== "Disconnect") {
            customer.displayName = workingObj.customer.name;
            customer.businessName = workingObj.customer.name;
            customer.locations[locationIndex].serviceAddress = workingObj.serviceAddress;
            customer.locations[locationIndex].shippingAddress = workingObj.shippingAddress;
            customer.locations[locationIndex].poc = workingObj.poc;
            customer.locations[locationIndex].businessPhone = workingObj.businessPhone;
            customer.billingAddress = workingObj.billingAddress;
        }
    }

    return (
        <SmartForm ref={ref} {...smartFormProps} formId="videoOrderForm" noun="Video Order" parentSetWorkingObj={parentSetObj} parentSetModified={parentSetModified} parentSetSavedObj={parentSetSavedObj} saveAndClose onClose={onClose}>
            <SmartForm.Main>
                <h3>{`Video Order${workingObj.number ? ` #${workingObj.number}` : ''}: ${workingObj.type}`}</h3>
                <SmartForm.Section nCols={3}>
                    <SmartForm.CustomerInput isSelector={workingObj.type !== "New Customer"} setSelectedCustomer={setSelectedCustomer} path="customer" label="Customer" required fullWidth/>

                    {workingObj.type !== "New Customer" ? 
                    <SmartForm.CustomerLocationSelector path="customer" label="Customer Location" fullWidth allowAddNew={workingObj.type === "Modify Service"}/>
                    :null}

                    {workingObj.type === "Modify Service" && workingObj.customer.locationRef === "ADD_NEW_LOCATION" ?
                    <SmartForm.StringField path="customer.newLocationNickname" label="New Location Nickname" required fullWidth/>
                    :null}

                    {workingObj.type === "Disconnect" ?
                    <SmartForm.DateField path="disconnectDate" label="Disconnect Date" fullWidth/>
                    :null}
                </SmartForm.Section>
                {workingObj.type !== "Disconnect" ? 
                <>
                <SmartForm.Section nCols={3}>
                    <SmartForm.StringField path="businessPhone" label="Business Phone" fullWidth/>
                </SmartForm.Section>
                <SmartForm.Section nCols={2}>
                    <SmartForm.PocField path="poc" label="Point of Contact"/>
                    <SmartForm.AddressField path="serviceAddress" label="Service Address"/>
                    <SmartForm.AddressField path="shippingAddress" label="Shipping Address" sameAsLabel="Same as Service Address?" sameAsPath="serviceAddress"/>
                    <SmartForm.AddressField path="billingAddress" label="Billing Address" sameAsLabel="Same as Service Address?" sameAsPath="serviceAddress"/>
                </SmartForm.Section>
                </>
                :null}
                <SmartForm.AttachmentsSection path="attachments"/>
                {workingObj.blame ? <>
                <SmartForm.Divider/>
                <SmartForm.BlameSection path="blame"/>
                </>
                :null}
            </SmartForm.Main>
            <SmartForm.Sidebar>
                <SmartForm.SelectField path="status" label="Order Status" fullWidth excludeNoneSelected options={exactOptions([
                    'New',
                    'Ordered',
                    'Pending',
                    'Ready to Pay Vendor',
                    'Received',
                    'Backordered',
                    'Cancelled',
                    'Completed',
                    'Returned to Vendor',
                    'Ready for Billing',
                    'Disconnect'
                ])}/>
                <SmartForm.SelectField path="estimateStatus" label="Estimate Status" fullWidth excludeNoneSelected options={exactOptions([
                    'Pending',
                    'Requested',
                    'Sent',
                    'Approved',
                    'Denied'
                ])}/>
                <SmartForm.SaveButton fullWidth/>
                <SmartForm.ActionButton icon="fas fa-arrow-right" label="Push to Customer" pendingLabel="Pushing..." fullWidth action={pushToCustomer(workingObj, mapFunc)}/>
                <SmartForm.Notes path="notes"/>
            </SmartForm.Sidebar>
        </SmartForm>
    )
})

export default VideoOrderForm;