import React, { useEffect, useState, useRef }  from 'react';

function optionLabelForValue(value, objects, valueRule, labelRule) {
    if(objects) {
        const match = objects.find(obj => valueRule(obj) === value);
        return match ? labelRule(match) : "";
    }
    else {
        return "";
    }
}

const AutocompleteNew = ({ label, value, objects, objectChosenCallback, labelRule, valueRule, strictMode, disabled, isLoading, children, required, noSnap}) => {
    const [hoverIndex, setHoverIndex] = useState(0); // the index of the active suggestion
    const [filteredObjects, setFilteredObjects] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [inputStr, setInputStr] = useState(value ? optionLabelForValue(value, objects, valueRule, labelRule) : "");
    const firstRun = useRef(true);
    const fromKeyDown = useRef(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if(!isLoading && firstRun.current) {
            firstRun.current = false; // fake news. it just resets back to true when it rerenders after `value` changes
            let newInputStr = optionLabelForValue(value, objects, valueRule, labelRule);
            setInputStr(newInputStr);
        }
    }, [value, isLoading]);

    useEffect(() => {
        if(!isLoading && (inputStr !== "" || fromKeyDown.current)) {
            fromKeyDown.current = false;
            let match = findExactLabelMatch(inputStr);
            if(match && !noSnap) {
                if(inputStr !== labelRule(match)) setInputStr(labelRule(match));
                if(valueRule(match) !== value) objectChosenCallback(match);
                setShowSuggestions(false);
            }
            else if(!match) {
                objectChosenCallback(null);
            }
        }
    }, [inputStr, objects, isLoading]);

    useEffect(() => {
        if(objects) {
            setFilteredObjects(objects.filter(obj => labelRule(obj).toLowerCase().indexOf(inputStr.toLowerCase()) > -1).sort((a,b) => labelRule(a) < labelRule(b) ? -1 : 1));
        }
    }, [objects, inputStr]);

    const findExactLabelMatch = (label) => {
        return objects ? objects.find(obj => labelRule(obj).toLowerCase() === label.toLowerCase()) : null;
    }

    const valueIsValidOption = (value) => {
        if(objects) {
            return objects.find(obj => valueRule(obj) === value) ? true : false;
        }
        else {
            return false;
        }
    }

    const onChange = e => {
        const newInputStr = e.currentTarget.value;
        setInputStr(newInputStr);
        setShowSuggestions(true);
        setHoverIndex(0);
    };

    const clickedObject = obj => {
        setHoverIndex(0);
        setShowSuggestions(false);
        setInputStr(labelRule(obj));
        objectChosenCallback(obj);
    };

    const onKeyDown = e => {
        // User pressed the enter key
        fromKeyDown.current = true;
        if (e.keyCode === 13) {
            e.preventDefault();
            setHoverIndex(0);
            setShowSuggestions(false);
            setInputStr(labelRule(filteredObjects[hoverIndex]));
            objectChosenCallback(filteredObjects[hoverIndex]);
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (hoverIndex === 0) return;
            setHoverIndex(hoverIndex - 1);
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (hoverIndex - 1 === filteredObjects.length) return;
            setHoverIndex(hoverIndex + 1);
        }
    };

    const suggestionsListComponent = (margin) => {
        if (showSuggestions) {
            if (filteredObjects.length) {
                return (
                    <ul className={margin ? `suggestions ${margin}` : 'suggestions'}>
                        {filteredObjects.map((obj, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === hoverIndex) {
                                className = "suggestion-active";
                            }

                            return (
                            <li className={className} key={index} onClick={(e) => {e.preventDefault(); clickedObject(obj)}}>
                                {labelRule(obj)}
                            </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <span className="text-muted">This value matches no suggestions.</span>
                );
            }
        }
        else {
            return null;
        }
    }

    const validationIndicator = (margin) => {
        if(strictMode) {
            // Indicate whether the input is valid or not.
            const icon = valueIsValidOption(value) ? "fas fa-check" : "fas fa-times"
            const colorClass = valueIsValidOption(value) ? "text-success" : "text-danger"
            return (
                <>&nbsp;<span className={margin ? `${colorClass} ${margin}` : colorClass}><i className={icon}/></span></>
            )
        }
        else {
            return null;
        }
    }

    return (
        <>
        {label ?
        <label htmlFor={label} className="form-label text-start w-100 autocomplete">
            {required ? <><span className="red">*</span>&nbsp;</> : null}{label}{isLoading ? <>&nbsp;<i className="fas fa-spinner"/></> : validationIndicator()}
            <input
                className="form-control"
                type="text"
                onChange={onChange}
                onFocus={(e) => {e.preventDefault(); setShowSuggestions(true)}}
                //onBlur={(e) => {e.preventDefault(); setShowSuggestions(inputStr === "" ? false : !valueIsValidOption(value))}}
                onKeyDown={onKeyDown}
                value={inputStr}
                size={30}
                id={label}
                name={label}
                disabled={disabled || isLoading}
                ref={inputRef}
            />
            {suggestionsListComponent()}
        </label>
        :
        <>
        <div className="d-flex">
            {strictMode ? validationIndicator('me-2'): null}
            <input
                className="form-control"
                type="text"
                onChange={onChange}
                onFocus={(e) => {e.preventDefault(); setShowSuggestions(true)}}
                //onBlur={(e) => {e.preventDefault(); setShowSuggestions(inputStr === "" ? false : !valueIsValidOption(value))}}
                onKeyDown={onKeyDown}
                value={inputStr}
                size={30}
                id={label}
                name={label}
                disabled={disabled || isLoading}
                ref={inputRef}
            />
        </div>
        {suggestionsListComponent('ms-4')}
        </>
        }
        {children}
        </>
    );
}

export default AutocompleteNew;