
/**
 * 
 * @param {Array<Object>} options keys include: `label`, `action`
 * @returns 
 */
const DropdownButton = ({id, className, label, icon, action, variant, disabled, options}) => {
    return (
        <div className={`btn-group ${className ? className : ''}`}>
            <button className={`btn btn-${variant ? variant : 'primary'}`} onClick={(e) => {e.preventDefault(); action()}} disabled={disabled}>
                <i style={{marginLeft: '2rem'}} className={`fas fa-${icon ? icon : 'arrow-right'}`}/>&nbsp;{label}
            </button>
            <div className="btn-group">
                <button id={id} className={`btn btn-${variant ? variant : 'primary'} dropdown-toggle`} data-bs-toggle="dropdown" disabled={disabled}>
                    {/** No label */}
                </button>
                <ul className="dropdown-menu" aria-labelledby={id}>
                    {options.map(opt => <li key={opt.label}><a className="dropdown-item" href="#" onClick={(e) => {e.preventDefault(); opt.action()}}>{opt.label}</a></li>)}
                </ul>
            </div>
        </div>
    )
}

export default DropdownButton;