import React, {useState, useLayoutEffect, useContext} from 'react';

import chimera from '../chimera';
import LoadingSpinner from './LoadingSpinner';
import Modal from './Modal';
import ModalContext from './ModalContext';
import FormField from './FormField';
import UserContext from '../UserContext';

const ExportModal = props => {
    const [exportAs, setExportAs] = useState("Excel");
    const [loading, setLoading] = useState(false);
    const [downloads, setDownloads] = useState([]);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const modaling = useContext(ModalContext);
    const userContext = useContext(UserContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const handleChange = (event) => {
        event.preventDefault();
        if(event.target.name === "exportAs") {
            setExportAs(event.target.value);
        }
    }

    const getNoun = () => {
        switch(props.model) {
            case 'fiberLead':
                return 'Fiber Lead';
            case 'residentialLead':
                return "Residential Lead";
            case 'residentialCustomer':
                return "Residential Customer";
            case 'customer':
                return "Customer";
            case 'internetOrder':
                return "Internet Order";
            case 'voipOrder':
                return "Voip Order";
            case 'user':
                return "User";
            case 'productOrder':
                return "Product Order";
            case 'vendor':
                return "Vendor";
            default:
                return props.model;
        }
    }

    const pocsStr = (pocs) => {
        return pocs.map(poc => `${poc.firstName} ${poc.lastName}${poc.email ? ` (${poc.email})` : ''}${poc.phone ? ` ${chimera.phoneNumberStr(poc.phone)}` : ''}`).join('; ')
    }

    const headerStyle = {
        alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true
        },
        font: {
            name: "Calibri",
            size: 10,
            bold: true
        },
        border: {
            right: {
                style: "medium",
                color: "black"
            },
            left: {
                style: "medium",
                color: "black"
            },
            top: {
                style: "medium",
                color: "black"
            },
            bottom: {
                style: "medium",
                color: "black"
            }
        },
        fill: {
            type: "pattern",
            patternType: "solid",
            fgColor: "#d3d3d3"
        }
    }

    const dollarFormat = "$#,##0.00; ($#,##0.00); -";
    const dollarStyle = {
        numberFormat: dollarFormat
    }

    const excelHeaderSection = (title, subHeaders) => {
        return {
            section: {
                title: {string: title, style: headerStyle},
                cols: subHeaders
            },
        }
    }

    const excelHeader = (title, type, renderFunc, opts) => {
        if(!opts) opts = {};
        return {
            string: title,
            key: !opts.key ? title : opts.key,
            render: renderFunc,
            rowSpan: !opts.rowSpan ? 1 : opts.rowSpan,
            style: headerStyle,
            type,
            cellStyle: opts.cellStyle
        }
    }

    const excelRow = (obj, headers) => {
        let row = {};
        const fillRow = (header) => {
            let data = {[header.type]: header.render(obj)};
            if(header.cellStyle) {
                data.style = header.cellStyle;
            }
            row[header.key] = data;
        }
        for(const header of headers) {
            if(!header.section) {
                fillRow(header);
            }
            else {
                for(const col of header.section.cols) {
                    fillRow(col);
                }
            }
        }
        return row;
    }

    const exportExcel = async() => {
        let headers, data;
        setLoading(true);
        try {
            switch(props.model) {
                case 'fiberLead':
                    headers = [
                        {
                            string: "Status",
                            key: "status",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Current Step",
                            key: "currentStep",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Customer",
                            key: "customerName",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "POC", style: headerStyle},
                                cols: [
                                    {
                                        string: "First Name",
                                        key: "pocFirst",
                                        style: headerStyle
                                    },
                                    {
                                        string: "LastName",
                                        key: "pocLast",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Email",
                                        key: "pocEmail",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Phone",
                                        key: "pocPhone",
                                        style: headerStyle
                                    }
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Address", style: headerStyle},
                                cols: [
                                    {
                                        string: "Street 1",
                                        key: "street1",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Street 2",
                                        key: "street2",
                                        style: headerStyle
                                    },
                                    {
                                        string: "City",
                                        key: "city",
                                        style: headerStyle
                                    },
                                    {
                                        string: "County",
                                        key: "county",
                                        style: headerStyle
                                    },
                                    {
                                        string: "State",
                                        key: "state",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Zip",
                                        key: "zip",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Billing Address", style: headerStyle},
                                cols: [
                                    {
                                        string: "Street 1",
                                        key: "billingStreet1",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Street 2",
                                        key: "billingStreet2",
                                        style: headerStyle
                                    },
                                    {
                                        string: "City",
                                        key: "billingCity",
                                        style: headerStyle
                                    },
                                    {
                                        string: "County",
                                        key: "billingCounty",
                                        style: headerStyle
                                    },
                                    {
                                        string: "State",
                                        key: "billingState",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Zip",
                                        key: "billingZip",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            string: "Service",
                            key: "service",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Provider",
                            key: "provider",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Billing Email",
                            key: "billingEmail",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "MRC",
                            key: "mrc",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "NRC",
                            key: "nrc",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Bill Date",
                            key: "billStartDate",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "FOC Date",
                            key: "focDate",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "Technical", style: headerStyle},
                                cols: [
                                    {
                                        string: "POCs",
                                        key: "techPocs",
                                        style: headerStyle
                                    },
                                    {
                                        string: "IPs",
                                        key: "ips",
                                        style: headerStyle
                                    },
                                    {
                                        string: "CIDs",
                                        key: "cids",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Gateways",
                                        key: "gateways",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Subnet Masks",
                                        key: "subnets",
                                        style: headerStyle
                                    },
                                    {
                                        string: "CPEs",
                                        key: "cpes",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            string: "Saved Signed Retail SO?",
                            key: "savedSignedRetailSO",
                            style: headerStyle,
                            rowSpan: 1
                        },
                        {
                            string: "Saved Signed Wholesale SO?",
                            key: "savedSignedWholesaleSO",
                            style: headerStyle,
                            rowSpan: 1
                        },
                        {
                            string: "Saved SOA?",
                            key: "savedSOA",
                            style: headerStyle,
                            rowSpan: 1
                        },
                    ]
                    data = props.objects.map(lead => {return {
                        status: {string: lead.status},
                        currentStep: {number: lead.currentStep + 1},
                        customerName: {string: lead.attrs.customerName},
                        pocFirst: {string: lead.attrs.poc.firstName},
                        pocLast: {string: lead.attrs.poc.lastName},
                        pocEmail: {string: lead.attrs.poc.email},
                        pocPhone: {string: chimera.phoneNumberStr(lead.attrs.poc.phone)},
                        street1: {string: lead.attrs.customerAddress.street1},
                        street2: {string: lead.attrs.customerAddress.street2},
                        city: {string: lead.attrs.customerAddress.city},
                        county: {string: lead.attrs.customerAddress.county},
                        state: {string: lead.attrs.customerAddress.state},
                        zip: {string: lead.attrs.customerAddress.zip},
                        billingStreet1: {string: lead.attrs.billingAddress.street1},
                        billingStreet2: {string: lead.attrs.billingAddress.street2},
                        billingCity: {string: lead.attrs.billingAddress.city},
                        billingCounty: {string: lead.attrs.billingAddress.county},
                        billingState: {string: lead.attrs.billingAddress.state},
                        billingZip: {string: lead.attrs.billingAddress.zip},
                        service: {string: lead.attrs.service},
                        provider: {string: lead.attrs.provider},
                        billingEmail: {string: lead.attrs.billingEmail},
                        mrc: {number: lead.attrs.mrc, style: {numberFormat: "$#,##0.00; ($#,##0.00); -"}},
                        nrc: {number: lead.attrs.nrc, style: {numberFormat: "$#,##0.00; ($#,##0.00); -"}},
                        billStartDate: {string: lead.attrs.billStartDate},
                        focDate: {string: lead.attrs.focDate},
                        techPocs: {string: lead.attrs.techPocs.map(poc => `${poc.firstName} ${poc.lastName}${poc.email ? ` (${poc.email})` : ''}${poc.phone ? ` ${chimera.phoneNumberStr(poc.phone)}` : ''}`).join('; ')},
                        ips: {string: lead.attrs.ips.join('; ')},
                        cids: {string: lead.attrs.cids.join('; ')},
                        subnets: {string: lead.attrs.subnets.join('; ')},
                        gateways: {string: lead.attrs.gateways.join('; ')},
                        cpes: {string: lead.attrs.cpes.join('; ')},
                        savedSignedRetailSO: {bool: lead.attrs.savedSignedRetailSO},
                        savedSignedWholesaleSO: {bool: lead.attrs.savedSignedWholesaleSO},
                        savedSOA: {bool: lead.attrs.savedSOA},
                    }})
                    setLoading(true);
                    chimera.callAPI(signal, '/api/xlsx', 'POST', {
                        dataSets: [
                            {
                                worksheet: "Fiber Leads",
                                headers: headers,
                                data: data
                            }
                        ]
                    }, 'blob')
                    .then(blob => {
                        setDownloads([{title: "Fiber Leads Export", blob: blob, ext: "xlsx"}]);
                        setLoading(false);
                    })
                    .catch(err => {
                        console.error(err);
                        alert("Something went wrong and the document could not be created.");
                    })
                    break;
                case 'residentialLead':
                    headers = [
                        {
                            string: "Status",
                            key: "status",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Current Step",
                            key: "currentStep",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Zone",
                            key: "type",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "Primary Resident", style: headerStyle},
                                cols: [
                                    {
                                        string: "First Name",
                                        key: "firstName",
                                        style: headerStyle
                                    },
                                    {
                                        string: "LastName",
                                        key: "lastName",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Email",
                                        key: "email",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Phone",
                                        key: "phone",
                                        style: headerStyle
                                    }
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Home Address", style: headerStyle},
                                cols: [
                                    {
                                        string: "Street 1",
                                        key: "street1",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Street 2",
                                        key: "street2",
                                        style: headerStyle
                                    },
                                    {
                                        string: "City",
                                        key: "city",
                                        style: headerStyle
                                    },
                                    {
                                        string: "County",
                                        key: "county",
                                        style: headerStyle
                                    },
                                    {
                                        string: "State",
                                        key: "state",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Zip",
                                        key: "zip",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Mail Address", style: headerStyle},
                                cols: [
                                    {
                                        string: "Street 1",
                                        key: "mailStreet1",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Street 2",
                                        key: "mailStreet2",
                                        style: headerStyle
                                    },
                                    {
                                        string: "City",
                                        key: "mailCity",
                                        style: headerStyle
                                    },
                                    {
                                        string: "County",
                                        key: "mailCounty",
                                        style: headerStyle
                                    },
                                    {
                                        string: "State",
                                        key: "mailState",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Zip",
                                        key: "mailZip",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            string: "Authorized Users",
                            key: "authorizedUsers",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "Technical", style: headerStyle},
                                cols: [
                                    {
                                        string: "Switch Port",
                                        key: "switchPort",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Bandwidth",
                                        key: "bandwidth",
                                        style: headerStyle
                                    },
                                    {
                                        string: "IP Type",
                                        key: "ipType",
                                        style: headerStyle
                                    },
                                    {
                                        string: "IPs",
                                        key: "ips",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Gateways",
                                        key: "gateways",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Subnet Masks",
                                        key: "subnetMasks",
                                        style: headerStyle
                                    },
                                    {
                                        string: "VoIP Lines",
                                        key: "voipLines",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            string: "Conduit Installed?",
                            key: "conduitInstalled",
                            style: headerStyle,
                            rowSpan: 1
                        },
                        {
                            string: "Conduit Install Date",
                            key: "conduitInstallDate",
                            style: headerStyle,
                            rowSpan: 1
                        },
                        {
                            string: "Fiber Install Date",
                            key: "installDate",
                            style: headerStyle,
                            rowSpan: 1
                        },
                    ]
                    data = props.objects.map(lead => {return {
                        status: {string: lead.status},
                        currentStep: {number: lead.currentStep + 1},
                        type: {string: lead.attrs.type},
                        firstName: {string: lead.attrs.firstName},
                        lastName: {string: lead.attrs.lastName},
                        email: {string: lead.attrs.email},
                        phone: {string: lead.attrs.phone ? chimera.phoneNumberStr(lead.attrs.phone) : ""},
                        street1: {string: lead.attrs.homeAddress.street1},
                        street2: {string: lead.attrs.homeAddress.street2},
                        city: {string: lead.attrs.homeAddress.city},
                        county: {string: lead.attrs.homeAddress.county},
                        state: {string: lead.attrs.homeAddress.state},
                        zip: {string: lead.attrs.homeAddress.zip},
                        mailStreet1: {string: lead.attrs.mailAddress.street1},
                        mailStreet2: {string: lead.attrs.mailAddress.street2},
                        mailCity: {string: lead.attrs.mailAddress.city},
                        mailCounty: {string: lead.attrs.mailAddress.county},
                        mailState: {string: lead.attrs.mailAddress.state},
                        mailZip: {string: lead.attrs.mailAddress.zip},
                        authorizedUsers: {string: lead.attrs.authorizedUsers.map(user => `${user.firstName} ${user.lastName}${user.email ? ` (${user.email})` : ''}${user.phone ? ` ${chimera.phoneNumberStr(user.phone)}` : ''}`).join('; ')},
                        switchPort: {string: lead.attrs.technical.switchPort},
                        bandwidth: {string: lead.attrs.technical.bandwidth},
                        ipType: {string: lead.attrs.technical.ipType},
                        ips: {string: lead.attrs.technical.ips.join('; ')},
                        gateways: {string: lead.attrs.technical.gateways.join('; ')},
                        subnetMasks: {string: lead.attrs.technical.subnetMasks.join('; ')},
                        voipLines: {number: lead.attrs.technical.voipLines},
                        conduitInstalled: {bool: lead.attrs.conduitInstalled},
                        conduitInstallDate: {string: lead.attrs.conduitInstallDate},
                        installDate: {string: lead.attrs.installDate}
                    }});
                    break;
                case 'residentialCustomer':
                    headers = [
                        {
                            string: "Account Number",
                            key: "accountNumber",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Zone",
                            key: "type",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "Primary Resident", style: headerStyle},
                                cols: [
                                    {
                                        string: "First Name",
                                        key: "firstName",
                                        style: headerStyle
                                    },
                                    {
                                        string: "LastName",
                                        key: "lastName",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Email",
                                        key: "email",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Phone",
                                        key: "phone",
                                        style: headerStyle
                                    }
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Home Address", style: headerStyle},
                                cols: [
                                    {
                                        string: "Street 1",
                                        key: "street1",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Street 2",
                                        key: "street2",
                                        style: headerStyle
                                    },
                                    {
                                        string: "City",
                                        key: "city",
                                        style: headerStyle
                                    },
                                    {
                                        string: "County",
                                        key: "county",
                                        style: headerStyle
                                    },
                                    {
                                        string: "State",
                                        key: "state",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Zip",
                                        key: "zip",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Mail Address", style: headerStyle},
                                cols: [
                                    {
                                        string: "Street 1",
                                        key: "mailStreet1",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Street 2",
                                        key: "mailStreet2",
                                        style: headerStyle
                                    },
                                    {
                                        string: "City",
                                        key: "mailCity",
                                        style: headerStyle
                                    },
                                    {
                                        string: "County",
                                        key: "mailCounty",
                                        style: headerStyle
                                    },
                                    {
                                        string: "State",
                                        key: "mailState",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Zip",
                                        key: "mailZip",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            string: "Authorized Users",
                            key: "authorizedUsers",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "Service Types", style: headerStyle},
                                cols: [
                                    {
                                        string: "Internet?",
                                        key: "internet",
                                        style: headerStyle
                                    },
                                    {
                                        string: "VoIP?",
                                        key: "voip",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Integration IDs", style: headerStyle},
                                cols: [
                                    {
                                        string: "QuickBooks",
                                        key: "quickbooks",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Syncro",
                                        key: "syncro",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Unity",
                                        key: "unity",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Technical", style: headerStyle},
                                cols: [
                                    {
                                        string: "Switch Port",
                                        key: "switchPort",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Bandwidth",
                                        key: "bandwidth",
                                        style: headerStyle
                                    },
                                    {
                                        string: "IP Type",
                                        key: "ipType",
                                        style: headerStyle
                                    },
                                    {
                                        string: "IPs",
                                        key: "ips",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Gateways",
                                        key: "gateways",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Subnet Masks",
                                        key: "subnetMasks",
                                        style: headerStyle
                                    },
                                    {
                                        string: "VoIP Lines",
                                        key: "voipLines",
                                        style: headerStyle
                                    },
                                ]
                            }
                        }
                    ]
                    data = props.objects.map(customer => {return {
                        accountNumber: {string: customer.accountNumber},
                        type: {string: customer.type},
                        firstName: {string: customer.firstName},
                        lastName: {string: customer.lastName},
                        email: {string: customer.email},
                        phone: {string: chimera.phoneNumberStr(customer.phone)},
                        street1: {string: customer.homeAddress.street1},
                        street2: {string: customer.homeAddress.street2},
                        city: {string: customer.homeAddress.city},
                        county: {string: customer.homeAddress.county},
                        state: {string: customer.homeAddress.state},
                        zip: {string: customer.homeAddress.zip},
                        mailStreet1: {string: customer.mailAddress.street1},
                        mailStreet2: {string: customer.mailAddress.street2},
                        mailCity: {string: customer.mailAddress.city},
                        mailCounty: {string: customer.mailAddress.county},
                        mailState: {string: customer.mailAddress.state},
                        mailZip: {string: customer.mailAddress.zip},
                        authorizedUsers: {string: pocsStr(customer.authorizedUsers)},
                        internet: {bool: customer.serviceTypes.internet},
                        voip: {bool: customer.serviceTypes.voip},
                        quickbooks: {string: customer.integrationIds.quickbooks},
                        syncro: {string: customer.integrationIds.syncro},
                        unity: {string: customer.integrationIds.unity},
                        switchPort: {string: customer.technical.switchPort},
                        bandwidth: {string: customer.technical.bandwidth},
                        ipType: {string: customer.technical.ipType},
                        ips: {string: customer.technical.ips.join('; ')},
                        gateways: {string: customer.technical.gateways.join('; ')},
                        subnetMasks: {string: customer.technical.subnetMasks.join('; ')},
                        voipLines: {number: customer.technical.voipLines},
                        standing: {string: customer.standing}
                    }});
                    break;
                case 'customer':
                    data = [];
                    headers = [
                        excelHeader('Account Number', 'string', (row) => row.customer.accountNumber),
                        excelHeader('Standing', 'string', (row) => row.customer.standing),
                        excelHeader('Business Name', 'string', (row) => row.customer.businessName),
                        excelHeader('Display Name', 'string', (row) => row.customer.displayName),
                        excelHeader('On-Call/After Hours', 'string', (row) => row.customer.onCallAfterHours),
                        excelHeader('ITSE', 'string', (row) => `${row.customer.assignedTechnicians.primary.first} ${row.customer.assignedTechnicians.primary.last}`),
                        excelHeader('ITSS', 'string', (row) => `${row.customer.assignedTechnicians.backup.first} ${row.customer.assignedTechnicians.backup.last}`),
                        excelHeader('Location', 'string', (row) => row.location.nickname),
                        excelHeaderSection('Service Address', [
                            excelHeader('Street 1', 'string', (row) => row.customer.serviceAddress.street1, {key: 'servAddrStreet1', rowSpan: 0}),
                            excelHeader('Street 2', 'string', (row) => row.customer.serviceAddress.street2, {key: 'servAddrStreet2', rowSpan: 0}),
                            excelHeader('City', 'string', (row) => row.customer.serviceAddress.city, {key: 'servAddrCity', rowSpan: 0}),
                            excelHeader('County', 'string', (row) => row.customer.serviceAddress.county, {key: 'servAddrCounty', rowSpan: 0}),
                            excelHeader('State', 'string', (row) => row.customer.serviceAddress.state, {key: 'servAddrState', rowSpan: 0}),
                            excelHeader('Zip', 'string', (row) => row.customer.serviceAddress.zip, {key: 'servAddrZip', rowSpan: 0})
                        ]),
                        excelHeaderSection('Shipping Address', [
                            excelHeader('Street 1', 'string', (row) => row.customer.shippingAddress.street1, {key: 'shipAddrStreet1', rowSpan: 0}),
                            excelHeader('Street 2', 'string', (row) => row.customer.shippingAddress.street2, {key: 'shipAddrStreet2', rowSpan: 0}),
                            excelHeader('City', 'string', (row) => row.customer.shippingAddress.city, {key: 'shipAddrCity', rowSpan: 0}),
                            excelHeader('County', 'string', (row) => row.customer.shippingAddress.county, {key: 'shipAddrCounty', rowSpan: 0}),
                            excelHeader('State', 'string', (row) => row.customer.shippingAddress.state, {key: 'shipAddrState', rowSpan: 0}),
                            excelHeader('Zip', 'string', (row) => row.customer.shippingAddress.zip, {key: 'shipAddrZip', rowSpan: 0})
                        ]),
                        excelHeaderSection('POC', [
                            excelHeader('First Name', 'string', (row) => row.location.poc.firstName, {key: 'pocFirst', rowSpan: 0}),
                            excelHeader('Last Name', 'string', (row) => row.location.poc.lastName, {key: 'pocLast', rowSpan: 0}),
                            excelHeader('Email', 'string', (row) => row.location.poc.email, {key: 'pocEmail', rowSpan: 0}),
                            excelHeader('Phone', 'string', (row) => chimera.phoneNumberStr(row.location.poc.phone), {key: 'pocPhone', rowSpan: 0}),
                        ]),
                        excelHeader('Notification Emails', 'string', (row) => row.location.notificationEmails.join(', ')),
                        excelHeader('Business Phone', 'string', (row) => chimera.phoneNumberStr(row.location.businessPhone)),
                        excelHeaderSection('Service Types', [
                            excelHeader('Internet?', 'bool', (row) => row.location.serviceTypes.internet, {rowSpan: 0}),
                            excelHeader('VoIP?', 'bool', (row) => row.location.serviceTypes.voip, {rowSpan: 0}),
                            excelHeader('MSP', 'bool', (row) => row.location.serviceTypes.msp, {rowSpan: 0}),
                            excelHeader('Video', 'bool', (row) => row.location.serviceTypes.video, {rowSpan: 0})
                        ]),
                        excelHeaderSection('Integration IDs', [
                            excelHeader('QuickBooks', 'string', (row) => row.customer.integrationIds.quickbooks, {rowSpan: 0}),
                            excelHeader('Syncro', 'string', (row) => row.customer.integrationIds.syncro, {rowSpan: 0}),
                            excelHeader('Unity', 'string', (row) => row.customer.integrationIds.unity, {rowSpan: 0}),
                            excelHeader('Pax8', 'string', (row) => row.customer.integrationIds.pax8, {rowSpan: 0}),
                            excelHeader('Duo', 'string', (row) => row.customer.integrationIds.duo, {rowSpan: 0})
                        ]),
                        excelHeaderSection('MSP Information (General)', [
                            excelHeader('Email Provider', 'string', (row) => row.location.technical.general.emailProvider, {rowSpan: 0}),
                            excelHeader('Cloud/Hosting Provider', 'string', (row) => row.location.technical.general.cloudHostingProvider, {rowSpan: 0}),
                            excelHeader('VoIP Provider', 'string', (row) => row.location.technical.general.voipProvider, {rowSpan: 0}),
                            excelHeader('Registrar', 'string', (row) => row.location.technical.general.registrar, {rowSpan: 0}),
                            excelHeader('Domain Name', 'string', (row) => row.location.technical.general.domainName, {rowSpan: 0}),
                            excelHeader('Dental?', 'bool', (row) => row.location.technical.general.dental, {rowSpan: 0}),
                            excelHeader('Technical POCs', 'string', (row) => pocsStr(row.location.technical.pocs), {rowSpan: 0}),
                        ]),
                        excelHeaderSection('MSP Information (Dental)', [
                            excelHeader('PMS', 'string', (row) => row.location.technical.dental.pms, {rowSpan: 0}),
                            excelHeader('PMS Support #', 'string', (row) => row.location.technical.dental.pmsSupportNumber, {rowSpan: 0}),
                            excelHeader('PMS Account #', 'string', (row) => row.location.technical.dental.pmsAccountNumber, {rowSpan: 0}),
                            excelHeader('2D XRAY Vendor', 'string', (row) => row.location.technical.dental.xrayVendor2D, {rowSpan: 0}),
                            excelHeader('2D XRAY Vendor Support #', 'string', (row) => row.location.technical.dental.xrayVendor2DSupportNumber, {rowSpan: 0}),
                            excelHeader('2D XRAY Vendor Acccount #', 'string', (row) => row.location.technical.dental.xrayVendor2DAccountNumber, {rowSpan: 0}),
                            excelHeader('3D XRAY Vendor', 'string', (row) => row.location.technical.dental.xrayVendor3D, {rowSpan: 0}),
                            excelHeader('3D XRAY Vendor Support #', 'string', (row) => row.location.technical.dental.xrayVendor3DSupportNumber, {rowSpan: 0}),
                            excelHeader('3D XRAY Vendor Acccount #', 'string', (row) => row.location.technical.dental.xrayVendor3DAccountNumber, {rowSpan: 0}),
                        ]),
                        excelHeader('Software Vendors', 'string', (row) => row.location.technical.softwareVendors.map(sv => sv.title).join('; ')),
                        excelHeaderSection('Non-CBIT Internet Providers', [
                            excelHeader('ISP', 'string', (row) => row.isp.isp, {rowSpan: 0}),
                            excelHeader('ISP Support #', 'string', (row) => row.isp.ispSupportNumber, {rowSpan: 0}),
                            excelHeader('ISP Support Email', 'string', (row) => row.isp.ispSupportEmail, {rowSpan: 0}),
                            excelHeader('WAN IP', 'string', (row) => row.isp.wanIp, {rowSpan: 0}),
                            excelHeader('WAN Subnet', 'string', (row) => row.isp.wanSubnet, {rowSpan: 0}),
                            excelHeader('WAN Gateway', 'string', (row) => row.isp.wanGateway, {rowSpan: 0}),
                            excelHeader('CID', 'string', (row) => row.isp.cid, {rowSpan: 0}),
                            excelHeader('Service', 'string', (row) => row.isp.service, {rowSpan: 0}),
                            excelHeader('DHCP?', 'bool', (row) => row.isp.dhcp, {rowSpan: 0}),
                            excelHeader('Equipment Location', 'string', (row) => row.isp.equipmentLocation, {rowSpan: 0}),
                            excelHeader('Service Port', 'string', (row) => row.isp.servicePort, {rowSpan: 0}),
                        ]),
                        excelHeaderSection('CBIT Internet Information', [
                            excelHeader('Provider', 'string', (row) => row.location.technical.provider, {rowSpan: 0}),
                            excelHeader('Service', 'string', (row) => row.location.technical.service, {key: 'cbitService', rowSpan: 0}),
                            excelHeader('Management IP', 'string', (row) => row.location.technical.managementIp, {rowSpan: 0}),
                            excelHeader('DHCP?', 'bool', (row) => row.location.technical.dhcp, {key: 'cbitDhcp', rowSpan: 0}),
                            excelHeader('CIDs', 'string', (row) => row.location.technical.cids.join('; '), {rowSpan: 0}),
                            excelHeader('IPs', 'string', (row) => row.useClaims ? row.claimedIps.map(entry => entry.ip).join('; ') : row.location.technical.ips.join('; '), {rowSpan: 0}),
                            excelHeader('Gateways', 'string', (row) => row.useClaims ? row.claimedIps.map(entry => entry.gateway).join('; ') : row.location.technical.gateways.join('; '), {rowSpan: 0}),
                            excelHeader('Subnet Masks', 'string', (row) => row.useClaims ? row.claimedIps.map(entry => entry.subnetMask).join('; ') : row.location.technical.subnetMasks.join('; '), {rowSpan: 0}),
                            excelHeader('Equipment Location', 'string', (row) => row.location.technical.equipmentLocation, {key: 'cbitEqLoc', rowSpan: 0}),
                            excelHeader('Service Port', 'string', (row) => row.location.technical.servicePort, {key: 'cbitServPort', rowSpan: 0})
                        ]),
                        excelHeaderSection('VoIP Information', [
                            excelHeader('# of Lines', 'number', (row) => row.location.technical.voipLines, {rowSpan: 0}),
                        ]),
                    ]

                    if(userContext.permissions.financial.read || userContext.permissions.admin.read) {
                        headers = [].concat(headers, [
                            excelHeaderSection('Financial', [
                                excelHeader('Billing POCs', 'string', (row) => pocsStr(row.customer.billingPocs), {rowSpan: 0}),
                                excelHeader('Billing Phone', 'string', (row) => chimera.phoneNumberStr(row.customer.billingPhone), {rowSpan: 0}),
                                excelHeader('Accounts Payable Emails', 'string', (row) => row.customer.accountsPayableEmails.join('; '), {rowSpan: 0}),
                                excelHeader('Standard Labor Rate', 'number', (row) => row.customer.standardRate, {cellStyle: dollarStyle, rowSpan: 0}),
                                excelHeader('After Hours Labor Rate', 'number', (row) => row.customer.afterHoursRate, {cellStyle: dollarStyle, rowSpan: 0}),
                                excelHeader('Labor Credit Hours', 'number', (row) => row.customer.laborCreditHours, {rowSpan: 0}),
                                excelHeader('Tax Exempt?', 'bool', (row) => row.customer.taxExempt, {rowSpan: 0}),
                            ]),
                            excelHeaderSection('Billing Address', [
                                excelHeader('Street 1', 'string', (row) => row.customer.billingAddress.street1, {key: 'billAddrStreet1', rowSpan: 0}),
                                excelHeader('Street 2', 'string', (row) => row.customer.billingAddress.street2, {key: 'billAddrStreet2', rowSpan: 0}),
                                excelHeader('City', 'string', (row) => row.customer.billingAddress.city, {key: 'billAddrCity', rowSpan: 0}),
                                excelHeader('County', 'string', (row) => row.customer.billingAddress.county, {key: 'billAddrCounty', rowSpan: 0}),
                                excelHeader('State', 'string', (row) => row.customer.billingAddress.state, {key: 'billAddrState', rowSpan: 0}),
                                excelHeader('Zip', 'string', (row) => row.customer.billingAddress.zip, {key: 'billAddrZip', rowSpan: 0})
                            ]),
                        ])
                    }

                    for(const customer of props.objects) {
                        for(const location of customer.locations) {
                            let claimedIps = null;
                            const useClaims = !location.technical.dhcp && (location.technical.provider === "COR" || location.technical.provider === "CBIT");
                            if(useClaims) {
                                try {
                                    claimedIps = await chimera.callAPI(undefined, '/api/iplists/getclaims', 'POST', {stamp: {objectType: 'CustomerLocation', objectId: location._id}});
                                }
                                catch(err) {
                                    console.error(err);
                                    alert(`ERROR: Failed to fetch IP claims for ${customer.displayName} location: ${location.nickname} -- export will be incomplete`);
                                    claimedIps = [];
                                }
                            }
    
                            if(location.technical.general.isps.length > 0) {
                                for(const isp of location.technical.general.isps) {
                                    const row = {
                                        customer,
                                        location,
                                        useClaims,
                                        claimedIps,
                                        isp
                                    }
                                    data.push(excelRow(row, headers));
                                }
                            }
                            else {
                                const row = {
                                    customer,
                                    location,
                                    useClaims,
                                    claimedIps,
                                    isp: {
                                        isp: '',
                                        ispSupportNumber: '',
                                        ispSupportEmail: '',
                                        wanIp: '',
                                        wanSubnet: '',
                                        wanGateway: '',
                                        cid: '',
                                        servicePort: '',
                                        equipmentLocation: '',
                                        dhcp: false,
                                        service: 'NOT SET'
                                    }
                                }
                                data.push(excelRow(row, headers));
                            }
    
                            /**
                            data.push({
                                accountNumber: {string: customer.accountNumber},
                                standing: {string: customer.standing},
                                businessName: {string: customer.businessName},
                                displayName: {string: customer.displayName},
                                location: {string: location.nickname},
                                pocFirst: {string: location.poc.firstName},
                                pocLast: {string: location.poc.lastName},
                                pocEmail: {string: location.poc.email},
                                pocPhone: {string: chimera.phoneNumberStr(location.poc.phone)},
                                notificationEmails: {string: location.notificationEmails.join(', ')},
                                businessPhone: {string: chimera.phoneNumberStr(location.businessPhone)},
                                billingPhone: {string: chimera.phoneNumberStr(customer.billingPhone)},
                                billingStreet1: {string: customer.billingAddress.street1},
                                billingStreet2: {string: customer.billingAddress.street2},
                                billingCity: {string: customer.billingAddress.city},
                                billingCounty: {string: customer.billingAddress.county},
                                billingState: {string: customer.billingAddress.state},
                                billingZip: {string: customer.billingAddress.zip},
                                serviceStreet1: {string: location.serviceAddress.street1},
                                serviceStreet2: {string: location.serviceAddress.street2},
                                serviceCity: {string: location.serviceAddress.city},
                                serviceCounty: {string: location.serviceAddress.county},
                                serviceState: {string: location.serviceAddress.state},
                                serviceZip: {string: location.serviceAddress.zip},
                                shippingStreet1: {string: location.shippingAddress.street1},
                                shippingStreet2: {string: location.shippingAddress.street2},
                                shippingCity: {string: location.shippingAddress.city},
                                shippingCounty: {string: location.shippingAddress.county},
                                shippingState: {string: location.shippingAddress.state},
                                shippingZip: {string: location.shippingAddress.zip},
                                internet: {bool: location.serviceTypes.internet},
                                voip: {bool: location.serviceTypes.voip},
                                msp: {bool: location.serviceTypes.msp},
                                billingPocs: {string: pocsStr(customer.billingPocs)},
                                taxExempt: {bool: customer.taxExempt},
                                quickbooks: {string: customer.integrationIds.quickbooks},
                                syncro: {string: customer.integrationIds.syncro},
                                bitdefender: {string: customer.integrationIds.bitdefender},
                                unity: {string: customer.integrationIds.unity},
                                pax8: {string: customer.integrationIds.pax8},
                                duo: {string: customer.integrationIds.duo},
                                networkSummary: {string: location.technical.networkSummary.replace(/\n/g, '; ')},
                                cids: {string: location.technical.cids.join('; ')},
                                ips: {string: useClaims ? claimedIps.map(entry => entry.ip).join('; ') : location.technical.ipList.map(entry => entry.ip).join('; ')},
                                gateways: {string: useClaims ? claimedIps.map(entry => entry.gateway).join('; ') : location.technical.ipList.map(entry => entry.gateway).join('; ')},
                                subnetMasks: {string: useClaims ? claimedIps.map(entry => entry.subnetMask).join('; ') : location.technical.ipList.map(entry => entry.subnetMask).join('; ')},
                                managementIp: {string: location.technical.managementIp},
                                pops: {string: useClaims ? claimedIps.map(entry => entry.pop).join('; ') : location.technical.ipList.map(entry => entry.pop).filter((value, index, self) => self.indexOf(value) === index).join('; ')},
                                service: {string: location.technical.service},
                                provider: {string: location.technical.provider},
                                voipLines: {number: location.technical.voipLines},
                                equipmentLocation: {string: location.technical.equipmentLocation},
                                techPocs: {string: pocsStr(location.technical.pocs)},
                                laborCreditHours: {number: customer.laborCreditHours},
                                onCallAfterHours: {string: customer.onCallAfterHours},
                                standardRate: {number: customer.standardRate, style: {numberFormat: "$#,##0.00; ($#,##0.00); -"}},
                                afterHoursRate: {number: customer.afterHoursRate, style: {numberFormat: "$#,##0.00; ($#,##0.00); -"}},
                                primaryTech: {string: `${customer.assignedTechnicians.primary.first} ${customer.assignedTechnicians.primary.last}`},
                                backupTech: {string: `${customer.assignedTechnicians.backup.first} ${customer.assignedTechnicians.backup.last}`}
                            })
                            */
                        }
                    }
                    break;
                case 'internetOrder':
                    headers = [
                        {
                            string: "Type",
                            key: "type",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Status",
                            key: "status",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Customer ID",
                            key: "customerID",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Customer Name",
                            key: "customerName",
                            rowSpan: 1,
                            style: headerStyle
                        },
                        {
                            string: "Service",
                            key: "service",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "Service Address", style: headerStyle},
                                cols: [
                                    {
                                        string: "Street 1",
                                        key: "serviceStreet1",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Street 2",
                                        key: "serviceStreet2",
                                        style: headerStyle
                                    },
                                    {
                                        string: "City",
                                        key: "serviceCity",
                                        style: headerStyle
                                    },
                                    {
                                        string: "County",
                                        key: "serviceCounty",
                                        style: headerStyle
                                    },
                                    {
                                        string: "State",
                                        key: "serviceState",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Zip",
                                        key: "serviceZip",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            string: "Business Phone",
                            key: "businessPhone",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "POC", style: headerStyle},
                                cols: [
                                    {
                                        string: "First Name",
                                        key: "pocFirst",
                                        style: headerStyle
                                    },
                                    {
                                        string: "LastName",
                                        key: "pocLast",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Email",
                                        key: "pocEmail",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Phone",
                                        key: "pocPhone",
                                        style: headerStyle
                                    }
                                ]
                            }
                        },
                        {
                            string: "Provider",
                            key: "provider",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "FOC Date",
                            key: "focDate",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Wholesale Status",
                            key: "wholesaleStatus",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Retail Status",
                            key: "retailStatus",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "Billing Address", style: headerStyle},
                                cols: [
                                    {
                                        string: "Street 1",
                                        key: "billingStreet1",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Street 2",
                                        key: "billingStreet2",
                                        style: headerStyle
                                    },
                                    {
                                        string: "City",
                                        key: "billingCity",
                                        style: headerStyle
                                    },
                                    {
                                        string: "County",
                                        key: "billingCounty",
                                        style: headerStyle
                                    },
                                    {
                                        string: "State",
                                        key: "billingState",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Zip",
                                        key: "billingZip",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Billing POC", style: headerStyle},
                                cols: [
                                    {
                                        string: "First Name",
                                        key: "billingPocFirst",
                                        style: headerStyle
                                    },
                                    {
                                        string: "LastName",
                                        key: "billingPocLast",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Email",
                                        key: "billingPocEmail",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Phone",
                                        key: "billingPocPhone",
                                        style: headerStyle
                                    }
                                ]
                            }
                        },
                        {
                            string: "MRC",
                            key: "mrc",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "NRC",
                            key: "nrc",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Bill Date",
                            key: "billStartDate",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Actionees",
                            key: "actionees",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Contract Term",
                            key: "contractTerm",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Renewal Date",
                            key: "renewalDate",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Renewal Approved",
                            key: "renewalApproved",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Check-In Date",
                            key: "checkInDate",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "Technical", style: headerStyle},
                                cols: [
                                    {
                                        string: "IPs",
                                        key: "ips",
                                        style: headerStyle
                                    },
                                    {
                                        string: "CIDs",
                                        key: "cids",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Gateways",
                                        key: "gateways",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Subnet Masks",
                                        key: "subnets",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Service Port",
                                        key: "servicePort",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Equipment Location",
                                        key: "equipmentLocation",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Email Sent Date",
                                        key: "emailSentDate",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            string: "Author",
                            key: "author",
                            style: headerStyle,
                            rowSpan: 1
                        },
                    ]
                    data = props.objects.map(order => {return {
                        type: {string: order.type},
                        status: {string: order.status},
                        customerID: {string: order.customerRef},
                        customerName: {string: order.customerName},
                        service: {string: order.service},
                        serviceStreet1: {string: order.serviceAddress.street1},
                        serviceStreet2: {string: order.serviceAddress.street2},
                        serviceCity: {string: order.serviceAddress.city},
                        serviceCounty: {string: order.serviceAddress.county},
                        serviceState: {string: order.serviceAddress.state},
                        serviceZip: {string: order.serviceAddress.zip},
                        businessPhone: {string: chimera.phoneNumberStr(order.businessPhone)},
                        pocFirst: {string: order.poc.firstName},
                        pocLast: {string: order.poc.lastName},
                        pocEmail: {string: order.poc.email},
                        pocPhone: {string: chimera.phoneNumberStr(order.poc.phone)},
                        provider: {string: order.provider},
                        focDate: {string: order.focDate},
                        wholesaleStatus: {string: order.wholesaleStatus},
                        retailStatus: {string: order.retailStatus},
                        billingStreet1: {string: order.billingAddress.street1},
                        billingStreet2: {string: order.billingAddress.street2},
                        billingCity: {string: order.billingAddress.city},
                        billingCounty: {string: order.billingAddress.county},
                        billingState: {string: order.billingAddress.state},
                        billingZip: {string: order.billingAddress.zip},
                        billingPocFirst: {string: order.billingPoc.firstName},
                        billingPocLast: {string: order.billingPoc.lastName},
                        billingPocEmail: {string: order.billingPoc.email},
                        billingPocPhone: {string: chimera.phoneNumberStr(order.billingPoc.phone)},
                        mrc: {number: order.mrc},
                        nrc: {number: order.nrc},
                        billStartDate: {string: order.billStartDate},
                        actionees: {string: order.actionees.join(', ')},
                        contractTerm: {string: order.contractTerm},
                        renewalDate: {string: order.renewalDate},
                        renewalApproved: {bool: order.renewalApproved},
                        checkInDate: {string: order.checkInDate},
                        ips: {string: order.technical.ips.join('; ')},
                        cids: {string: order.technical.cids.join('; ')},
                        gateways: {string: order.technical.gateways.join('; ')},
                        subnets: {string: order.technical.subnetMasks.join('; ')},
                        servicePort: {string: order.technical.servicePort},
                        equipmentLocation: {string: order.technical.equipmentLocation},
                        emailSentDate: {string: order.technical.emailSentDate},
                        author: {string: order.author.email}
                    }})
                    break;
                case 'voipOrder':
                    headers = [
                        {
                            string: "Type",
                            key: "type",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Status",
                            key: "status",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Customer ID",
                            key: "customerID",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Customer Name",
                            key: "customerName",
                            rowSpan: 1,
                            style: headerStyle
                        },
                        {
                            string: "Location Name",
                            key: "customerLocationNickname",
                            rowSpan: 1,
                            style: headerStyle
                        },
                        {
                            string: "New Location Name?",
                            key: "newLocationNickname",
                            rowSpan: 1,
                            style: headerStyle
                        },
                        {
                            section: {
                                title: {string: "Service Address", style: headerStyle},
                                cols: [
                                    {
                                        string: "Street 1",
                                        key: "serviceStreet1",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Street 2",
                                        key: "serviceStreet2",
                                        style: headerStyle
                                    },
                                    {
                                        string: "City",
                                        key: "serviceCity",
                                        style: headerStyle
                                    },
                                    {
                                        string: "County",
                                        key: "serviceCounty",
                                        style: headerStyle
                                    },
                                    {
                                        string: "State",
                                        key: "serviceState",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Zip",
                                        key: "serviceZip",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            string: "Business Phone",
                            key: "businessPhone",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "POC", style: headerStyle},
                                cols: [
                                    {
                                        string: "First Name",
                                        key: "pocFirst",
                                        style: headerStyle
                                    },
                                    {
                                        string: "LastName",
                                        key: "pocLast",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Email",
                                        key: "pocEmail",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Phone",
                                        key: "pocPhone",
                                        style: headerStyle
                                    }
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Billing Address", style: headerStyle},
                                cols: [
                                    {
                                        string: "Street 1",
                                        key: "billingStreet1",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Street 2",
                                        key: "billingStreet2",
                                        style: headerStyle
                                    },
                                    {
                                        string: "City",
                                        key: "billingCity",
                                        style: headerStyle
                                    },
                                    {
                                        string: "County",
                                        key: "billingCounty",
                                        style: headerStyle
                                    },
                                    {
                                        string: "State",
                                        key: "billingState",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Zip",
                                        key: "billingZip",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Billing POC", style: headerStyle},
                                cols: [
                                    {
                                        string: "First Name",
                                        key: "billingPocFirst",
                                        style: headerStyle
                                    },
                                    {
                                        string: "LastName",
                                        key: "billingPocLast",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Email",
                                        key: "billingPocEmail",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Phone",
                                        key: "billingPocPhone",
                                        style: headerStyle
                                    }
                                ]
                            }
                        },
                        {
                            string: "Bill Date",
                            key: "billStartDate",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Enable SMS?",
                            key: "enableSms",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Enable Fax?",
                            key: "enableFax",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Enable International?",
                            key: "enableInternational",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "International",
                            key: "international",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "Phone Number Port Status", style: headerStyle},
                                cols: [
                                    {
                                        string: "Wholesale Status",
                                        key: "phoneNumberWholesaleStatus",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Retail Status",
                                        key: "phoneNumberRetailStatus",
                                        style: headerStyle
                                    },
                                    {
                                        string: "FOC Date",
                                        key: "phoneNumberFocDate",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Toll Free Port Status", style: headerStyle},
                                cols: [
                                    {
                                        string: "Wholesale Status",
                                        key: "tollFreeWholesaleStatus",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Retail Status",
                                        key: "tollFreeRetailStatus",
                                        style: headerStyle
                                    },
                                    {
                                        string: "FOC Date",
                                        key: "tollFreeFocDate",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Fax Number Port Status", style: headerStyle},
                                cols: [
                                    {
                                        string: "Wholesale Status",
                                        key: "faxNumberWholesaleStatus",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Retail Status",
                                        key: "faxNumberRetailStatus",
                                        style: headerStyle
                                    },
                                    {
                                        string: "FOC Date",
                                        key: "faxNumberFocDate",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "SMS Port Status", style: headerStyle},
                                cols: [
                                    {
                                        string: "Wholesale Status",
                                        key: "smsWholesaleStatus",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Retail Status",
                                        key: "smsRetailStatus",
                                        style: headerStyle
                                    },
                                    {
                                        string: "FOC Date",
                                        key: "smsFocDate",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            string: "Products",
                            key: "products",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Hardware",
                            key: "hardware",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Contract Term",
                            key: "contractTerm",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Onboarding Person",
                            key: "onboardingPerson",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Salesperson",
                            key: "salesperson",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Email Sent Date",
                            key: "emailSentDate",
                            rowSpan: 1,
                            style: headerStyle
                        },
                        {
                            string: "Ticket Created Date",
                            key: "ticketCreatedDate",
                            rowSpan: 1,
                            style: headerStyle
                        },
                        {
                            string: "Author",
                            key: "author",
                            style: headerStyle,
                            rowSpan: 1
                        },
                    ]
                    data = props.objects.map(order => {return {
                        type: {string: order.type},
                        status: {string: order.status},
                        customerID: {string: order.customerRef},
                        customerName: {string: order.customerName},
                        customerLocationNickname: {string: order.customerLocationNickname},
                        newLocationNickname: {string: order.newLocationNickname},
                        phoneNumberWholesaleStatus: {string: order.portStatuses.phoneNumber.wholesaleStatus},
                        phoneNumberRetailStatus: {string: order.portStatuses.phoneNumber.retailStatus},
                        phoneNumberFocDate: {date: new Date(order.portStatuses.phoneNumber.focDate)},
                        tollFreeWholesaleStatus: {string: order.portStatuses.tollFree.wholesaleStatus},
                        tollFreeRetailStatus: {string: order.portStatuses.tollFree.retailStatus},
                        tollFreeFocDate: {date: new Date(order.portStatuses.tollFree.focDate)},
                        faxNumberWholesaleStatus: {string: order.portStatuses.faxNumber.wholesaleStatus},
                        faxNumberRetailStatus: {string: order.portStatuses.faxNumber.retailStatus},
                        faxNumberFocDate: {date: new Date(order.portStatuses.faxNumber.focDate)},
                        smsWholesaleStatus: {string: order.portStatuses.sms.wholesaleStatus},
                        smsRetailStatus: {string: order.portStatuses.sms.retailStatus},
                        smsFocDate: {date: new Date(order.portStatuses.sms.focDate)},
                        enableSms: {bool: order.enableSms},
                        enableFax: {bool: order.enableFax},
                        enableInternational: {bool: order.enableInternational},
                        international: {string: order.international},
                        products: {string: order.products.map(product => `${product.name} x ${product.qty}`).join('\n')},
                        hardware: {string: order.hardware.map(hardware => `${hardware.name} x ${hardware.qty}`).join('\n')},
                        service: {string: order.service},
                        serviceStreet1: {string: order.serviceAddress.street1},
                        serviceStreet2: {string: order.serviceAddress.street2},
                        serviceCity: {string: order.serviceAddress.city},
                        serviceCounty: {string: order.serviceAddress.county},
                        serviceState: {string: order.serviceAddress.state},
                        serviceZip: {string: order.serviceAddress.zip},
                        businessPhone: {string: chimera.phoneNumberStr(order.businessPhone)},
                        pocFirst: {string: order.poc.firstName},
                        pocLast: {string: order.poc.lastName},
                        pocEmail: {string: order.poc.email},
                        pocPhone: {string: chimera.phoneNumberStr(order.poc.phone)},
                        billingStreet1: {string: order.billingAddress.street1},
                        billingStreet2: {string: order.billingAddress.street2},
                        billingCity: {string: order.billingAddress.city},
                        billingCounty: {string: order.billingAddress.county},
                        billingState: {string: order.billingAddress.state},
                        billingZip: {string: order.billingAddress.zip},
                        billingPocFirst: {string: order.billingPoc.firstName},
                        billingPocLast: {string: order.billingPoc.lastName},
                        billingPocEmail: {string: order.billingPoc.email},
                        billingPocPhone: {string: chimera.phoneNumberStr(order.billingPoc.phone)},
                        billStartDate: {string: order.billStartDate},
                        onboardingPerson: {string: `${order.onboardingPerson.first} ${order.onboardingPerson.last}`},
                        salesperson: {string: order.salesperson},
                        contractTerm: {string: order.contractTerm},
                        emailSentDate: {string: order.emailSentDate},
                        author: {string: order.author.email}
                    }})
                    break;
                case 'user':
                    headers = [
                        {
                            string: "Email",
                            key: "email",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "First Name",
                            key: "first",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Last Name",
                            key: "last",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Groups",
                            key: "groups",
                            rowSpan: 1,
                            style: headerStyle,
                        }
                    ]
                    data = props.objects.map(user => {return {
                        email: {string: user.email},
                        first: {string: user.first},
                        last: {string: user.last},
                        groups: {string: user.groups.join('; ')}
                    }});
                    break;
                case 'productOrder':
                    headers = [
                        {
                            string: "Order #",
                            key: "bundleNumber",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "Customer", style: headerStyle},
                                cols: [
                                    {
                                        string: "Display Name",
                                        key: "customerDisplayName",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Chimera ID",
                                        key: "customerChimeraId",
                                        style: headerStyle
                                    },
                                    {
                                        string: "QuickBooks ID",
                                        key: "customerQbId",
                                        style: headerStyle
                                    }
                                ]
                            }
                        },
                        {
                            string: "Vendor",
                            key: "vendor",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Ticket #",
                            key: "ticketNumber",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            section: {
                                title: {string: "QuickBooks Purchase Order", style: headerStyle},
                                cols: [
                                    {
                                        string: "Number",
                                        key: "qbPurchaseOrderNumber",
                                        style: headerStyle
                                    },
                                    {
                                        string: "ID",
                                        key: "qbPurchaseOrderId",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "QuickBooks Estimate", style: headerStyle},
                                cols: [
                                    {
                                        string: "Number",
                                        key: "qbEstimateNumber",
                                        style: headerStyle
                                    },
                                    {
                                        string: "ID",
                                        key: "qbEstimateId",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "QuickBooks Invoice", style: headerStyle},
                                cols: [
                                    {
                                        string: "Number",
                                        key: "qbInvoiceNumber",
                                        style: headerStyle
                                    },
                                    {
                                        string: "ID",
                                        key: "qbInvoiceId",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Status",
                                        key: "qbInvoiceStatus",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            section: {
                                title: {string: "Vendor Bill", style: headerStyle},
                                cols: [
                                    {
                                        string: "Number",
                                        key: "vendorBillNumber",
                                        style: headerStyle
                                    },
                                    {
                                        string: "Paid?",
                                        key: "vendorBillPaid",
                                        style: headerStyle
                                    },
                                ]
                            }
                        },
                        {
                            string: "Status",
                            key: "status",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Type",
                            key: "type",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Customer Quoted?",
                            key: "customerQuoted",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Billable?",
                            key: "billable",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Recurring Charge?",
                            key: "recurringCharge",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Date Purchased",
                            key: "datePurchased",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Items",
                            key: "items",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Notes",
                            key: "notes",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Created By",
                            key: "createdBy",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Last Modified By",
                            key: "modifiedBy",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Created Date",
                            key: "createdDate",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Last Updated Date",
                            key: "lastUpdatedDate",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                    ]
                    data = props.objects.map(order => {return {
                        bundleNumber: {number: order.bundleNumber},
                        customerDisplayName: {string: order.customer.displayName},
                        customerChimeraId: {string: order.customer.chimeraId},
                        customerQbId: {string: order.customer.qbId},
                        vendor: {string: order.vendor},
                        ticketNumber: {string: order.ticketNumber},
                        qbPurchaseOrderNumber: {string: order.qbPurchaseOrderNumber},
                        qbPurchaseOrderId: {string: order.qbPurchaseOrderId},
                        qbEstimateNumber: {string: order.qbEstimateNumber},
                        qbEstimateId: {string: order.qbEstimateId},
                        qbInvoiceNumber: {string: order.qbInvoiceNumber},
                        qbInvoiceId: {string: order.qbInvoiceId},
                        qbInvoiceStatus: {string: order.qbInvoiceStatus},
                        vendorBillNumber: {string: order.vendorBillNumber},
                        vendorBillPaid: {bool: order.vendorBillPaid},
                        status: {string: order.status},
                        type: {string: order.type},
                        customerQuoted: {bool: order.customerQuoted},
                        billable: {bool: order.billable},
                        recurringCharge: {bool: order.recurringCharge},
                        datePurchased: order.datePurchased ? {date: order.datePurchased} : {string: order.datePurchased},
                        items: {string: order.items.map(item => `Desc: ${item.description}\nSKU: ${item.sku}\nQty: ${item.qty}\nEstimated Unit Cost: ${item.estimatedUnitCost}\nShipping Cost: ${item.shippingCost}\nLink: ${item.link}`).join('\n\n')},
                        notes: {string: order.notes},
                        createdBy: {string: order.blame.createdBy.email},
                        modifiedBy: {string: order.blame.modifiedBy.email},
                        createdDate: {date: order.createdAt},
                        lastUpdatedDate: {date: order.updatedAt}
                    }});
                    break;
                case 'vendor':
                    headers = [
                        {
                            string: "Vendor Name",
                            key: "name",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Account Number",
                            key: "accountNumber",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Website",
                            key: "website",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Products/Services",
                            key: "productsServices",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "First Name",
                            key: "first",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Last Name",
                            key: "last",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Title",
                            key: "title",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Phone",
                            key: "phone",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Ext.",
                            key: "ext",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Email",
                            key: "email",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                        {
                            string: "Notes",
                            key: "notes",
                            rowSpan: 1,
                            style: headerStyle,
                        },
                    ]
                    data = [];
                    for(const vendor of props.objects) {
                        for(const contact of vendor.contacts) {
                            data.push({
                                name: {string: vendor.name},
                                accountNumber: {string: vendor.accountNumber},
                                website: {string: vendor.website},
                                productsServices: {string: vendor.productsServices},
                                first: {string: contact.first},
                                last: {string: contact.last},
                                title: {string: contact.title},
                                phone: {string: contact.phone},
                                ext: {string: contact.ext},
                                email: {string: contact.email},
                                notes: {string: contact.notes}
                            })
                        }
                    }
                    break;
                default:
                    return;
            }

            const blob = await chimera.callAPI(signal, '/api/xlsx', 'POST', {
                dataSets: [
                    {
                        worksheet: `${getNoun()}s`,
                        headers: headers,
                        data: data
                    }
                ]
            }, 'blob');
            setDownloads([{title: `${getNoun()}s Export`, blob: blob, ext: "xlsx"}]);
            setLoading(false);

        }
        catch(err) {
            console.error(err);
            alert("Something went wrong and the document could not be created.");
        }
    }

    const addressHeader = (name) => {
        return `${name} Address Street1,${name} Address Street2,${name} Address City,${name} Address County,${name} Address State,${name} Address ZIP`;
    }

    const addressData = (addr) => {
        return `"${addr.street1}","${addr.street2}","${addr.city}","${addr.county}","${addr.state}","${addr.zip}"`;
    }

    const exportCSV = async() => {
        let csv;
        let base64;
        setLoading(true);
        switch(props.model) {
            case 'fiberLead':
                csv = `Status,Current Step,Customer,POC First,POC Last,POC Email,POC Phone,${addressHeader("Customer")},${addressHeader("Billing")},Billing Email,Service,Provider,MRC,NRC,Bill Date,FOC Date,Technical POCs,CIDs,IPs,Subnets,Gateways,CPEs,Saved Signed Retail SO?,Saved Signed Wholesale SO?,Saved SOA?\n`;
                for(const lead of props.objects) {
                    csv += `"${lead.status}","${lead.currentStep+1}","${lead.attrs.customerName}","${lead.attrs.poc.firstName}","${lead.attrs.poc.lastName}","${lead.attrs.poc.email}","${chimera.phoneNumberStr(lead.attrs.poc.phone)}",${addressData(lead.attrs.customerAddress)},${addressData(lead.attrs.billingAddress)},"${lead.attrs.billingEmail}","${lead.attrs.service}","${lead.attrs.provider}","${chimera.dollarStr(lead.attrs.mrc)}","${chimera.dollarStr(lead.attrs.nrc)}","${lead.attrs.billStartDate}","${lead.attrs.focDate}","${lead.attrs.billStartDate}","${lead.attrs.techPocs.map(user => `${user.firstName} ${user.lastName}${user.email ? ` (${user.email})` : ''}${chimera.phoneNumberStr(user.phone)}`).join('; ')}","${lead.attrs.cids.join('; ')}","${lead.attrs.ips.join('; ')}","${lead.attrs.subnets.join('; ')}","${lead.attrs.gateways.join('; ')}","${lead.attrs.cpes.join('; ')}","${lead.attrs.savedSignedRetailSO}","${lead.attrs.savedSignedWholesaleSO}","${lead.attrs.savedSOA}"\n`;
                }
                break;
            case 'residentialLead':
                csv = `Status,Current Step,Zone,First Name,Last Name,Email,Phone,${addressHeader("Home")},${addressHeader("Mail")},Authorized Users,Switch Port,Bandwidth,IP Type,IPs,Gateways,Subnet Masks,VoIP Lines,Conduit Installed?,Conduit Install Date,Fiber Install Date\n`;
                for(const lead of props.objects) {
                    csv += `"${lead.status}","${lead.currentStep+1}","${lead.attrs.type}","${lead.attrs.firstName}","${lead.attrs.lastName}","${lead.attrs.email}","${chimera.phoneNumberStr(lead.attrs.phone)}",${addressData(lead.attrs.homeAddress)},${addressData(lead.attrs.mailAddress)},"${lead.attrs.authorizedUsers.map(user => `${user.firstName} ${user.lastName}${user.email ? ` (${user.email})` : ''}${chimera.phoneNumberStr(user.phone)}`).join('; ')}","${lead.attrs.technical.switchPort}","${lead.attrs.technical.bandwidth}","${lead.attrs.technical.ipType}","${lead.attrs.technical.ips.join('; ')}","${lead.attrs.technical.gateways.join('; ')}","${lead.attrs.technical.subnetMasks.join('; ')}","${lead.attrs.technical.voipLines}","${lead.attrs.conduitInstalled}","${lead.attrs.conduitInstallDate}","${lead.attrs.installDate}"\n`
                }
                break;
            case 'residentialCustomer':
                csv = `Account Number,Zone,First Name,Last Name,Email,Phone,${addressHeader("Home")},${addressHeader("Mail")},Authorized Users,Internet?,VoIP?,QuickBooks ID,Syncro ID,Unity Domain,Switch Port,Bandwidth,IP Type,IPs,Gateways,Subnet Masks,VoIP Lines,Standing\n`;
                for(const customer of props.objects) {
                    csv += `"${customer.accountNumber}","${customer.type}","${customer.firstName}","${customer.lastName}","${customer.email}","${chimera.phoneNumberStr(customer.phone)}",${addressData(customer.homeAddress)},${addressData(customer.mailAddress)},"${customer.authorizedUsers.map(user => `${user.firstName} ${user.lastName}${user.email ? ` (${user.email})` : ''}${chimera.phoneNumberStr(user.phone)}`).join('; ')}","${customer.serviceTypes.internet}","${customer.serviceTypes.voip}","${customer.integrationIds.quickbooks}","${customer.integrationIds.syncro}","${customer.integrationIds.unity}","${customer.technical.switchPort}","${customer.technical.bandwidth}","${customer.technical.ipType}","${customer.technical.ips.join('; ')}","${customer.technical.gateways.join('; ')}","${customer.technical.subnetMasks.join('; ')}","${customer.technical.voipLines}","${customer.standing}"\n`
                }
                break;
            case 'customer':
                csv = `Account Number,Business Name,Display Name,Location,POC First,POC Last,POC Email,POC Phone,Notification Emails,Business Phone,Billing Phone,${addressHeader("Billing")},${addressHeader("Service")},${addressHeader("Shipping")},Internet?,MSP?,VoIP?,Video?,Servers,Platinum,Gold,Silver,Billing POCs,Tax Exempt?,QuickBooks ID,Syncro ID,Bitdefender ID,Unity Domain,Pax8 ID,Duo Account,Network Summary,CIDs,IPs,Gateways,Subnet Masks,Management IP,Technical POCs,POPs,iDRAC,VoIP Lines,Equipment Location,Labor Credit Hours,On-Call/After Hours,Standard Rate,After-Hours Rate,Standing\n`;
                for(const customer of props.objects) {
                    for(const location of customer.locations) {
                        csv += `"${customer.accountNumber}","${customer.businessName}","${customer.displayName}","${location.nickname}","${location.poc.firstName}","${location.poc.lastName}","${location.poc.email}","${chimera.phoneNumberStr(location.poc.phone)}","${location.notificationEmails.join(', ')}","${chimera.phoneNumberStr(location.businessPhone)}","${chimera.phoneNumberStr(customer.billingPhone)}",${addressData(customer.billingAddress)},${addressData(location.serviceAddress)},${addressData(location.shippingAddress)},"${location.serviceTypes.internet}","${location.serviceTypes.msp}","${location.serviceTypes.voip}","${location.serviceTypes.video}","${customer.endpointPackages.servers}","${customer.endpointPackages.workstations.nPlat}","${customer.endpointPackages.workstations.nGold}","${customer.endpointPackages.workstations.nSilv}","${pocsStr(customer.billingPocs)}","${customer.taxExempt}","${customer.integrationIds.quickbooks}","${customer.integrationIds.syncro}","${customer.integrationIds.bitdefender}","${customer.integrationIds.unity}","${customer.integrationIds.pax8}","${customer.integrationIds.duo}","${location.technical.networkSummary.replace(/\n/g, '; ')}","${location.technical.cids.join(', ')}","${location.technical.ipList.map(entry => entry.ip).join(', ')}","${location.technical.ipList.map(entry => entry.gateway).join(', ')}","${location.technical.ipList.map(entry => entry.subnetMask).join(', ')}","${location.technical.managementIp}","${pocsStr(location.technical.pocs)}","${location.technical.ipList.map(entry => entry.pop).filter((value, index, self) => self.indexOf(value) === index).join(', ')}","${location.technical.idrac}","${location.technical.voipLines}","${location.technical.equipmentLocation}","${customer.laborCreditHours}","${customer.onCallAfterHours}","${chimera.dollarStr(customer.standardRate)}","${chimera.dollarStr(customer.afterHoursRate)}","${customer.standing}"\n`
                    }
                }
                break;
            case 'internetOrder':
                csv = `Type,Status,Customer ID,Customer Name,Service,${addressHeader("Service")},Business Phone,POC First,POC Last,POC Email,POC Phone,Provider,FOC Date,Wholesale Status,Retail Status,${addressHeader("Billing")},Billing POC First,Billing POC Last,Billing POC Email,Billing POC Phone,MRC,NRC,Bill Date,Actionees,Contract Term,Renewal Date,Renewal Approved,Check-In Date,IPs,CIDs,Gateways,Subnet Masks,Service Port,Equipment Location,Email Sent Date,Author\n`;
                for(const order of props.objects) {
                    csv += `"${order.type}","${order.status}","${order.customerRef}","${order.customerName}","${order.service}",${addressData(order.serviceAddress)},"${order.businessPhone}","${order.poc.firstName}","${order.poc.lastName}","${order.poc.email}","${order.poc.phone}","${order.provider}","${order.focDate}","${order.wholesaleStatus}","${order.retailStatus}",${addressData(order.billingAddress)},"${order.billingPoc.firstName}","${order.billingPoc.lastName}","${order.billingPoc.email}","${order.billingPoc.phone}","${order.mrc}","${order.nrc}","${order.billStartDate}","${order.actionees.join(', ')}","${order.contractTerm}","${order.renewalDate}","${order.renewalApproved}","${order.checkInDate}","${order.technical.ips.join('; ')}","${order.technical.cids.join('; ')}","${order.technical.gateways.join('; ')}","${order.technical.subnetMasks.join('; ')}","${order.technical.servicePort}","${order.technical.equipmentLocation}","${order.technical.emailSentDate}","${order.author.email}"\n`;
                }
                break;
            case 'voipOrder':
                csv = `Type,Status,Customer ID,Customer Name,Location Name,New Location Name?,${addressHeader('Service')},Business Phone,POC First,POC Last,POC Email,POC Phone,${addressHeader('Billing')},Billing POC First,Billing POC Last,Billing POC Email,Billing POC Phone,Bill Date,Enable SMS?,Enable Fax?,Enable International?,International,Phone Number Wholesale Status,Phone Number Retail Status,Phone Number FOC Date,Toll Free Wholesale Status,Toll Free Retail Status,Toll Free FOC Date,Fax Number Wholesale Status,Fax Number Retail Status,Fax Number FOC Date,SMS Wholesale Status,SMS Retail Status,SMS FOC Date,Products,Hardware,Contract Term,Onboarding Person,Salesperson,Email Sent Date,Ticket Created Date,Author\n`;
                for(const order of props.objects) {
                    csv += `"${order.type}","${order.status}","${order.customerRef}","${order.customerName}","${order.customerLocationNickname}","${order.newLocationNickname}",${addressData(order.serviceAddress)},"${order.businessPhone}","${order.poc.firstName}","${order.poc.lastName}","${order.poc.email}","${order.poc.phone}",${addressData(order.billingAddress)},"${order.billingPoc.firstName}","${order.billingPoc.lastName}","${order.billingPoc.email}","${order.billingPoc.phone}","${order.billStartDate}","${order.enableSms}","${order.enableFax}","${order.enableInternational}","${order.international}","${order.portStatuses.phoneNumber.wholesaleStatus}","${order.portStatuses.phoneNumber.retailStatus}","${order.portStatuses.phoneNumber.focDate}","${order.portStatuses.tollFree.wholesaleStatus}","${order.portStatuses.tollFree.retailStatus}","${order.portStatuses.tollFree.focDate}","${order.portStatuses.faxNumber.wholesaleStatus}","${order.portStatuses.faxNumber.retailStatus}","${order.portStatuses.faxNumber.focDate}","${order.portStatuses.sms.wholesaleStatus}","${order.portStatuses.sms.retailStatus}","${order.portStatuses.sms.focDate}","${order.products.map(product => `${product.name} x ${product.qty}`).join('; ')}","${order.hardware.map(hw => `${hw.name} x ${hw.qty}`).join('; ')}","${order.contractTerm}","${order.onboardingPerson.email}","${order.salesperson}","${order.emailSentDate}","${order.ticketCreatedDate}","${order.author.email}"\n`;
                }
                break;
            case 'user':
                csv = 'Email,First Name,Last Name,Groups\n';
                for(const user of props.objects) {
                    csv += `"${user.email}","${user.first}","${user.last}","${user.groups.join('; ')}"\n`
                }
                break;
            case 'productOrder':
                csv = 'Order #,Customer Display Name,Customer Chimera ID,Customer QB ID,Vendor,Ticket #,QB Purchase Order #,QB Purchase Order ID,QB Estimate #,QB Estimate ID,QB Invoice #,QB Invoice ID,QB Invoice Status,Vendor Bill #,Vendor Bill Paid?,Status,Type,Customer Quoted?,Billable?,Recurring Charge?,Date Purchased,Items,Notes,Created By,Modified By,Created Date,Last Updated Date\n';
                for(const order of props.objects) {
                    csv += `"${order.bundleNumber}","${order.customer.displayName}","${order.customer.chimeraId}","${order.customer.qbId}","${order.vendor}","${order.ticketNumber}","${order.qbPurchaseOrderNumber}","${order.qbPurchaseOrderId}","${order.qbEstimateNumber}","${order.qbEstimateId}","${order.qbInvoiceNumber}","${order.qbInvoiceId}","${order.qbInvoiceStatus}","${order.vendorBillNumber}","${order.vendorBillPaid}","${order.status}","${order.type}","${order.customerQuoted}","${order.billable}","${order.recurringCharge}","${order.datePurchased}","${order.items.map(item => `Desc: ${item.description}; SKU: ${item.sku}; Qty: ${item.qty}; Estimated Unit Cost: ${item.estimatedUnitCost}; Shipping Cost: ${item.shippingCost}; Link: ${item.link}`)}","${order.notes.replace(/\n/g, '   ')}","${order.blame.createdBy.email}","${order.blame.modifiedBy.email}","${order.createdAt}","${order.updatedAt}"\n`;
                }
                break;
            case 'vendor':
                csv = 'Vendor Name,Account Number,Website,Products/Services,First Name,Last Name,Title,Phone,Ext.,Email,Notes\n';
                for(const vendor of props.objects) {
                    for(const contact of vendor.contacts) {
                        csv += `"${vendor.name}","${vendor.accountNumber}","${vendor.website}","${vendor.productsServices.replace(/\n/g, '   ')}","${contact.first}","${contact.last}","${contact.title}","${contact.phone}","${contact.ext}","${contact.email}","${contact.notes.replace(/\n/g, '   ')}"\n`;
                    }
                }
                break;
            default:
                return;
        }
        base64 = btoa(csv);
        fetch(`data:text/csv;base64,${base64}`)
        .then(async response => {
            setDownloads([{title: `${getNoun()}s Export`, blob: await response.blob(), ext: "csv"}]);
            setLoading(false);
        })
        .catch(err => {
            console.error(err);
            alert("Something went wrong and the document could not be created.");
        })
    }

    const exportJSON = () => {
        const json = JSON.stringify(props.objects, null, 4);
        const base64 = btoa(json);
        setLoading(true);
        fetch(`data:application/json;base64,${base64}`)
        .then(async response => {
            setDownloads([{title: `${getNoun()}s Export`, blob: await response.blob(), ext: "json"}]);
            setLoading(false);
        })
        .catch(err => {
            console.error(err);
            alert("Something went wrong and the document could not be created.");
        })
    }

    const exportObjects = (event) => {
        event.preventDefault();
        try {
            if(exportAs === "Excel") {
                exportExcel();
            }
            else if(exportAs === "CSV") {
                exportCSV();
            }
            else if(exportAs === "JSON") {
                exportJSON();
            }
        }
        catch(err) {
            console.error(err);
            alert('ERROR: An unhandled error occurred and the export cannot continue.');
            modaling.backtrack();
        }
    }

    const defaultChoices = [
        {
            btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
            btnColor: 'secondary',
            func: (e) => {
                e.preventDefault();
                modaling.backtrack();
            }
        }
    ]

    const download = (obj) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(obj.blob);
        const today = new Date();
        link.download = `${obj.title} ${today.toISOString().substring(0,10)}.${obj.ext}`;
        link.click();
    }

    return (
        <Modal choices={props.choices !== undefined ? props.choices : defaultChoices} dismiss={props.dismiss !== undefined ? props.dismiss : defaultChoices[0].func}>
            <h3>Export {props.objects.length} {getNoun()}s</h3>
            {loading ?
                <LoadingSpinner size={50}/> 
            :
                <>
                {downloads.length === 0 ? 
                <>
                <FormField
                    type="select"
                    options={[
                        {id: "Excel", value: "Excel"},
                        {id: "CSV", value: "CSV"},
                        {id: "JSON", value: "JSON"},
                    ]}
                    name="exportAs"
                    label="Export As"
                    value={exportAs}
                    description=""
                    handleChange={handleChange}
                    classes="my-0"
                    excludeNoneSelected
                    />
                <button className="btn btn-primary mb-2" onClick={exportObjects}>
                    <i className="fas fa-file-export"/>&nbsp;Export
                </button>
                </>
                :
                <>
                {downloads.map((dwn, i) => <div className="row" key={i}>
                    <div className="col">
                        <button className="btn btn-success mb-2" onClick={(event) => {event.preventDefault(); download(dwn)}}>
                            <i className="fas fa-download"/>&nbsp;{dwn.title}.{dwn.ext}
                        </button>
                    </div>
                </div>)}
                </>
                }
                </>
            }
        </Modal>
    )
}

export default ExportModal;