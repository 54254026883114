import React, { useState, useEffect, useRef } from 'react';

import chimera from '../chimera';

function fromRelativeMonth(relativeDefaultMonth) {
    const date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() + relativeDefaultMonth);
    return chimera.dateToISOFragment(date);
}

const MonthSelector = ({relativeDefaultMonth, setMonth}) => {
    const [baseDayStr, setBaseDayStr] = useState(relativeDefaultMonth ? fromRelativeMonth(relativeDefaultMonth) : chimera.dateToISOFragment(new Date())); // expecting `month` to be 'YYYY-MM' format
    const nextWeekBtn = useRef(null);
    const prevWeekBtn = useRef(null);
    const [monthOfStr, setMonthOfStr] = useState(null);

    useEffect(() => {
        if(nextWeekBtn.current && document.activeElement === nextWeekBtn.current && tooFarAhead()) {
            nextWeekBtn.current.blur();
        }
        if(prevWeekBtn.current && document.activeElement === prevWeekBtn.current && tooFarBack()) {
            prevWeekBtn.current.blur();
        }
    });

    useEffect(() => {
        const baseDay = chimera.dateFromISOFragment(baseDayStr);
        setMonthOfStr(`${chimera.monthStr(baseDay.getMonth())}, ${baseDay.getFullYear()}`);
        setMonth(baseDayStr.substring(0,7));
    }, [baseDayStr]);

    const tooFarAhead = () => {
        const baseDay = chimera.dateFromISOFragment(baseDayStr);
        const today = new Date();
        return baseDay.getMonth() === today.getMonth();
    }

    const tooFarBack = () => {
        const baseDay = chimera.dateFromISOFragment(baseDayStr);
        return baseDay.getMonth() === 3 && baseDay.getFullYear() === 2022; // Can't go farther back than April 2022
    }

    const moveMonth = (delta) => {
        let newBaseDay = chimera.dateFromISOFragment(baseDayStr);
        newBaseDay.setDate(1);
        newBaseDay.setMonth(newBaseDay.getMonth() + delta);
        setBaseDayStr(chimera.dateToISOFragment(newBaseDay));
    }

    const prevMonth = (event) => {
        event.preventDefault();
        moveMonth(-1);
    }

    const nextMonth = (event) => {
        event.preventDefault();
        moveMonth(1);
    }

    return(
        <>
            <button className="btn" onClick={prevMonth} disabled={tooFarBack()} ref={prevWeekBtn}>
                <i className={tooFarBack() ? "fas fa-angle-left text-white" : "fas fa-angle-left"}/>
            </button>
            {monthOfStr}
            <button className="btn" onClick={nextMonth} disabled={tooFarAhead()} ref={nextWeekBtn}>
                <i className={tooFarAhead() ? "fas fa-angle-right text-white" : "fas fa-angle-right"}/>
            </button>
        </>
    )
}

export default MonthSelector;