import {useState, useEffect, useContext} from 'react';

import chimera from '../../chimera';
import BannerContext from '../BannerLogContext';
import SmartForm from '../SmartForm';
import useSmartForm from '../../hooks/useSmartForm';

const QBItemServiceTypes = ({}) => {
    const [attr, setAttr] = useState(null);
    const [qbItems, setQbItems] = useState(null);
    const [qbItemCategories, setQbItemCategories] = useState(null);
    const smartFormProps = useSmartForm({initialState: attr, apiBase: '/api/attributes'});
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(attr === null) {
            chimera.callAPI(undefined, '/api/settings/QB_ITEM_SERVICE_TYPES')
            .then(newAttr => setAttr(newAttr))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read settings, form cannot load.', 'Error');
            })
        }
    }, [attr]);

    useEffect(() => {
        if(qbItems === null) {
            chimera.callQuickBooksAPI(undefined, '/api/qb/items')
            .then(data => setQbItems(data))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read QB Items; cannot load selectors.', 'Error');
            })
        }
    }, [qbItems]);

    useEffect(() => {
        if(qbItemCategories === null) {
            chimera.callQuickBooksAPI(undefined, '/api/qb/itemcategories')
            .then(data => setQbItemCategories(data))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read QB Item Categories; cannot load selectors.', 'Error');
            })
        }
    }, [qbItemCategories])

    const sections = ['Internet', 'VoIP', 'MSP', 'Video']

    return (
        <SmartForm formId="QBItemServiceTypes" noun="Settings" {...smartFormProps}>
            <SmartForm.Main>
                <SmartForm.SaveButton/>
                {sections.map((section) => <div key={section}>
                    <h4>{section}</h4>
                    <SmartForm.QBItemCategorySelectorList label={`${section} Categories`} path={`value.${section.toLowerCase()}.categories`} fullWidth selection={qbItemCategories}/>
                    <SmartForm.QBItemSelectorList label={`${section} Items`} path={`value.${section.toLowerCase()}.items`} fullWidth selection={qbItems}/>
                    <SmartForm.Divider/>
                </div>)}
                <SmartForm.SaveButton/>
            </SmartForm.Main>
        </SmartForm>
    )
}

export default QBItemServiceTypes;