import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ModalProvider } from './components/ModalContext';
import Navigation from "./components/Navigation";
import Home from "./components/Home";
import Footer from "./components/Footer";
import SyncroInvoice from './components/tools/SyncroInvoice';
import CustomFields from './components/tools/CustomFields';
import React, {useState, useEffect} from 'react';
import QBReports from './components/tools/QBReports';
import RetrieveCACNs from './components/tools/RetrieveCACNs';
import TaxCalc from './components/tools/TaxCalc';
import Auditing from './components/tools/Auditing';
import UserContext from './UserContext';
import Leads from './components/Leads';
import Customers from './components/Customers';
import Orders from './components/Orders/Orders';
import Reports from './components/Reports/Reports';
import SearchPage from './components/SearchPage';
import LoginPage from './components/LoginPage';
import CustomerPage from './components/CustomerPage/CustomerPage';
import NewCustomer from './components/NewCustomer';
import ReportTablePage from './components/ReportTablePage';
import PatchNotesPage from './components/PatchNotesPage';
import PatchNotesUpload from './components/tools/PatchNotesUpload';
import ResidentialCustomers from './components/ResidentialCustomers';
import NewResidentialCustomer from './components/NewResidentialCustomer';
import ResidentialCustomerPage from './components/ResidentialCustomerPage';
import CommitToggler from './components/tools/CommitToggler';
import Requests from './components/Requests';
import TagsEditor from './components/tools/TagsEditor';
import QBCustomerChecker from './components/tools/QBCustomerChecker';
import { AlertsContextProvider } from './components/AlertsContext';
import TechnicianBillability from './components/tools/TechnicianBillability';
import TxnTracker from './components/tools/TxnTracker';
import Vendors from './components/Vendors/Vendors';
import UnityBackupsMonitor from './components/UnityBackupsMonitor';
import Admin from './components/Admin/Admin';
import AgentTool from './components/tools/AgentTool';
import ChimeraBackupsMonitor from './components/ChimeraBackupsMonitor';
import ProjectsPage from './components/Projects/ProjectsPage';
import Rolodex from './components/Rolodex';
import RevenueDeltaTool from './components/tools/RevenueDelta/RevenueDeltaTool';
import TicketMetrics from './components/tools/TicketMetrics';

const NotFound = props => {
  return(
    <div className="container pb-5">
      <h1 className="chomsky">Not Found</h1>
      <p>
        The resource you are trying to access could not be found.
      </p>
    </div>
  )
}

const App = props => {
  const [user, setUser] = useState(null);
  const [tools, setTools] = useState([]);
  const [permissions, setPermissions] = useState(null);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (!user) { 
      //Use /auth/test route to (re)-test authentication and obtain user data
      fetch("/auth/test")
        .then((response) => response.json())
        .then((obj) => {
          if (obj.isAuthenticated) {
            setUser(obj.user);
            setTools(obj.tools);
            setPermissions(obj.permissions);
            setGroups(obj.groups);
          }
          else if(obj.user.skipped) {
            alert(`The account for ${obj.user.email} does not exist and automatic account creation has been disabled by the Admins. Please reach out to an Admin to have your account manually created, then try again.`);
          }
        })
    }
  }, [user]);

  return (
    <UserContext.Provider value={{user: user, tools: tools, permissions: permissions, groups: groups}}>
      <AlertsContextProvider>
        <ModalProvider>
          <div className="App">
            <Router>
              <Navigation />
              {user ? 
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="/tools/syncroinvoice" element={<SyncroInvoice />} />
                <Route path="/tools/customfields" element={<CustomFields />}/>
                <Route path="/tools/qbreports" element={<QBReports />}/>
                <Route path="/tools/cacns" element={<RetrieveCACNs />}/>
                <Route path="/tools/taxcalc" element={<TaxCalc />}/>
                <Route path="/tools/audit" element={<Auditing />}/>
                <Route path="/tools/commit" element={<CommitToggler/>}/>
                <Route path="/tools/tags" element={<TagsEditor/>}/>
                <Route path="/tools/customerchecker" element={<QBCustomerChecker/>}/>
                <Route path="/tools/patchnotes" element={<PatchNotesUpload/>}/>
                <Route path="/tools/techbill" element={<TechnicianBillability/>}/>
                <Route path="/tools/txntrackers" element={<TxnTracker/>}/>
                <Route path="/tools/agenttool" element={<AgentTool/>}/>
                <Route path="/tools/revenuedelta" element={<RevenueDeltaTool/>}/>
                {/** <Route path="/tools/ticketmetrics" element={<TicketMetrics/>}/> **/}
                <Route path="/leads" element={<Leads/>}/>
                <Route path="/customers" element={<Customers/>}/>
                <Route path="/residential" element={<ResidentialCustomers/>}/>
                <Route path="/residential/new" element={<NewResidentialCustomer/>}/>
                <Route path="/residential/:acct" element={<ResidentialCustomerPage/>}/>
                <Route path="/orders" element={<Orders/>}/>
                <Route path="/orders/:type" element={<Orders/>}/>
                <Route path="/orders/:type/:id" element={<Orders/>}/>
                <Route path="/reports" element={<Reports/>}/>
                <Route path="/vendors" element={<Vendors/>}/>
                <Route path="/vendors/:id" element={<Vendors/>}/>
                <Route path="/backups/unity" element={<UnityBackupsMonitor/>}/>
                <Route path="/backups/chimera" element={<ChimeraBackupsMonitor/>}/>
                <Route path="/search" element={<SearchPage/>}/>
                <Route path="/customers/new" element={<NewCustomer/>}/>
                <Route path="/customers/:acct" element={<CustomerPage/>}/>
                <Route path="/reporttables/:id" element={<ReportTablePage/>}/>
                <Route path="/projects" element={<ProjectsPage/>}/>
                <Route path="/rolodex" element={<Rolodex/>}/>
                <Route path="/patchnotes" element={<PatchNotesPage/>}/>
                <Route path="/help" element={<Requests/>}/>
              </Routes>
              : <LoginPage/> }
              <Footer />
            </Router>
          </div>
        </ModalProvider>
      </AlertsContextProvider>
    </UserContext.Provider>
  );
}

export default App;