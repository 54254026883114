import { BannerLog } from "../../BannerLogContext";
import Modal from "../../Modal";
import useFormModal from "../../../hooks/useFormModal";
import SmartForm from "../../SmartForm";
import useSmartForm from "../../../hooks/useSmartForm";
import chimera from "../../../chimera";
import { forwardRef } from 'react';

const TxnTable = ({txns, type}) => {
    return (
        <>
        {txns.length > 0 ? 
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Link</th>
                    <th>Internet Total</th>
                    <th>VoIP Total</th>
                    <th>MSP Total</th>
                    <th>Video Total</th>
                    <th>Grand Total</th>
                </tr>
            </thead>
            <tbody>
                {txns.map(txn => <tr key={txn.id}>
                    <td>{txn.docNumber}</td>
                    <td>{txn.txnDate}</td>
                    <td><a target="_blank" rel="noopener noreferrer" href={`https://qbo.intuit.com/app/${type}?txnId=${txn.id}`}><i className="fas fa-up-right-from-square"/></a></td>
                    <td>{chimera.dollarStr(txn.totals.internet)}</td>
                    <td>{chimera.dollarStr(txn.totals.voip)}</td>
                    <td>{chimera.dollarStr(txn.totals.msp)}</td>
                    <td>{chimera.dollarStr(txn.totals.video)}</td>
                    <td>{chimera.dollarStr(txn.totals.internet + txn.totals.voip + txn.totals.msp + txn.totals.video)}</td>
                </tr>)}
            </tbody>
        </table>
        :
        <p className="text-muted">No transactions of this kind for this Customer within this month.</p>
        }
        </>
    )
}

const DataEntryForm = forwardRef(({entry, parentSetModified, parentSetObj, parentSetSavedObj}, ref) => {
    const smartFormProps = useSmartForm({initialState: entry, apiBase: '/api/revenuedeltaentries'});

    return (
        <SmartForm ref={ref} {...smartFormProps} formId="dataEntryForm" noun="Data Entry" parentSetWorkingObj={parentSetObj} parentSetModified={parentSetModified} parentSetSavedObj={parentSetSavedObj}>
            <SmartForm.Main>
                <h3>Revenue Delta Data Entry</h3>
                <SmartForm.Section nCols={1}>
                    <div className="text-start">
                        <p><b>Customer:</b>&nbsp;<a target="_blank" href={`/customers/${entry.customerRef}`}>{entry.customerName}&nbsp;<i className="fas fa-up-right-from-square"/></a></p>
                        <p><b>Month:</b>&nbsp;{chimera.monthAsStr(entry.month)}</p>
                        <p><b>Date Created:</b>&nbsp;{(new Date(entry.createdAt)).toLocaleString()}</p>
                        <p><b>Last Updated:</b>&nbsp;{(new Date(entry.updatedAt)).toLocaleString()}</p>
                    </div>
                </SmartForm.Section>
                <h4>Invoices</h4>
                <SmartForm.Section nCols={1}>
                    <TxnTable txns={entry.invoices} type="invoice"/>
                </SmartForm.Section>
                <h4>Sales Receipts</h4>
                <SmartForm.Section nCols={1}>
                    <TxnTable txns={entry.salesReceipts} type="salesreceipt"/>
                </SmartForm.Section>
            </SmartForm.Main>
        </SmartForm>
    )
})

const DataEntryFormModal = ({entry, parentBanners, parentSetSavedObj, onClose, removeById}) => {
    const {workingObj, setWorkingObj, setModified, choices, ref} = useFormModal({
        initialState: entry,
        apiBase: '/api/revenuedeltaentries',
        noun: 'Data Entry',
        removeById: removeById,
        onClose,
        parentBanners
    })

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <BannerLog>
                <DataEntryForm ref={ref} entry={workingObj} parentSetModified={setModified} parentSetProject={setWorkingObj} parentSetSavedObj={parentSetSavedObj}/>
            </BannerLog>
        </Modal>
    )
}

export default DataEntryFormModal;