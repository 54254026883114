import React, {useState, useEffect, useContext} from "react";
import ToolPage from './ToolPage';
import ScreenGroup from "../ScreenGroup";
import useScreens from "../../hooks/useScreens";
import BannerContext from "../BannerLogContext";
import LoadingSpinner from "../LoadingSpinner";
import useDateRange from "../../hooks/useDateRange";
import DateRange from "../DateRange";
import chimera from "../../chimera";

function resolutionTimeInHours(ticket) {
    const createdAt = new Date(ticket.created_at);
    const resolvedAt = new Date(ticket.resolved_at);
    const difference = resolvedAt.getTime() - createdAt.getTime();
    return difference / 3600000;
}

const TicketMetricsBody = ({}) => {
    const [startDate, endDate, handleDateChange] = useDateRange('firstOfMonth', 'lastOfMonth');
    const [results, setResults] = useState(null);
    const banners = useContext(BannerContext);
    const [loaderLabel, setLoaderLabel] = useState("Loading...");
    const screenGroupProps = useScreens('START', ['START', 'RUNNING', 'RESULTS'], () => {
        setResults(null);
        banners.clearBanners();
    });
    const { screen, setScreen } = screenGroupProps;

    useEffect(() => {
        if(screen === 'RUNNING') {
            chimera.callAPI(undefined, `/api/syncro/tickets/range/${startDate}/${endDate}`)
            .then(tickets => {
                // NOTE: Syncro API sucks butt and does not reliably give what you'd expect when providing `created_after` so we need to filter again
                tickets = tickets.filter(ticket => ticket.created_at.substring(0,10) >= startDate && ticket.created_at.substring(0,10) <= endDate);
                const resolvedTickets = tickets.filter(ticket => ticket.resolved_at !== null && ticket.resolved_at.substring(0,10) <= endDate)

                const results = {};

                let totalResTime = 0;
                for(const resolvedTicket of resolvedTickets) {
                    totalResTime += resolutionTimeInHours(resolvedTicket);
                }

                results.supportTickets = {
                    ticketsOpened: tickets.length,
                    ticketsResolved: resolvedTickets.length,
                    averageResolutionTime: totalResTime / resolvedTickets.length
                }

                // NOTE: Getting 26 for Macrium when Syncro reports 27. Should go through and find what's missing to see if its falling out of filters incorrectly or something
                let nTicketsPerCustomer = {};
                for(const ticket of tickets) {
                    if(nTicketsPerCustomer[ticket.customer_business_then_name] === undefined) {
                        nTicketsPerCustomer[ticket.customer_business_then_name] = 1;
                    }
                    else {
                        nTicketsPerCustomer[ticket.customer_business_then_name] += 1;
                    }
                }
                results.ticketCountByCustomer = nTicketsPerCustomer;

                results.byTechnician = {};
                results.ticketsWithNoAssignee = [];
                for(const resolvedTicket of resolvedTickets) {
                    if(resolvedTicket.user === null) {
                        results.ticketsWithNoAssignee.push(resolvedTicket);
                        continue;
                    }
                    if(results.byTechnician[resolvedTicket.user.full_name] === undefined) {
                        results.byTechnician[resolvedTicket.user.full_name] = {
                            tickets: [resolvedTicket]
                        }
                    }
                    else {
                        results.byTechnician[resolvedTicket.user.full_name].tickets.push(resolvedTicket);
                    }
                }
                for(const tech in results.byTechnician) {
                    results.byTechnician[tech].nTickets = results.byTechnician[tech].tickets.length;
                    let totalResTime = 0;
                    for(const ticket of results.byTechnician[tech].tickets) {
                        totalResTime += resolutionTimeInHours(ticket);
                    }
                    results.byTechnician[tech].averageResolutionTime = totalResTime / results.byTechnician[tech].nTickets;
                }

                setResults(results);
                setScreen('RESULTS');
            })
        }
        else if(screen === 'RESULTS') {
            console.log(results);
        }
    }, [screen]);

    return (
        <ScreenGroup {...screenGroupProps}>
            <ScreenGroup.Screen name='START' header="Choose an Option" hideBackBtn>
                <DateRange startDate={startDate} endDate={endDate} handleChange={handleDateChange}/>
                <button className="btn btn-primary" onClick={(e) => {e.preventDefault(); setScreen('RUNNING')}}>
                    <i className="fas fa-arrow-right"/>&nbsp;Generate Report
                </button>
            </ScreenGroup.Screen>
            <ScreenGroup.Screen name='RUNNING' header="Generating Report...">
                <LoadingSpinner size={75} label={loaderLabel}/>
            </ScreenGroup.Screen>
            <ScreenGroup.Screen name='RESULTS' header="Results">
                Hello World
            </ScreenGroup.Screen>
        </ScreenGroup>
    )
}

const TicketMetrics = ({}) => {
    const toolName = "Ticket Metrics";
    const toolId = "ticketmetrics";
    return (
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image={null} toolName={toolName} alt="Ticket Metrics">
                A report generator that pulls data otherwise captured in separate Syncro Reports.
            </ToolPage.Header>
            <ToolPage.How>
                
            </ToolPage.How>
            <ToolPage.Body>
                <TicketMetricsBody />
            </ToolPage.Body>
        </ToolPage>
    );
}

export default TicketMetrics;