import React, {useState, useEffect, useContext} from 'react';

const ScreenGroupContext = React.createContext();

const ScreenGroup = ({screen, setScreen, startScreenName, children}) => {
    const [shownScreen, setShownScreen] = useState(null);

    useEffect(() => {
        let screenComponents = childrenAsArr(children).filter(c => c.type === Screen);
        const s = screenComponents.find(c => c.props.name === screen);
        if(s) {
            setShownScreen(s);
        }
        else {
            setShownScreen(null);
        }
    }, [screen, children]);

    const childrenAsArr = (c) => {
        if(!c) return [];
        if(c.length) return c;
        else return [c];
    }

    return (
        <ScreenGroupContext.Provider value={{setScreen, startScreenName}}>
            {shownScreen}
        </ScreenGroupContext.Provider>
    )
}

const Screen = ({name, header, hideBackBtn, onBackBtnClick, disableBackBtn, children}) => {
    const screenGroupContext = useContext(ScreenGroupContext);

    const handleBackBtnClick = (e) => {
        e.preventDefault();
        if(onBackBtnClick) {
            onBackBtnClick();
        }
        else {
            screenGroupContext.setScreen(screenGroupContext.startScreenName);
        }
    }

    return (
        <div>
            {header || !hideBackBtn ?
            <div className="row">
                {!hideBackBtn ?
                <div className="col-2">
                    <button className="btn btn-secondary float-start" onClick={handleBackBtnClick} disabled={disableBackBtn}>
                        <i className="fas fa-arrow-left"/>
                    </button>
                </div>
                :null}
                <div className="col">
                    <h1>{header ? header : name}</h1>
                </div>
                {!hideBackBtn ? <div className="col-2"/> : null}
            </div>
            :null}
            {children}
        </div>
    )
}
ScreenGroup.Screen = Screen;

export {ScreenGroup as default, ScreenGroupContext};