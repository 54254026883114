import React, {useState, useContext} from "react";
import LoadingSpinner from '../../LoadingSpinner';
import BannerContext from "../../BannerLogContext";
import chimera from "../../../chimera";

const boldStyle = {
    font: {
        name: "Calibri",
        size: 12,
        bold: true
    }
}

const dollarStyle = {
    numberFormat: "$#,##0.00; ($#,##0.00); -"
}

const negativeDollarStyle = {
    numberFormat: "$#,##0.00; ($#,##0.00); -",
    font: {
        color: '#FF0000'
    }
}

const boldDollarStyle = {
    font: {
        name: "Calibri",
        size: 12,
        bold: true
    },
    numberFormat: "$#,##0.00; ($#,##0.00); -"
}

const boldNegativeDollarStyle = {
    font: {
        name: "Calibri",
        size: 12,
        bold: true,
        color: '#FF0000'
    },
    numberFormat: "$#,##0.00; ($#,##0.00); -"
}

const DownloadReport = ({}) => {
    // Fetch months. Then do a call for each of those months. Hundreds of entries will be returned for each month so this is best.
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [label, setLabel] = useState("Running...");
    const [xlsx, setXlsx] = useState(null);
    const banners = useContext(BannerContext);

    const capitalizeServiceType = (serviceType) => {
        switch(serviceType.toLowerCase()) {
            case 'internet':
                return 'Internet';
            case 'voip':
                return 'VoIP';
            case 'msp':
                return 'MSP';
            case 'video':
                return 'Video';
            default: 
                return '';
        }
    }

    const composeCustomerData = (currentMonthEntry, prevMonthEntry) => {
        if(!currentMonthEntry && !prevMonthEntry) {
            throw new Error("currentMonthEntry and prevMonthEntry both null/undefined");
        }
        const defaultTotals = {
            internet: 0,
            voip: 0,
            msp: 0,
            video: 0,
            grandTotal: 0
        }
        const currentTotals = currentMonthEntry ? currentMonthEntry.totals : defaultTotals;
        const prevTotals = prevMonthEntry ? prevMonthEntry.totals : defaultTotals;
        let deltas = {};
        for(const serviceType of chimera.SERVICE_TYPES) {
            deltas[serviceType] = currentTotals[serviceType] - prevTotals[serviceType];
        }
        const commonData = currentMonthEntry ? currentMonthEntry : prevMonthEntry; // prefer to use current info if available, fall back to prev if necessary
        let data = [];
        let isFirstPart = true;
        for(const serviceType of chimera.SERVICE_TYPES) {
            if(currentTotals[serviceType] !== prevTotals[serviceType]) {
                data.push({
                    month: {string: isFirstPart ? commonData.month : ''},
                    customerName: {string: isFirstPart ? commonData.customerName : ''},
                    service: {string: capitalizeServiceType(serviceType)},
                    newRevenue: {number: deltas[serviceType], style: deltas[serviceType] >= 0 ? dollarStyle : negativeDollarStyle},
                    monthlyEst: isFirstPart ? {number: commonData.totals.grandTotal, style: dollarStyle} : {string: ''},
                    annualEst: isFirstPart ? {number: commonData.totals.grandTotal * 12, style: dollarStyle} : {string: ''}
                })
                isFirstPart = false;
            }
        }
        if(data.length > 0) {
            // append line break
            data.push({
                month: {string: ''},
                customerName: {string: ''},
                service: {string: ''},
                newRevenue: {string: ''},
                monthlyEst: {string: ''},
                annualEst: {string: ''}
            })
        }
        return data;
    }

    const composeMonth = (monthStr, currentMonthEntries, prevMonthEntries) => {
        let data = [];

        let customerRefs = [];
        for(const currentMonthEntry of currentMonthEntries) {
            if(!customerRefs.includes(currentMonthEntry.customerRef)) {
                customerRefs.push(currentMonthEntry.customerRef);
            }
        }
        if(prevMonthEntries) {
            for(const prevMonthEntry of prevMonthEntries) {
                if(!customerRefs.includes(prevMonthEntry.customerRef)) {
                    customerRefs.push(prevMonthEntry.customerRef);
                }
            }
        }

        for(const customerRef of customerRefs) {
            const currentMonthEntry = currentMonthEntries.find(entry => entry.customerRef === customerRef);
            const prevMonthEntry = prevMonthEntries ? prevMonthEntries.find(entry => entry.customerRef === customerRef) : undefined;
            try {
                data = [].concat(data, composeCustomerData(currentMonthEntry, prevMonthEntry));
            }
            catch(err) {
                if(err.toString() === "Error: currentMonthEntry and prevMonthEntry both null/undefined") {
                    banners.addBanner('danger', `Composing for customerRef ${customerRef} failed; this customer will not be counted in this month: ${monthStr}`, 'Error');
                }
                else {
                    throw err;
                }
            }
        }
        if(data.length > 0) {
            // append totals
            let newRevenueTotal = 0;
            let monthlyEstTotal = 0;
            for(const d of data) {
                if(d.newRevenue.number) {
                    newRevenueTotal += d.newRevenue.number;
                }
            }
            for(const entry of currentMonthEntries) {
                monthlyEstTotal += entry.totals.grandTotal;
            }
            data.push({
                month: {string: 'Total:', style: boldStyle},
                customerName: {string: ''},
                service: {string: ''},
                newRevenue: {number: newRevenueTotal, style: newRevenueTotal >= 0 ? boldDollarStyle : boldNegativeDollarStyle}, // todo: implement formulas?
                monthlyEst: {number: monthlyEstTotal, style: boldDollarStyle},
                annualEst: {number: monthlyEstTotal * 12, style: boldDollarStyle}
            })
        }

        return data;
    }

    const compose = () => {
        return new Promise(async(resolve, reject) => {
            try {
                setIsLoading(true);

                setLabel("Reading months...");
                let months = [];
                let entriesByMonth = {};
                try {
                    months = await chimera.callAPI(undefined, '/api/revenuedeltamonths');
                    months = months.sort((a,b) => a < b ? -1 : 1);
                }
                catch(err) {
                    reject(err);
                }

                for(const month of months) {
                    setLabel(`Reading entries for month ${month}`);
                    try {
                        entriesByMonth[month] = await chimera.callAPI(undefined, `/api/revenuedeltaentries/month/${month}`);
                    }
                    catch(err) {
                        reject(err);
                    }
                }

                setLabel("Composing...");
                let data = [];
                const dataByMonth = {};
                for(let i = 0; i < months.length; i++) {
                    const dataThisSection = composeMonth(months[i], entriesByMonth[months[i]], i === 0 ? null : entriesByMonth[months[i-1]]);
                    dataByMonth[months[i]] = dataThisSection;
                    data = [].concat(data, dataThisSection);
                }
                let headers = [
                    {string: 'Month', key: 'month', style: boldStyle},
                    {string: 'Customer Name', key: 'customerName', style: boldStyle},
                    {string: 'Service', key: 'service', style: boldStyle},
                    {string: 'New Revenue', key: 'newRevenue', style: boldStyle},
                    {string: 'Total Monthly Est. Revenue', key: 'monthlyEst', style: boldStyle},
                    {string: 'Total Annual Est. Revenue', key: 'annualEst', style: boldStyle}
                ];
                let dataSets = [
                    {
                        worksheet: "Everything",
                        headers: headers,
                        data: data
                    }
                ]
                for(const month in dataByMonth) {
                    dataSets.push({
                        worksheet: chimera.monthAsStr(month),
                        headers: headers,
                        data: dataByMonth[month]
                    })
                }

                const results = {dataSets}
                setXlsx(results);
                setIsLoading(false);
                resolve(results);
            }
            catch(err) {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'An unhandled error has occurred - the tool cannot continue.', 'Error');
                }
            }
        })
    }

    const downloadXlsx = (xl) => {
        setIsDownloading(true);
        chimera.callAPI(undefined, '/api/xlsx', 'POST', xl, 'blob')
        .then(blob => {
            const today = new Date();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `RevenueDeltaReport_${chimera.dateToISOFragment(today)}.xlsx`;
            link.click();
        })
        .catch(err => {
            console.error(err);
            if(banners) {
                banners.addBanner('danger', 'Failed to download the report', 'Error');
            }
            else {
                alert('ERROR: Failed to download the report');
            }
        })
        .finally(() => {
            setIsDownloading(false);
        })
    }

    const download = (e) => {
        e.preventDefault();
        if(xlsx === null) {
            compose()
            .then(results => downloadXlsx(results))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'Failed to compose the report', 'Error');
                }
                else {
                    alert('ERROR: Failed to compose the report');
                }
            })
        }
        else {
            downloadXlsx(xlsx);
        }
    }

    return (
        <>
        <button className="btn btn-success" onClick={download} disabled={isDownloading || isLoading}>
            <i className={isDownloading || isLoading ? "fas fa-spinner" : "fas fa-download"}/>&nbsp;{isDownloading || isLoading? 'Downloading...' : 'Download'}
        </button>

        {isLoading ?
            <div className="mt-3">
                <LoadingSpinner size={75} label={label}/>
            </div>
        :null}
        </>
    )
}

export default DownloadReport;