import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle} from 'react';

import BannerContext from './BannerLogContext';
import LoadingSpinner from './LoadingSpinner';
import FormFieldMicro from './FormFieldMicro';
import chimera from '../chimera';
import ModalContext from './ModalContext';
import Modal, {choiceCancel, choiceDelete} from './Modal';
import Checklist from './Checklist';
import Tooltip from './Tooltip';
import FileUpload from './FileUpload';
import AutocompleteNew from './AutocompleteNew';
import AddressDisplay from './AddressDisplay';
import PocDisplay from './PocDisplay';
import UserContext from '../UserContext';
import DropdownButton from './DropdownButton';

const SmartFormContext = React.createContext();

// TODO: readonly implementation for all subcomponents
const SmartForm = forwardRef(({formId, noun, workingObj, setWorkingObj, parentSetWorkingObj, parentSetSavedObj, modified, setModified, parentSetModified, saveAndClose, onClose, handleChange, handleSave, readonly, disabled, children}, ref) => {
    const [isSaving, setIsSaving] = useState(false);
    const [preSaves, setPreSaves] = useState([]);
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);
    //const formId = useId(); // not supported in React 17

    useImperativeHandle(ref, () => ({
        handleSaveAndClose() {
            setIsSaving(true);
            return _handleSave(workingObj, false);
        }
    }))

    useEffect(() => {
        if(parentSetWorkingObj) {
            parentSetWorkingObj(workingObj);
        }
    }, [workingObj]);

    useEffect(() => {
        if(parentSetModified) {
            parentSetModified(modified);
        }
    }, [modified]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSaving(true);
        _handleSave(workingObj, saveAndClose);
    }

    const doPreSave = (objToSave) => {
        return new Promise(async(resolve, reject) => {
            try {
                let _objToSave = objToSave;
                for(const preSave of preSaves) {
                    _objToSave = await preSave.promiseFunc(_objToSave);
                }
                resolve(_objToSave);
            }
            catch(err) {
                console.error(err);
                reject(err);
            }
        })
    }

    const _handleSave = (objToSave, andClose) => {
        return new Promise((resolve, reject) => {
            if(!objToSave) objToSave = workingObj;
            setIsSaving(true);
            doPreSave(objToSave)
            .then(updatedObj => {
                handleSave(updatedObj)
                .then(savedObj => {
                    if(parentSetSavedObj) {
                        parentSetSavedObj(savedObj);
                    }
                    if(banners) {
                        if(workingObj._id) {
                            banners.addBanner('info', 'Changes saved successfully', 'Saved');
                        }
                        else {
                            banners.addBanner('info', `Created new ${noun}`, 'Saved');
                        }
                    }
                    setWorkingObj(savedObj);
                    setIsSaving(false);
                    setModified(false);
                    // TODO: implement page overlay version instead of modal opening version, and update this to support it.
                    if(saveAndClose && andClose && modaling) {
                        resolve(savedObj);
                        if(onClose) onClose();
                        modaling.backtrack();
                    }
                    else {
                        resolve(savedObj);
                    }
                })
                .catch(err => {
                    console.error(err);
                    if(banners) {
                        if(err.details && err.details.name === "ValidationError") {
                            for(const key in err.details.errors) {
                                banners.addBanner('danger', `${key}: ${err.details.errors[key].message}`, 'Validation Error');
                            }
                        }
                        else if(err.name !== 'AbortError') {
                            banners.addBanner('danger', `Failed to save ${noun}`, 'Error');
                        }
                    }
                    else {
                        alert(`ERROR: An unhandled error occurred and the ${noun} could not be saved.`);
                    }
                    setIsSaving(false);
                    reject(err);
                })
            })
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'Pre-Save handlers ran into an error; changes could not be saved.', 'Error');
                }
                setIsSaving(false);
                reject(err);
            })
        })
    }

    const _handleChange = (e) => {
        setModified(true);
        handleChange(e);
    }

    const getValue = (path) => {
        return chimera.getAttr(workingObj, path);
    }

    const addPreSave = (path, promiseFunc) => {
        const index = preSaves.findIndex(p => p.path === path);
        if(index > -1) {
            // already exists, just update it
            let newPreSaves = [];
            for(let i = 0; i < preSaves.length; i++) {
                if(i === index) {
                    newPreSaves.push({path, promiseFunc});
                }
                else {
                    newPreSaves.push(preSaves[i]);
                }
            }
            setPreSaves(newPreSaves);
        }
        else {
            setPreSaves([...preSaves, {path, promiseFunc}]);
        }
    }

    const removePreSave = (path) => {
        setPreSaves(preSaves.filter(p => p.path !== path));
    }

    return(
        <form id={formId} onSubmit={handleSubmit} noValidate onKeyDown={(e) => {if(e.key === "Enter") e.stopPropagation()}}>
            <SmartFormContext.Provider value={{workingObj, setWorkingObj, saveAndClose, readonly, modified, setModified, getValue, handleChange: _handleChange, handleSave: _handleSave, isSaving, addPreSave, removePreSave, disabled: disabled || isSaving}}>
                {workingObj !== null ? 
                    <div className="row">
                        {children}
                    </div>
                : <LoadingSpinner size={75}/>}
            </SmartFormContext.Provider>
        </form>
    )
})

/** TODO: Stretch to height of viewport, enable scrolling within the component itself */
const Main = ({children}) => {
    return (
        <div className="col">
            {children}
        </div>
    )
}
SmartForm.Main = Main;

/** TODO: Stays static (no scrolling) at least compared to Main. Could let Main scroll with the page instead of being scrollable within itself for full-page forms, but scrollable Main is for Modals (which we should try to get away from tbh)*/
const Sidebar = ({children}) => {
    return (
        <div className="col-3 border-start">
            {children}
        </div>
    )
}
SmartForm.Sidebar = Sidebar;

/** BUTTONS START */
const SaveButton = ({margin, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);
    return(
        <>
        {smartFormContext.saveAndClose ? 
        <DropdownButton id="saveBtn" label={smartFormContext.isSaving ? "Saving..." : 'Save & Close'} className={`${margin ? margin : 'mb-1'} ${fullWidth ? 'w-100' : 'w-fit'}`} icon={smartFormContext.isSaving ? 'spinner' : 'floppy-disk'} disabled={smartFormContext.disabled || !smartFormContext.modified} action={() => smartFormContext.handleSave(null, true)}
            options={[
                {label: "Save", action: () => smartFormContext.handleSave()}
            ]}
        />
        :
        <button className={`btn ${margin ? margin : 'mb-1'} btn-primary${fullWidth ? ' w-100' : ' w-fit'}`} type="submit" disabled={smartFormContext.disabled || !smartFormContext.modified}>
            <i className={smartFormContext.isSaving ? 'fas fa-spinner' : 'fas fa-floppy-disk'}/>&nbsp;{smartFormContext.isSaving ? 'Saving...' : (smartFormContext.saveAndClose ? 'Save & Close' : 'Save')}
        </button>
        }
        </>
    )
}
SmartForm.SaveButton = SaveButton;

const DeleteButton = ({}) => {
    return (
        <></>
    )
}
SmartForm.DeleteButton = DeleteButton;

const Button = ({icon, label, color, action, disabled, disableWhenModified, fullWidth, margin}) => {
    const smartFormContext = useContext(SmartFormContext);
    const handleClick = (e) => {
        e.preventDefault();
        action();
    }

    return (
        <button className={`btn ${margin ? margin : ''} btn-${color ? color : 'primary'}${fullWidth ? ' w-100' : ' w-fit'}`} onClick={handleClick} disabled={(disableWhenModified && smartFormContext.modified) || disabled || smartFormContext.disabled}>
            <i className={icon ? icon : 'fas fa-arrow-right'}/>{label ? <>&nbsp;{label}</> : null}
        </button>
    )
}
SmartForm.Button = Button;

const ActionButton = ({icon, label, pendingLabel, color, action, disableWhenModified, disabled, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);
    const [isPending, setIsPending] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setIsPending(true);
        action()
        .finally(() => {
            setIsPending(false);
        })
    }

    return (
        <button className={`btn mb-1 btn-${color ? color : 'primary'}${fullWidth ? ' w-100' : ''}`} onClick={handleClick} disabled={(disableWhenModified && smartFormContext.modified) || disabled || isPending || smartFormContext.disabled}>
            <i className={isPending ? 'fas fa-spinner' : icon}/>&nbsp;{isPending ? (pendingLabel ? pendingLabel : 'Working...') : label}
        </button>
    )
}
SmartForm.ActionButton = ActionButton;

/** BUTTONS END */
/** FIELDS START */

function trimOnBlur(smartFormContext) {
    return (event) => {
        if(event.target.value !== event.target.value.trim()) {
            smartFormContext.handleChange({
                target: {
                    type: "string",
                    name: event.target.name,
                    value: event.target.value.trim()
                },
                preventDefault: () => {}
            })
        }
    }
}

/**
 * 
 * @param {String} path The path to the value from `workingObj` 
 * @param {String} label The label displayed on the `FormFieldMicro`
 * @param {Number} size (optional) An integer size limit for the text input
 * @param {Boolean} fullWidth (optional) Whether to pass `fullWidth` or `fit` to the `FormFieldMicro`
 * @returns 
 */
const StringField = ({path, label, size, required, fullWidth, children}) => {
    const smartFormContext = useContext(SmartFormContext);

    return (
        <FormFieldMicro
            type="text"
            name={path}
            label={label}
            value={smartFormContext.getValue(path)}
            handleChange={smartFormContext.handleChange}
            onBlur={trimOnBlur(smartFormContext)}
            disabled={smartFormContext.disabled}
            required={required}
            size={size}
            fullWidth={fullWidth}
            fit={!fullWidth}
            useNewChildPos
        >
            {children}
        </FormFieldMicro>
    )
}
SmartForm.StringField = StringField;

const DateField = ({path, label, required, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);

    return (
        <FormFieldMicro
            type="date"
            name={path}
            label={label}
            value={smartFormContext.getValue(path)}
            handleChange={smartFormContext.handleChange}
            disabled={smartFormContext.disabled}
            required={required}
            fullWidth={fullWidth}
            fit={!fullWidth}
        />
    )
}
SmartForm.DateField = DateField;

/**
 * @param {String} path The path to the value from `workingObj` 
 * @param {String} label The label displayed on the `FormFieldMicro`
 * @param {Array<{label: String, value: any}>} options 
 * @param {Boolean} fullWidth (optional) Whether to pass `fullWidth` or `fit` to the `FormFieldMicro`
 * @param {Boolean} excludeNoneSelected (optional) Whether to exclude "-- NONE SELECTED --" option from dropdown
 * @returns A rendered `<select>` form element
 */
const SelectField = ({path, label, options, required, fullWidth, excludeNoneSelected, disabled}) => {
    const smartFormContext = useContext(SmartFormContext);

    return (
        <FormFieldMicro
            type="select"
            name={path}
            label={label}
            value={smartFormContext.getValue(path)}
            handleChange={smartFormContext.handleChange}
            options={options.map(opt => {return {id: opt.value, value: opt.label}})} // `id` is the value and `value` is the label. this is bad, please fix later
            disabled={smartFormContext.disabled || disabled}
            fullWidth={fullWidth}
            fit={!fullWidth}
            required={required}
            excludeNoneSelected={excludeNoneSelected}
        />
    )
}
SmartForm.SelectField = SelectField;

const DollarField = ({path, label, required, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);

    return (
        <FormFieldMicro
            type="text"
            name={path}
            label={label}
            value={smartFormContext.getValue(path)}
            handleChange={smartFormContext.handleChange}
            onBlur={trimOnBlur(smartFormContext)}
            disabled={smartFormContext.disabled}
            size={10}
            placeholder={"$0.00"}
            pattern={"\\$?[\\d\\,]+\\.\\d\\d"}
            required={required}
            fit={!fullWidth}
            fullWidth={fullWidth}
        />
    )
}
SmartForm.DollarField = DollarField;

function defaultSameAs(path, sameAsPath, smartFormContext) {
    if(chimera.deepEqual(smartFormContext.getValue(path), chimera.DEFAULT_ADDRESS)) return false;
    else return chimera.deepEqual(smartFormContext.getValue(path), smartFormContext.getValue(sameAsPath));
}

const AddressField = ({path, label, required, sameAsLabel, sameAsPath}) => {
    const smartFormContext = useContext(SmartFormContext);
    const [sameAs, setSameAs] = useState(sameAsPath ? defaultSameAs(path, sameAsPath, smartFormContext) : false);
    const [oldSameAsValue, setOldSameAsValue] = useState(sameAsPath ? smartFormContext.getValue(sameAsPath) : null);

    // Update value when "Same as ...?" checked for the first time
    useEffect(() => {
        if(sameAs && sameAsPath && !chimera.deepEqual(smartFormContext.getValue(path), smartFormContext.getValue(sameAsPath))) {
            smartFormContext.handleChange({
                target: {
                    type: "address",
                    name: path,
                    value: smartFormContext.getValue(sameAsPath)
                },
                preventDefault: () => {}
            })
        }
    }, [sameAs]);

    // Update value persistently while "Same as ...?" is checked when the value it's supposed to match is changing
    useEffect(() => {
        if(sameAs && sameAsPath && !chimera.deepEqual(oldSameAsValue, smartFormContext.getValue(sameAsPath))) {
            setOldSameAsValue(smartFormContext.getValue(sameAsPath));
            smartFormContext.handleChange({
                target: {
                    type: "address",
                    name: path,
                    value: smartFormContext.getValue(sameAsPath)
                },
                preventDefault: () => {}
            })
        }
    }, sameAsPath ? [smartFormContext.getValue(sameAsPath)] : []);

    const handleChange = (event) => {
        if(event.target.name === sameAsPath) {
            setSameAs(event.target.checked);
        }
        else {
            smartFormContext.handleChange(event);
        }
    }

    return (
        <FormFieldMicro
            type="component"
            name={path}
            label={label}
            disabled={smartFormContext.disabled}
            required={required}
            useNewChildPos
        >
            <AddressDisplay addr={smartFormContext.getValue(path)} basePath={path} baseValue={smartFormContext.workingObj} sameAsLabel={sameAsLabel} sameAsPath={sameAsPath} sameAs={sameAs} onChange={handleChange} isEditing onBlur={trimOnBlur(smartFormContext)} disabled={smartFormContext.disabled}/>
        </FormFieldMicro>
    )
}
SmartForm.AddressField = AddressField;

const PocField = ({path, label, required}) => {
    const smartFormContext = useContext(SmartFormContext);

    return (
        <FormFieldMicro
            type="component"
            name={path}
            label={label}
            disabled={smartFormContext.disabled}
            required={required}
            useNewChildPos
        >
            <PocDisplay poc={smartFormContext.getValue(path)} basePath={path} baseValue={smartFormContext.workingObj} onChange={smartFormContext.handleChange} isEditing onBlur={trimOnBlur(smartFormContext)} disabled={smartFormContext.disabled}/>
        </FormFieldMicro>
    )
}
SmartForm.PocField = PocField;

const ChecklistField = ({path, label, required, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);

    const setItems = (newList) => {
        const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObj));
        chimera.setAttr(newWorkingObj, path, newList);
        smartFormContext.setWorkingObj(newWorkingObj);
        smartFormContext.setModified(true);
    }

    return (
        <FormFieldMicro
            type="component"
            name={path}
            label={label}
            required={required}
            fit={!fullWidth}
            fullWidth={fullWidth}
            useNewChildPos
        >
            <Checklist name={path} items={smartFormContext.getValue(path)} setItems={setItems} isEditing={!smartFormContext.readonly} disabled={smartFormContext.disabled} fullWidth={fullWidth}/>
        </FormFieldMicro>
    )
}
SmartForm.ChecklistField = ChecklistField;

const ListField = ({path, keyPath, defaultItem, label, required, fullWidth, renderRow}) => {
    const smartFormContext = useContext(SmartFormContext);

    const setItems = (newItems) => {
        const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObj));
        chimera.setAttr(newWorkingObj, path, newItems);
        smartFormContext.setWorkingObj(newWorkingObj);
        smartFormContext.setModified(true);
    }

    const addItem = (e) => {
        e.preventDefault();
        setItems([...smartFormContext.getValue(path), defaultItem]);
    }

    const removeItemAtIndex = (index) => {
        setItems(smartFormContext.getValue(path).filter((_, i) => i !== index));
    }

    return (
        <FormFieldMicro
            type="component"
            name={path}
            label={label}
            required={required}
            fit={!fullWidth}
            fullWidth={fullWidth}
            useNewChildPos
        >
            <div>
                {/** index multiplied by 10000 to prevent matching an ID */}
                {smartFormContext.getValue(path).map((item, i) => <div key={keyPath && chimera.getAttr(item, keyPath) ? chimera.getAttr(item, keyPath) : i * 10000} className={i !== 0 ? 'row mt-2' : 'row'}>
                    <div className="col">
                        {renderRow(item, i)}
                    </div>
                    <div className="col-1 d-flex">
                        <button className="btn btn-sm btn-danger w-100" onClick={(e) => {e.preventDefault(); removeItemAtIndex(i)}} disabled={smartFormContext.disabled}>
                            <i className="fas fa-times"/>
                        </button>
                    </div>
                </div>)}
                <button className="btn btn-sm btn-success mt-2" onClick={addItem} disabled={smartFormContext.disabled}>
                    <i className="fas fa-plus"/>
                </button>
            </div>
        </FormFieldMicro>
    )
}
SmartForm.ListField = ListField;

const CustomerSelector = ({path, label, required, setSelectedCustomer, selection}) => {
    const [customers, setCustomers] = useState(selection ? selection : null);
    const smartFormContext = useContext(SmartFormContext);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(customers === null && selection === undefined) {
            chimera.callAPI(undefined, '/api/customers')
            .then(newCustomers => setCustomers(newCustomers))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'Failed to load Customers; Customer selection will not be available.', 'Error');
                }
                else {
                    alert('ERROR: Failed to load Customers; Customer selection will not be available.');
                }
            })
        }
        else if(customers !== null) {
            const selectedCustomer = customers.find(c => c.accountNumber === smartFormContext.getValue(path).ref);
            if(setSelectedCustomer) setSelectedCustomer(selectedCustomer ? selectedCustomer : null);
        }
    }, [customers, selection, smartFormContext.getValue(path).ref]);

    useEffect(() => {
        if(selection) {
            setCustomers(selection);
        }
    }, [selection]);

    const suggestionChosenCallback = (customer) => {
        if(setSelectedCustomer) setSelectedCustomer(customer);
        const oldValues = smartFormContext.getValue(path)
        const newValues = {
            name: customer ? customer.displayName : '',
            ref: customer ? customer.accountNumber : '',
            qbId: customer ? customer.integrationIds.quickbooks : '',
            locationRef: customer ? customer.locations[0]._id : '',
            newLocationNickname: smartFormContext.getValue(path).newLocationNickname
        }
        if(!chimera.deepEqual(oldValues, newValues)) {
            const newObject = JSON.parse(JSON.stringify(smartFormContext.workingObj));
            chimera.setAttr(newObject, path, newValues);
            smartFormContext.setWorkingObj(newObject);
            smartFormContext.setModified(true);
        }
    }

    const openCustomerInNewTab = (e) => {
        e.preventDefault();
        window.open(`/customers/${smartFormContext.getValue(path).ref}`, '_blank');
    }

    return (
        <AutocompleteNew
            label={label}
            value={smartFormContext.getValue(path).ref}
            objects={customers}
            labelRule={(c) => c.displayName}
            valueRule={(c) => c.accountNumber}
            objectChosenCallback={suggestionChosenCallback}
            strictMode
            required={required}
            disabled={smartFormContext.disabled}
            isLoading={customers === null}
        >
            <div className={`text-start mb-1${!label ? ' ms-4' : ''}`} style={{marginTop: label ? '-.3rem': '.2rem'}}>
                <Tooltip pos="bottom" text={smartFormContext.getValue(path).ref ? "Open Customer in new tab" : "Open Customer in new tab (choose Customer first)"}>
                    <button className="btn btn-secondary btn-sm" onClick={openCustomerInNewTab} disabled={!smartFormContext.getValue(path).ref}>
                        <i className="fas fa-up-right-from-square"/>
                    </button>
                </Tooltip>
            </div>
        </AutocompleteNew>
    )
}
SmartForm.CustomerSelector = CustomerSelector;

const CustomerSelectorList = ({path, label, fullWidth, required, selection}) => {
    return (
        <ListField
            path={path}
            label={label}
            keyPath="ref"
            defaultItem={{name: '', ref: '', qbId: '', locationRef: '', newLocationNickname: ''}}
            fullWidth={fullWidth}
            required={required}
            renderRow={(_, i) => <CustomerSelector path={`${path}[${i}]`} selection={selection}/>}
        />
    )
}
SmartForm.CustomerSelectorList = CustomerSelectorList;

/**
 * Renders `StringField` or `CustomerSelector`
 * In the case of `StringField`, also includes an "Open Customer in new tab" link like CustomerSelector
 */
const CustomerInput = ({path, label, required, isSelector, setSelectedCustomer, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);
    const openCustomerInNewTab = (e) => {
        e.preventDefault();
        window.open(`/customers/${smartFormContext.getValue(path).ref}`, '_blank');
    }

    return isSelector ? <CustomerSelector path={path} label={label} required={required} setSelectedCustomer={setSelectedCustomer}/> : <StringField path={`${path}.name`} label={label} required={required} fullWidth={fullWidth}>
        <div className="text-start mb-1" style={{marginTop: '-.3rem'}}>
            <Tooltip pos="bottom" text={smartFormContext.getValue(path).ref ? "Open Customer in new tab" : "Open Customer in new tab (Push to Customer first)"}>
                <button className="btn btn-secondary btn-sm" onClick={openCustomerInNewTab} disabled={!smartFormContext.getValue(path).ref}>
                    <i className="fas fa-up-right-from-square"/>
                </button>
            </Tooltip>
        </div>
    </StringField>
}
SmartForm.CustomerInput = CustomerInput;

const CustomerLocationSelector = ({path, label, required, allowAddNew, fullWidth}) => {
    const [customer, setCustomer] = useState(null);
    const [options, setOptions] = useState([]);
    const smartFormContext = useContext(SmartFormContext);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(smartFormContext.getValue(path).ref && !customer) {
            chimera.callAPI(undefined, `/api/customers/accountNumber/${smartFormContext.getValue(path).ref}`)
            .then(c => setCustomer(c))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'Failed to read Customer; cannot load Location selection', 'Error');
                }
                else {
                    alert('ERROR: Failed to read Customer; cannot load Location selection');
                }
            })
        }
        else if(smartFormContext.getValue(path).ref === '' && customer) {
            setCustomer(null);
        }
    }, [customer, smartFormContext.getValue(path).ref])

    useEffect(() => {
        if(customer) {
            let opts = customer.locations.map(loc => {return {label: loc.nickname, value: loc._id}});
            if(allowAddNew) {
                opts.push({label: "+ Add Location...", value: "ADD_NEW_LOCATION"})
            }
            setOptions(opts);
        }
        else {
            setOptions([]);
        }
    }, [customer, allowAddNew]);

    return (
        <SelectField path={`${path}.locationRef`} label={label} required={required} fullWidth={fullWidth} options={options} excludeNoneSelected disabled={!customer}/>
    )
}
SmartForm.CustomerLocationSelector = CustomerLocationSelector;

const AssigneeSelector = ({path, label, required}) => {
    const [users, setUsers] = useState(null);
    const smartFormContext = useContext(SmartFormContext);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(users === null) {
            chimera.callAPI(undefined, '/api/users')
            .then(newUsers => setUsers(newUsers))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'Failed to load Users; Assignee selection will not be available.', 'Error');
                }
                else {
                    alert('ERROR: Failed to load Users; Assignee selection will not be available.');
                }
            })
        }
    }, [users]);

    const suggestionChosenCallback = (user) => {
        const oldValues = smartFormContext.getValue(path)
        const newValues = {
            first: user ? user.first : '',
            last: user ? user.last : '',
            email: user ? user.email : ''
        }
        if(!chimera.deepEqual(oldValues, newValues)) {
            const newObject = JSON.parse(JSON.stringify(smartFormContext.workingObj));
            chimera.setAttr(newObject, path, newValues);
            smartFormContext.setWorkingObj(newObject);
            smartFormContext.setModified(true);
        }
    }

    return (
        <AutocompleteNew
            label={`${label ? label : 'Assignee'}:`}
            value={smartFormContext.getValue(path).email}
            objects={users}
            labelRule={(u) => `${u.first} ${u.last}`}
            valueRule={(u) => u.email}
            objectChosenCallback={suggestionChosenCallback}
            strictMode
            required={required}
            disabled={smartFormContext.disabled}
            isLoading={users === null}
        />
    )
}
SmartForm.AssigneeSelector = AssigneeSelector;

const QBVendorSelector = ({path, label, required}) => {
    const [vendors, setVendors] = useState(null);
    const smartFormContext = useContext(SmartFormContext);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(vendors === null) {
            chimera.callQuickBooksAPI(undefined, '/api/qb/vendor')
            .then(newVendors => setVendors(newVendors))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'Failed to load QB Vendors; QB Vendor selection will not be available.', 'Error');
                }
                else {
                    alert('ERROR: Failed to load QB Vendors; QB Vendor selection will not be available.');
                }
            })
        }
    }, [vendors]);

    const suggestionChosenCallback = (vendor) => {
        const oldValues = smartFormContext.getValue(path)
        const newValues = {
            name: vendor ? vendor.DisplayName : '',
            ref: vendor ? vendor.Id : '',
        }
        if(!chimera.deepEqual(oldValues, newValues)) {
            const newObject = JSON.parse(JSON.stringify(smartFormContext.workingObj));
            chimera.setAttr(newObject, path, newValues);
            smartFormContext.setWorkingObj(newObject);
            smartFormContext.setModified(true);
        }
    }

    return (
        <AutocompleteNew
            label={`${label ? label : 'QB Vendor'}:`}
            value={smartFormContext.getValue(path).ref}
            objects={vendors}
            labelRule={(v) => v.DisplayName}
            valueRule={(v) => v.Id}
            objectChosenCallback={suggestionChosenCallback}
            strictMode
            required={required}
            disabled={smartFormContext.disabled}
            isLoading={vendors === null}
        />
    )
}
SmartForm.QBVendorSelector = QBVendorSelector;

const QBItemCategorySelector = ({path, label, required, selection}) => {
    const [categories, setCategories] = useState(null);
    const smartFormContext = useContext(SmartFormContext);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(categories === null && selection !== undefined) {
            chimera.callQuickBooksAPI(undefined, '/api/qb/itemcategories')
            .then(data => setCategories(data))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'Failed to load QB Item Categories; QB Item Category selection will not be available.', 'Error');
                }
                else {
                    alert('ERROR: Failed to load QB Item Categories; QB Item Category selection will not be available.');
                }
            })
        }
    }, [categories]);

    useEffect(() => {
        if(selection !== undefined) {
            setCategories(selection);
        }
    }, [selection]);

    const suggestionChosenCallback = (category) => {
        const oldValues = smartFormContext.getValue(path)
        const newValues = {
            name: category ? category.FullyQualifiedName : '',
            ref: category ? category.Id : '',
        }
        if(!chimera.deepEqual(oldValues, newValues)) {
            const newObject = JSON.parse(JSON.stringify(smartFormContext.workingObj));
            chimera.setAttr(newObject, path, newValues);
            smartFormContext.setWorkingObj(newObject);
            smartFormContext.setModified(true);
        }
    }

    return (
        <AutocompleteNew
            label={label}
            value={smartFormContext.getValue(path).ref}
            objects={categories}
            labelRule={(v) => v.FullyQualifiedName}
            valueRule={(v) => v.Id}
            objectChosenCallback={suggestionChosenCallback}
            strictMode
            required={required}
            disabled={smartFormContext.disabled}
            isLoading={categories === null}
            noSnap
        />
    )
}
SmartForm.QBItemCategorySelector = QBItemCategorySelector;

const QBItemCategorySelectorList = ({path, label, fullWidth, required, selection}) => {
    return (
        <ListField
            path={path}
            label={label}
            keyPath="ref"
            defaultItem={{name: '', ref: ''}}
            fullWidth={fullWidth}
            required={required}
            renderRow={(_, i) => <QBItemCategorySelector path={`${path}[${i}]`} selection={selection}/>}
        />
    )
}
SmartForm.QBItemCategorySelectorList = QBItemCategorySelectorList;

const QBItemSelector = ({path, label, required, selection}) => {
    const [items, setItems] = useState(null);
    const smartFormContext = useContext(SmartFormContext);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(items === null && selection === undefined) {
            chimera.callQuickBooksAPI(undefined, '/api/qb/items', 'POST')
            .then(data => setItems(data))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'Failed to load QB Items; QB Item selection will not be available.', 'Error');
                }
                else {
                    alert('ERROR: Failed to load QB Items; QB Item selection will not be available.');
                }
            })
        }
    }, [items]);

    useEffect(() => {
        if(selection !== undefined) {
            setItems(selection);
        }
    }, [selection]);

    const suggestionChosenCallback = (item) => {
        const oldValues = smartFormContext.getValue(path)
        const newValues = {
            name: item ? item.Name : '',
            ref: item ? item.Id : '',
        }
        if(!chimera.deepEqual(oldValues, newValues)) {
            const newObject = JSON.parse(JSON.stringify(smartFormContext.workingObj));
            chimera.setAttr(newObject, path, newValues);
            smartFormContext.setWorkingObj(newObject);
            smartFormContext.setModified(true);
        }
    }

    return (
        <AutocompleteNew
            label={label}
            value={smartFormContext.getValue(path).ref}
            objects={items}
            labelRule={(item) => item.Name}
            valueRule={(item) => item.Id}
            objectChosenCallback={suggestionChosenCallback}
            strictMode
            required={required}
            disabled={smartFormContext.disabled}
            isLoading={items === null}
            noSnap
        />
    )
}
SmartForm.QBItemSelector = QBItemSelector;

const QBItemSelectorList = ({path, label, fullWidth, required, selection}) => {
    return (
        <ListField
            path={path}
            label={label}
            keyPath="ref"
            defaultItem={{name: '', ref: ''}}
            fullWidth={fullWidth}
            required={required}
            renderRow={(_, i) => <QBItemSelector path={`${path}[${i}]`} selection={selection}/>}
        />
    )
}
SmartForm.QBItemSelectorList = QBItemSelectorList;

/**
 * 
 * @param {String} path The path to the QB Invoice (subschema), not to the number directly 
 * @returns 
 */
const QBInvoiceField = ({path, label, required, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);
    const banners = useContext(BannerContext);

    const [isVerifying, setIsVerifying] = useState(false);

    // override form's handleChange so we can reset qbInvoice.id and qbInvoice.status
    const handleChange = (e) => {
        e.preventDefault();
        let newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObj));
        chimera.setAttr(newWorkingObj, e.target.name, e.target.value);
        chimera.setAttr(newWorkingObj, `${path}.id`, '');
        chimera.setAttr(newWorkingObj, `${path}.status`, 'NOT SET');
        smartFormContext.setWorkingObj(newWorkingObj);
        smartFormContext.setModified(true);
    }

    const openInvoiceInNewTab = (e) => {
        e.preventDefault();
        window.open(`https://app.qbo.intuit.com/app/invoice?txnId=${smartFormContext.getValue(`${path}.id`)}`, '_blank');
    }

    const performLookup = (e) => {
        e.preventDefault();
        setIsVerifying(true);
        chimera.callQuickBooksAPI(undefined, `/api/qb/invoice/${smartFormContext.getValue(`${path}.number`)}`)
        .then(invoice => {
            const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObj));
            chimera.setAttr(newWorkingObj, `${path}.id`, invoice.Id);
            chimera.setAttr(newWorkingObj, `${path}.status`, invoice.Balance === 0 ? "Paid" : "Unpaid");
            smartFormContext.setWorkingObj(newWorkingObj);
            smartFormContext.setModified(true);
        })
        .catch(err => {
            console.error(err);
            if(banners) {
                banners.addBanner('danger', `Failed to verify QB Invoice #${smartFormContext.getValue(`${path}.number`)}`, 'QB Error');
            }
            else {
                alert(`ERROR: Failed to verify QB Invoice #${smartFormContext.getValue(`${path}.number`)}`);
            }
            const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObj));
            chimera.setAttr(newWorkingObj, `${path}.id`, '');
            chimera.setAttr(newWorkingObj, `${path}.status`, 'NOT SET');
            smartFormContext.setWorkingObj(newWorkingObj);
            smartFormContext.setModified(true);
        })
        .finally(() => {
            setIsVerifying(false);
        })
    }

    return (
        <FormFieldMicro
            type="text"
            name={`${path}.number`}
            label={label}
            value={smartFormContext.getValue(`${path}.number`)}
            handleChange={handleChange}
            onBlur={trimOnBlur(smartFormContext)}
            disabled={smartFormContext.disabled}
            required={required}
            size={6}
            fit={!fullWidth}
            fullWidth={fullWidth}
            useNewChildPos
        >
            <div className="mt-1 text-start">
                <Tooltip pos="bottom" text="Verify Invoice">
                    <button className="btn btn-secondary btn-sm me-1" onClick={performLookup} disabled={smartFormContext.disabled || isVerifying || smartFormContext.getValue(`${path}.number`) === ''}>
                        <i className={isVerifying ? "fas fa-spinner" : "fas fa-rotate"}/>
                    </button>
                </Tooltip>
                <Tooltip pos="bottom" text="Open QB Invoice in New Tab">
                    <button className="btn btn-secondary btn-sm" onClick={openInvoiceInNewTab} disabled={smartFormContext.getValue(`${path}.id`) === ''}>
                        <i className="fas fa-up-right-from-square"/>
                    </button>
                </Tooltip>
                <br/>
                <span>Verified:&nbsp;<i className={smartFormContext.getValue(`${path}.id`) ? 'fas fa-check text-success' : 'fas fa-times text-danger'}/></span>
                <br/>
                <span>Status: {smartFormContext.getValue(`${path}.status`) !== "NOT SET" ? smartFormContext.getValue(`${path}.status`) : "(Verify first)"}</span>
            </div>
        </FormFieldMicro>
    )
}
SmartForm.QBInvoiceField = QBInvoiceField;

const QBEstimateField = ({path, label, required, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);
    const banners = useContext(BannerContext);

    const [isVerifying, setIsVerifying] = useState(false);

    // override form's handleChange so we can reset the ID
    const handleChange = (e) => {
        e.preventDefault();
        let newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObj));
        chimera.setAttr(newWorkingObj, e.target.name, e.target.value);
        chimera.setAttr(newWorkingObj, `${path}.id`, '');
        smartFormContext.setWorkingObj(newWorkingObj);
        smartFormContext.setModified(true);
    }

    const openEstimateInNewTab = (e) => {
        e.preventDefault();
        window.open(`https://app.qbo.intuit.com/app/estimate?txnId=${smartFormContext.getValue(`${path}.id`)}`, '_blank');
    }

    const performLookup = (e) => {
        e.preventDefault();
        setIsVerifying(true);
        chimera.callQuickBooksAPI(undefined, `/api/qb/estimate/${smartFormContext.getValue(`${path}.number`)}`)
        .then(estimate => {
            const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObj));
            chimera.setAttr(newWorkingObj, `${path}.id`, estimate.Id);
            smartFormContext.setWorkingObj(newWorkingObj);
            smartFormContext.setModified(true);
        })
        .catch(err => {
            console.error(err);
            if(banners) {
                banners.addBanner('danger', `Failed to verify QB Estimate #${smartFormContext.getValue(`${path}.number`)}`, 'QB Error');
            }
            else {
                alert(`ERROR: Failed to verify QB Estimate #${smartFormContext.getValue(`${path}.number`)}`);
            }
            const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObj));
            chimera.setAttr(newWorkingObj, `${path}.id`, '');
            smartFormContext.setWorkingObj(newWorkingObj);
            smartFormContext.setModified(true);
        })
        .finally(() => {
            setIsVerifying(false);
        })
    }

    return (
        <FormFieldMicro
            type="text"
            name={`${path}.number`}
            label={label}
            value={smartFormContext.getValue(`${path}.number`)}
            handleChange={handleChange}
            onBlur={trimOnBlur(smartFormContext)}
            disabled={smartFormContext.disabled}
            required={required}
            size={6}
            fit={!fullWidth}
            fullWidth={fullWidth}
            useNewChildPos
        >
            <div className="mt-1 text-start">
                <Tooltip pos="bottom" text="Verify Invoice">
                    <button className="btn btn-secondary btn-sm me-1" onClick={performLookup} disabled={smartFormContext.disabled || isVerifying || smartFormContext.getValue(`${path}.number`) === ''}>
                        <i className={isVerifying ? "fas fa-spinner" : "fas fa-rotate"}/>
                    </button>
                </Tooltip>
                <Tooltip pos="bottom" text="Open QB Invoice in New Tab">
                    <button className="btn btn-secondary btn-sm" onClick={openEstimateInNewTab} disabled={smartFormContext.getValue(`${path}.id`) === ''}>
                        <i className="fas fa-up-right-from-square"/>
                    </button>
                </Tooltip>
                <br/>
                <span>Verified:&nbsp;<i className={smartFormContext.getValue(`${path}.id`) ? 'fas fa-check text-success' : 'fas fa-times text-danger'}/></span>
            </div>
        </FormFieldMicro>
    )
}
SmartForm.QBEstimateField = QBEstimateField;

// Copied from Mini Notes. Copied instead of composed to use preSaves
const Notes = ({path}) => {
    const [text, setText] = useState('');
    const smartFormContext = useContext(SmartFormContext);
    const userContext = useContext(UserContext);

    useEffect(() => {
        smartFormContext.addPreSave(path, (objToSave) => new Promise((resolve, reject) => {
            if(text) {
                const newNotes = addNote({preventDefault: () => {}});
                chimera.setAttr(objToSave, path, newNotes);
            }
            resolve(objToSave);
        }))
        // TODO: do I need to call removePreSave? it was causing problems so I'd like to avoid it
    }, [path, text]);

    const handleChange = (event) => {
        event.preventDefault();
        if(event.target.name === "text") {
            setText(event.target.value);
            smartFormContext.setModified(true);
        }
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const setNotes = (newNotes) => {
        const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObj));
        chimera.setAttr(newWorkingObj, path, newNotes);
        smartFormContext.setWorkingObj(newWorkingObj);
        smartFormContext.setModified(true);
    }

    const addNote = (event) => {
        event.preventDefault();
        let newNotes = JSON.parse(JSON.stringify(smartFormContext.getValue(path)));
        newNotes.unshift({
            author: {
                first: userContext.user.first,
                last: userContext.user.last,
                email: userContext.user.email
            },
            text: text,
            createdAt: new Date(),
            flagged: false
        })
        setNotes(newNotes);
        setText('');
        return newNotes;
    }

    const toggleFlagAtIndex = (i) => {
        let newNotes = JSON.parse(JSON.stringify(smartFormContext.getValue(path)));
        newNotes[i].flagged = !newNotes[i].flagged;
        setNotes(newNotes);
    }

    const removeNoteAtIndex = (i) => {
        setNotes(smartFormContext.getValue(path).filter((_, index) => index !== i));
    }

    const noteDisplay = (note, i) => {
        let date = null;
        if(note.createdAt) {
            date = new Date(note.createdAt);
        }
        return <div key={i} className="section-outline w-100 text-start mt-2">
            <strong>{note.author.first} {note.author.last}</strong>&nbsp;
            <button className="btn p-0 ps-1" onClick={(e) => {e.preventDefault(); toggleFlagAtIndex(i)}}>
                <i className={`fas fa-flag ${note.flagged ? 'text-danger' : 'text-muted'}`}/>
            </button>
            <button className="btn p-0 ps-1" onClick={(e) => {e.preventDefault(); removeNoteAtIndex(i)}}>
                <i className={'fas fa-trash text-muted'}/>
            </button>
            <br/>
            <i className="text-muted">{note.createdAt ? date.toLocaleString() : "(Unsaved)"}</i>
            <p>{note.text}</p>
        </div>
    }

    return (
        <div>
            <FormFieldMicro
                type="textarea"
                name="text"
                label="Notes"
                value={text}
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={smartFormContext.disabled}
                resize="vertical"
            />
            <button className="btn btn-primary" disabled={text.trim() === "" || smartFormContext.disabled} onClick={addNote}>
                <i className="fas fa-plus"/>&nbsp;Post Note
            </button>
            <div className="overflow-auto" style={{maxHeight: 850}}>
                {smartFormContext.getValue(path).sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).map((note, i) => noteDisplay(note, i))}
            </div>
        </div>
    );
}
SmartForm.Notes = Notes;

/** FIELDS END */
/** SECTIONS START */

const Section = ({children, nCols, bordered}) => {
    // for some reason when there is only one child it is not passed as an array, so we turn it into one
    const childrenArr = () => {
        if(!children) return [];
        if(children.length) return children.filter(child => child !== null);
        else return [children];
    }

    return (
        <div className={bordered ? "section-outline" : ""}>
            <div className={`row row-cols-1 row-cols-md-${nCols} g-${nCols}`}>
                {childrenArr().map((child, i) => <div key={i} className="col">{child}</div>)}
            </div>
        </div>
    )
}
SmartForm.Section = Section;

const FlexSection = ({children, bordered}) => {
    return (
        <div className={bordered ? "section-outline" : ""}>
            <div className={`row g-${children.length ? children.length : 1}`}>
                {children}
            </div>
        </div>
    )
}
SmartForm.FlexSection = FlexSection;

const FlexSectionCol = ({children, span}) => {
    return (
        <div className={`col-${span}`}>
            {children}
        </div>
    )
}
SmartForm.FlexSectionCol = FlexSectionCol;

const Divider = ({}) => {
    return (
        <hr/>
    )
}
SmartForm.Divider = Divider;

/** TODO: Include "Add Attachment" button within the section so attachment functionality relies on this subcomponent alone and isn't dependent upon an additional button */
const AttachmentsSection = ({path}) => {
    const smartFormContext = useContext(SmartFormContext);
    const modaling = useContext(ModalContext);
    const banners = useContext(BannerContext);

    const downloadAttachment = (attachment) => {
        const modal = <Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.backtrack();}}>
            <LoadingSpinner size={75} label="Downloading..."/>
        </Modal>
        modaling.setModal(modal);

        chimera.callAPI(undefined, `/api/file/${attachment.id}`)
        .then(file => {
            fetch(`data:${file.type};base64,${file.content}`)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = file.filename;
                link.click();
            })
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', `Failed to download file (ID: ${attachment.id})`, 'Error');
                }
                else {
                    alert(`ERROR: Failed to download file (ID: ${attachment.id})`);
                }
            })
        })
        .catch(err => {
            console.error(err);
            if(banners) {
                banners.addBanner('danger', `Failed to download file (ID: ${attachment.id})`, 'Error');
            }
            else {
                alert(`ERROR: Failed to download file (ID: ${attachment.id})`);
            }
        })
        .finally(() => {
            modaling.backtrack();
        })
    }

    const sizeAbbreviation = (sizeInBytes) => {
        if(sizeInBytes >= 1000000) {
            return `${(sizeInBytes / 1000000).toFixed(1)} MB`;
        }
        else if(sizeInBytes >= 1000) {
            return `${(sizeInBytes / 1000).toFixed(1)} KB`;
        }
        else {
            return `${(sizeInBytes).toFixed(1)} B`;
        }
    }

    const deleteAttachment = (attachment) => {
        const choices = [
            choiceCancel({modalContext: modaling, backtrack: true}),
            choiceDelete(
                {modalContext: modaling},
                () => {
                    const modal = <Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.backtrack(); modaling.backtrack();}}>
                        <LoadingSpinner size={75} label="Deleting..."/>
                    </Modal>
                    modaling.setModal(modal);
            
                    chimera.callAPI(undefined, `/api/file/${attachment.id}`, 'DELETE')
                    .then(async _ => {
                        const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObj));
                        chimera.setAttr(newWorkingObj, path, chimera.getAttr(newWorkingObj, path).filter(a => a.id !== attachment.id));
                        try {
                            await smartFormContext.handleSave(newWorkingObj, false);
                        }
                        catch(err) {
                            // dont care
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        banners.addBanner('danger', 'Failed to delete file', 'Error');
                    })
                    .finally(() => {
                        modaling.backtrack();
                        modaling.backtrack();
                    })
                },
                {noConfirm: true}
            )
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure?</h3>
            <p>Are you sure you want to delete the attached file? This operation cannot be undone. Filename: {attachment.filename}</p>
        </Modal>
        modaling.setModal(modal);
    }

    const addAttachment = (file, contents) => {
        chimera.callAPI(undefined, '/api/file', 'POST', {
            filename: file.name,
            type: file.type,
            size: file.size,
            content: contents,
            encoding: 'base64'
        })
        .then(async savedFile => {
            const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObj));
            let newAttachment = {
                filename: savedFile.filename,
                type: savedFile.type,
                size: savedFile.size,
                id: savedFile._id
            }
            if(chimera.getAttr(newWorkingObj, path) === undefined || chimera.getAttr(newWorkingObj, path) === null) {
                chimera.setAttr(newWorkingObj, path, [newAttachment]);
            }
            else {
                chimera.setAttr(newWorkingObj, path, [...chimera.getAttr(newWorkingObj, path), newAttachment]);
            }
            try {
                await smartFormContext.handleSave(newWorkingObj, false);
            }
            catch(err) {
                // dont care
            }
        })
        .catch(err => {
            console.error(err);
            if(banners) {
                banners.addBanner('danger', 'Failed to upload attachment', 'Error');
            }
            else {
                alert('ERROR: Failed to upload attachment');
            }
        })
        .finally(() => {
            modaling.backtrack();
        })
    }

    const openFileUploadModal = (event) => {
        event.preventDefault();
        const choices = [
            choiceCancel({modalContext: modaling, backtrack: true}),
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <FileUpload callback={addAttachment}/>
        </Modal>
        modaling.setModal(modal);
    }

    return (
        <div className="section-outline">
            <div className="row">
                <div className="col">
                    <h5 className="text-start">Attachments</h5>
                </div>
            </div>
            <div className="row">
                <div className="col text-start">
                    {!smartFormContext.getValue(path) || smartFormContext.getValue(path).length === 0 ? <span className="text-muted">There are no attachments yet</span> : <ol className="text-start">
                        {smartFormContext.getValue(path).map((attachment, i) => <li key={i}>
                            <span>
                                <a href="#" onClick={(e) => {e.preventDefault(); downloadAttachment(attachment)}}>
                                    {attachment.filename}
                                </a>
                                &nbsp;
                                ({sizeAbbreviation(attachment.size)})
                                &nbsp;
                                <button className="btn btn-sm btn-danger" onClick={(e) => {e.preventDefault(); deleteAttachment(attachment)}} disabled={smartFormContext.disabled}>
                                    <i className="fas fa-times"/>
                                </button>
                            </span>
                        </li>)}
                    </ol>}
                    <br/>
                    <button className="btn btn-success mt-2" onClick={openFileUploadModal} disabled={smartFormContext.disabled}>
                        <i className="fas fa-plus"/>&nbsp;Upload Attachment
                    </button>
                </div>
            </div>
        </div>
    )
}
SmartForm.AttachmentsSection = AttachmentsSection;

const BlameSection = ({path, bordered}) => {
    const smartFormContext = useContext(SmartFormContext);
    return (
        <div className={bordered ? 'text-start section-outline' : 'text-start'}>
            <p className="text-muted">Created by {smartFormContext.getValue(path).createdBy.first} {smartFormContext.getValue(path).createdBy.last} ({smartFormContext.getValue(path).createdBy.email}) at {(new Date(smartFormContext.getValue('createdAt'))).toLocaleString()}</p>
            <p className="text-muted">Last modified by by {smartFormContext.getValue(path).modifiedBy.first} {smartFormContext.getValue(path).modifiedBy.last} ({smartFormContext.getValue(path).modifiedBy.email}) at {(new Date(smartFormContext.getValue('updatedAt'))).toLocaleString()}</p>
        </div>
    )
}
SmartForm.BlameSection = BlameSection;

/** SECTIONS END */

const exactOptions = (arr) => {
    let opts = [];
    for(const str of arr) {
        opts.push({label: str, value: str});
    }
    return opts;
}

export {SmartForm as default, exactOptions};