import React, {useContext} from 'react';

import ModalContext from '../ModalContext';
import ExportModal from '../ExportModal';
import ProductOrderFormModal from './ProductOrderFormModal';
import UserContext from '../../UserContext';
import AlertsContext from '../AlertsContext';
import Tooltip from '../Tooltip';
import ObjectTable from '../ObjectTable';

const getDescriptionPreview = (order) => {
    return order.items[0].description.substring(0, 20) + (order.items[0].description.length > 20 ? '...' : '');
}

/**
 * 
 * @param {Array} props.orders The list of orders to display
 * @param {Function} props.clickedOrder A function that takes the clicked order as an argument
 */
const ProductOrdersTable = props => {
    const modaling = useContext(ModalContext);
    const userContext = useContext(UserContext);
    const alerts = useContext(AlertsContext);

    const openNewOrderForm = (_) => {
        modaling.setModal(null);
        modaling.setModal(<ProductOrderFormModal parentBanners={props.parentBanners} modalContext={modaling} onClose={() => {props.setOrders(null); alerts.reload()}}/>)
    }

    const openNewOrderEstimateForm = (_) => {
        modaling.setModal(null);
        modaling.setModal(<ProductOrderFormModal isEstimate parentBanners={props.parentBanners} modalContext={modaling} onClose={() => {props.setOrders(null); alerts.reload()}}/>)
    }

    /** TODO: Implement exporting for ProductOrders */
    const openExportModal = (selectedObjects) => {
        modaling.setModal(<ExportModal objects={selectedObjects} model="productOrder"/>);
    }

    const statusColor = (status) => {
        switch(status) {
            case "New":
            case "Estimate":
                return "info";
            case "Ordered":
                return "success";//"warning";
            case "Backordered":
                return "dark";
            case "Ready to Pay Vendor":
            case "Pending":
            case "Invoiced":
                return "warning";//"secondary";
            case "Disconnect":
            case "Cancelled":
                return "danger";
            case "Received":
                return "primary";
            case "Completed":
                return "secondary";//"success";
            default:
                return "light";
        }
    }

    const estimateStatusColor = (status) => {
        switch(status) {
            case "Pending":
                return "info";
            case "Requested":
                return "primary";
            case "Sent":
                return "warning";
            case "Approved":
                return "success";
            case "Denied":
                return "danger";
            default:
                return "light";
        }
    }

    const typeColor = (type) => {
        switch(type) {
            case "New":
                return "info";
            case "From Stock":
                return "warning";
            case "Disconnect":
            case "Return":
                return "danger";
            default:
                return "light";
        }
    }

    const renderFlag = (obj) => {
        const reason = alerts.orderReason(obj._id, 'product');
        if(!reason) return null;
        else return <>&nbsp;<Tooltip text={reason}><i className="fas fa-flag text-danger"/></Tooltip></>
    }

    return (
        <ObjectTable 
            id="productOrdersTable"
            cols={[
                {
                    label: 'Type', 
                    sort: (a, b) => {
                        const values = {
                            'New': 0,
                            'From Stock': 1,
                            'Return': 2,
                            'Disconnect': 3,
                        }
                        return values[`${a.type}`] < values[`${b.type}`] ? -1 : 1;
                    },
                    render: (obj) => obj.type,
                    color: (obj) => typeColor(obj.type)
                },
                {
                    label: 'Estimate Status',
                    sort: (a, b) => {
                        const values = {
                            'NOT SET': 0,
                            'Pending': 1,
                            'Requested': 2,
                            'Sent': 3,
                            'Approved': 4,
                            'Denied': 5,
                        }
                        return values[`${a.estimateStatus}`] < values[`${b.estimateStatus}`] ? -1 : 1;
                    },
                    render: (obj) => obj.estimateStatus,
                    color: (obj) => estimateStatusColor(obj.estimateStatus)
                },
                {
                    label: 'Order Status',
                    sort: (a, b) => {
                        const values = {
                            'NOT SET': 0,
                            'New': 1,
                            'Estimate': 2,
                            'Ready to Pay Vendor': 3,
                            'Approved': 4,
                            'Invoiced': 5,
                            'Ordered': 6,
                            'Pending': 7,
                            'Backordered': 8,
                            'Cancelled': 9,
                            'Received': 10,
                            'Completed': 11,
                            'Disconnect': 12
                        }
                        return values[`${a.status}`] < values[`${b.status}`] ? -1 : 1;
                    },
                    render: (obj) => obj.status,
                    color: (obj) => statusColor(obj.status)
                },
                {
                    label: 'Customer Name',
                    sort: (a, b) => a.customer.displayName < b.customer.displayName ? -1 : 1,
                    render: (obj) => <>{obj.customer.displayName}{renderFlag(obj)}</>
                },
                {
                    label: 'Description',
                    sort: (a, b) => getDescriptionPreview(a) < getDescriptionPreview(b) ? -1 : 1,
                    render: (object) => <div className="tooltip-container border-0">
                        <span className="tooltip-text p-2">
                            {object.items.map((item, i) => `Item ${i+1}: ${item.description}`).join('\n\n')}
                        </span>
                        {getDescriptionPreview(object)}
                    </div>
                },
                {
                    label: 'QB Invoice #',
                    sort: (a, b) => a.qbInvoiceNumber < b.qbInvoiceNumber ? -1 : 1,
                    render: (obj) => obj.qbInvoiceNumber
                },
                {
                    label: 'QB Invoice Status',
                    sort: (a, b) => {
                        const values = {
                            'Paid': 0,
                            'Unpaid': 1,
                            'NOT SET': 2,
                            '': 3
                        }
                        return values[`${a.qbInvoiceStatus}`] < values[`${b.qbInvoiceStatus}`] ? -1 : 1;
                    },
                    render: (object) => object.qbInvoiceId ? (object.qbInvoiceStatus !== "NOT SET" ? object.qbInvoiceStatus : "(Check QB)") : ""
                },
                {
                    label: 'Date Purchased',
                    sort: (a, b) => a.datePurchased < b.datePurchased ? -1 : 1,
                    render: (object) => object.datePurchased ? (new Date(`${object.datePurchased}T13:00:00`)).toLocaleDateString() : ''
                },
                {
                    label: 'PO #',
                    sort: (a, b) => a.number < b.number ? -1 : 1,
                    render: (obj) => obj.bundleNumber
                },
                {
                    label: 'Assignee',
                    sort: (a, b) => a.assignee.email < b.assignee.email ? -1 : 1,
                    render: (obj) => obj.assignee.email ? `${obj.assignee.first.substring(0,1)}${obj.assignee.last.substring(0,1)}` : '(None)'
                },
            ]}
            objects={props.orders}
            actions={[
                {label: 'Export', func: openExportModal},
            ]}
            filters={[
                {label: 'My Orders', value: 'Mine', func: (order) => order.status !== "Completed" && order.status !== "Received" && (order.blame.createdBy.email === userContext.user.email || order.assignee.email === userContext.user.email)},
                {label: 'Assigned to Me', value: 'Assigned', func: (order) => order.status !== "Completed" && order.status !== "Received" && order.assignee.email === userContext.user.email},
                {label: 'All Active Orders', value: 'All', func: (order) => order.status !== "Completed" && order.status !== "Cancelled" && order.status !== "Ready to Pay Vendor" && order.estimateStatus !== "Denied"},
                {label: 'Ready to Pay Vendor', value: 'ReadyToPayVendor', func: (order) => (order.status === "Ready to Pay Vendor")},
                {label: 'Completed', value: 'Archived', func: (order) => (order.status === "Completed")},
                {label: 'Cancelled', value: 'Cancelled', func: (order) => (order.status === "Cancelled" || order.estimateStatus === "Denied")},
                {label: 'Everything', value: 'Everything', func: (_) => true},
            ]}
            btns={[
                {label: 'Request', func: openNewOrderEstimateForm},
                {label: 'Order', func: openNewOrderForm}
            ]}
            onClicked={props.clickedOrder}
            search
            paginated
            defaultSortByColName="Status"
            defaultFilterSetting={userContext.user.email === "lindsey@gocbit.com" ? 'All' : 'Mine'}
            defaultSortAscending={true}
        />
    )
}

export default ProductOrdersTable