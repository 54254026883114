import React, {useState, useEffect, useContext} from "react";
import MonthSelector from "../../MonthSelector";
import BannerContext from "../../BannerLogContext";
import chimera from "../../../chimera";
import ObjectTable from "../../ObjectTable";
import DataEntryFormModal from "./DataEntryFormModal";
import ModalContext from "../../ModalContext";
import useSmoothFormModal from "../../../hooks/useSmoothFormModal";
import ModalCancelOnly from "../../ModalCancelOnly";
import Modal from "../../Modal";
import LoadingSpinner from "../../LoadingSpinner";

const ManageEntries = ({}) => {
    const [month, setMonth] = useState('');
    const [entries, setEntries] = useState(null);
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);
    const {setSavedObj, removeById} = useSmoothFormModal(entries, setEntries);

    useEffect(() => {
        if(month !== '') {
            setEntries(null);
        }
    }, [month]);

    useEffect(() => {
        if(entries === null && month !== '') {
            chimera.callAPI(undefined, `/api/revenuedeltaentries/month/${month}`)
            .then(data => setEntries(data))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read Data Entries; the table cannot be loaded.', 'Error');
            })
        }
    }, [entries, month]);

    const onClicked = (entry) => {
        modaling.setModal(<DataEntryFormModal entry={entry} parentSetSavedObj={setSavedObj} removeById={removeById}/>)
    }

    const confirmDelete = (selectedObjects) => {
        const choices = [
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Cancel</span>,
                func: (e) => {
                    e.preventDefault();
                    modaling.backtrack();
                }
            },
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Delete {selectedObjects.length} IPs</span>,
                func: e => {
                    e.preventDefault();
                    modaling.setModal(<ModalCancelOnly context={modaling}><LoadingSpinner size={75} label="Deleting..."/></ModalCancelOnly>)
                    Promise.all(selectedObjects.map(obj => new Promise((resolve, reject) => {
                        chimera.callAPI(undefined, `/api/revenuedeltaentries/${obj._id}`, 'DELETE')
                        .then(response => resolve(response))
                        .catch(err => reject(err))
                    })))
                    .then(_ => {
                        setEntries(null);
                        banners.addBanner('info', `Deleted ${selectedObjects.length} Data Entries.`, 'Success');
                    })
                    .catch(err => {
                        console.error(err);
                        banners.addBanner('danger', 'An error occurred during bulk deletion; operation aborted.', 'Error');
                    })
                    .finally(() => {
                        modaling.setModal(null);
                    })
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure?</h3>
            <p>You are about to delete {selectedObjects.length} Data Entries permanently from Chimera.</p>
        </Modal>
        modaling.setModal(modal);
    }

    return (
        <div>
            <p className="fs-3"><MonthSelector relativeDefaultMonth={0} setMonth={setMonth}/></p>
            <ObjectTable 
                id="revenueDeltaEntriesTable"
                cols={[
                    {
                        label: 'ID', 
                        sort: (a, b) => a._id < b._id ? -1 : 1,
                        render: (obj) => obj._id
                    },
                    {
                        label: 'Month', 
                        sort: (a, b) => a.month < b.month ? -1 : 1,
                        render: (obj) => chimera.monthAsStr(obj.month),
                    },
                    {
                        label: 'Customer Name',
                        sort: (a, b) => a.customerName < b.customerName ? -1 : 1,
                        render: (obj) => obj.customerName
                    },
                    {
                        label: '# of Invoices',
                        sort: (a, b) => a.invoices.length < b.invoices.length ? -1 : 1,
                        render: (obj) => obj.invoices.length
                    },
                    {
                        label: '# of SalesReceipts',
                        sort: (a, b) => a.salesReceipts.length < b.salesReceipts.length ? -1 : 1,
                        render: (obj) => obj.salesReceipts.length
                    },
                ]}
                filters={[
                    {label: 'Default', value: 'Default', func: (_) => true},
                    {label: 'No Transactions', value: 'No Transactions', func: (entry) => entry.invoices.length === 0 && entry.salesReceipts.length === 0},
                ]}
                actions={[
                    {label: 'Delete', func: confirmDelete, classes: 'text-danger'},
                ]}
                objects={entries}
                onClicked={onClicked}
                search
                paginated
                defaultSortByColName="Customer Name"
                defaultSortAscending={true}
            />
        </div>
    )
}

export default ManageEntries;