import {useState, useEffect} from 'react';

function useScreens(initialScreen, screenOptions, reset) {
    const [screen, setScreen] = useState(initialScreen);

    useEffect(() => {
        if(screen === initialScreen && reset) {
            reset();
        }
        else if(!screenOptions.includes(screen)) {
            setScreen(initialScreen);
        }
    }, [screen, initialScreen]);

    return {
        screen,
        setScreen,
        startScreenName: initialScreen,
    }
}

export default useScreens;