import React, {useState, useEffect, useContext} from "react";
import {ScreenGroupContext} from "../../ScreenGroup";
import LoadingSpinner from '../../LoadingSpinner';
import BannerContext from "../../BannerLogContext";
import chimera from "../../../chimera";
import useGet from "../../../hooks/useGet";

const RetrieveData = ({month}) => {
    const [label, setLabel] = useState("Loading...");
    const [customers] = useGet('/api/customers/integration/quickbooks', 'Customer', {keepNull: true});
    const [txns, setTxns] = useState(null);
    const [qbItemsByServiceType, setQbItemsByServiceType] = useState(null);
    const banners = useContext(BannerContext);
    const screenContext = useContext(ScreenGroupContext);

    useEffect(() => {
        if(txns === null && customers !== null) {
            chimera.callQuickBooksAPI(undefined, '/api/qb/revenuedeltaquery', 'POST', {month, customerIds: customers.map(c => c.integrationIds.quickbooks)})
            .then(data => {
                setTxns(data);
            })
            .catch(err => {
                console.error(err);
                setLabel('ERROR');
                if(banners) {
                    banners.addBanner('danger', 'Failed to read QB Invoices & SalesReceipts; the tool cannot continue.', 'Error');
                }
                else {
                    alert('ERROR: Failed to read QB Invoices & SalesReceipts; the tool cannot continue.');
                }
                screenContext.setScreen('START');
            })
        }
    }, [txns, customers]);

    useEffect(() => {
        if(qbItemsByServiceType === null) {
            chimera.callAPI(undefined, '/api/settings/QB_ITEM_SERVICE_TYPES')
            .then(attr => {
                setQbItemsByServiceType(attr.value);
            })
            .catch(err => {
                console.error(err);
                setLabel('ERROR');
                if(banners) {
                    banners.addBanner('danger', 'Failed to read QB Item Service Types; the tool cannot continue.', 'Error');
                }
                else {
                    alert('ERROR: Failed to read QB Item Service Types; the tool cannot continue.');
                }
                screenContext.setScreen('START');
            })
        }
    }, [qbItemsByServiceType]);

    const getTxnTotals = (lines) => {
        const serviceTypes = ['internet', 'voip', 'msp', 'video'];
        const salesItemLines = lines.filter(line => line.DetailType === 'SalesItemLineDetail');
        let totals = {};
        for(const serviceType of serviceTypes) {
            totals[serviceType] = 0;
        }
        for(const line of salesItemLines) {
            for(const serviceType of serviceTypes) {
                let counted = false;
                for(const category of qbItemsByServiceType[serviceType].categories) {
                    if(line.SalesItemLineDetail.ItemRef.value === "SHIPPING_ITEM_ID") continue;
                    if(line.SalesItemLineDetail.ItemRef.name.startsWith(`${category.name}:`)) {
                        totals[serviceType] += line.Amount;
                        counted = true;
                        break;
                    }
                }
                if(!counted) {
                    for(const item of qbItemsByServiceType[serviceType].items) {
                        if(line.SalesItemLineDetail.ItemRef.value === item.ref) {
                            totals[serviceType] += line.Amount;
                        }
                    }
                }
            }
        }
        return totals;
    }

    const entryTxn = (txn) => {
        return {
            id: txn.Id,
            docNumber: txn.DocNumber,
            txnDate: txn.TxnDate,
            totals: getTxnTotals(txn.Line)
        }
    }

    useEffect(() => {
        if(customers !== null && txns !== null && qbItemsByServiceType !== null) {
            setLabel("Running...");
            let entries = [];
            for(const customer of customers) {
                const customerInvoices = txns.invoices.filter(inv => inv.CustomerRef.value === customer.integrationIds.quickbooks);
                const customerSalesReceipts = txns.salesReceipts.filter(sr => sr.CustomerRef.value === customer.integrationIds.quickbooks);
                let entry = {
                    customerName: customer.displayName,
                    customerRef: customer.accountNumber,
                    invoices: [],
                    salesReceipts: [],
                    month
                };
                for(const inv of customerInvoices) {
                    try {
                        entry.invoices.push(entryTxn(inv));
                    }
                    catch(err) {
                        console.error(err);
                        if(err.toString() === "TypeError: line.SalesItemLineDetail.ItemRef.name is undefined") {
                            banners.addBanner('danger', `Failed to check for category matches on Invoice #${inv.DocNumber} (ID: ${inv.Id}); it will be excluded from the report.`);
                            console.error(inv);
                        }
                        else {
                            banners.addBanner('danger', `An unhandled error occurred when handling Invoice #${inv.DocNumber} (ID: ${inv.Id}); the tool cannot continue.`);
                            return;
                        }
                    }
                }
                for(const sr of customerSalesReceipts) {
                    try {
                        entry.salesReceipts.push(entryTxn(sr));
                    }
                    catch(err) {
                        console.error(err);
                        if(err.toString() === "TypeError: line.SalesItemLineDetail.ItemRef.name is undefined") {
                            banners.addBanner('danger', `Failed to check for category matches on SalesReceipt #${sr.DocNumber} (ID: ${sr.Id}); it will be excluded from the report.`);
                        }
                        else {
                            banners.addBanner('danger', `An unhandled error occurred when handling SalesReceipt #${sr.DocNumber} (ID: ${sr.Id}); the tool cannot continue.`);
                            return;
                        }
                    }
                }
                entries.push(entry);
            }
            chimera.callAPI(undefined, '/api/revenuedeltaentries/batch', 'POST', {entries})
            .then(_ => {
                screenContext.setScreen('DOWNLOAD_REPORT');
            })
            .catch(err => {
                console.error(err);
                setLabel('ERROR');
                if(banners) {
                    banners.addBanner('danger', 'Failed to save data; the tool cannot continue.', 'Error');
                }
                else {
                    alert('ERROR: Failed to save data; the tool cannot continue.');
                }
                screenContext.setScreen('START');
            })
        }
    }, [customers, txns, qbItemsByServiceType]);

    return (
        <LoadingSpinner size={75} label={label}/>
    )
}

export default RetrieveData;