import { useEffect, useState } from 'react';

import chimera from '../chimera';

function useSmartForm({initialState, apiBase}) {
    const [workingObj, setWorkingObj] = useState(initialState);
    const [modified, setModified] = useState(false);

    useEffect(() => {
        setWorkingObj(initialState);
    }, [initialState]);

    const handleChange = (e) => {
        if(e.target.type !== "checkbox") e.preventDefault();
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const type = e.target.type;
        let newWorkingObj = JSON.parse(JSON.stringify(workingObj));
        if(type === 'number') {
            chimera.setAttr(newWorkingObj, name, parseFloat(value));
        }
        else if(name.split('.')[name.split('.').length-1].toLowerCase().includes("phone")) {
            chimera.setAttr(newWorkingObj, name, value.replace(/\D/g, ''));
        }
        else {
            chimera.setAttr(newWorkingObj, name, value);
        }
        setModified(true);
        setWorkingObj(newWorkingObj);
    }

    const handleSave = (objToSave) => {
        return new Promise((resolve, reject) => {
            if(!objToSave) objToSave = workingObj;
            const isPost = objToSave._id ? false : true;
            chimera.callAPI(undefined, `${apiBase}${!isPost ? `/${objToSave._id}` : ''}`, isPost ? 'POST' : 'PUT', objToSave)
            .then(savedObj => {
                resolve(savedObj);
            })
            .catch(err => reject(err));
        })
    }

    return {
        workingObj,
        setWorkingObj,
        modified,
        setModified,
        handleChange,
        handleSave
    }
}

export default useSmartForm;