import { useState, useContext } from "react";
import FormField from "../FormField";
import chimera from "../../chimera";
import UserContext from "../../UserContext";

const NonpaymentNoticeForm = ({newStanding, customerId, locationIndex, onDone}) => {
    const [text, setText] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const userContext = useContext(UserContext);

    const handleChange = (event) => {
        event.preventDefault();
        if(event.target.name === "text") {
            setText(event.target.value);
        }
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const handleSave = (event) => {
        event.preventDefault();
        setIsSaving(true);
        chimera.callAPI(undefined, `/api/customers/${customerId}`, 'PUT', {standing: newStanding})
        .then(_ => {
            if(newStanding === 'bad') {
                // make note
                chimera.callAPI(undefined, '/api/notes', 'POST', {
                    refId: customerId,
                    subId: locationIndex,
                    title: "Notice of Nonpayment",
                    text: text,
                    author: {
                        first: userContext.user.first,
                        last: userContext.user.last,
                        email: userContext.user.email
                    },
                    groupsCanRead: [
                        'Engineer', 'Manager', 'Executive', 'Developer', 'Admin'
                    ],
                    pinned: true,
                    starred: true,
                    isFromAction: true,
                    categories: []
                })
                .then(_ => {
                    onDone(newStanding);
                })
                .catch(err => {
                    console.error(err);
                    alert("ERROR: Failed to save Note");
                })
            }
            else {
                // clear notes
                chimera.callAPI(undefined, `/api/notes/refId/${customerId}/subId/${locationIndex}/isFromAction`, 'DELETE')
                .then(_ => {
                    onDone(newStanding);
                })
                .catch(err => {
                    console.error(err);
                    alert("ERROR: Failed to clear Note(s); please manually delete the Notice of Nonpayment.");
                })
            }
        })
        .catch(err => {
            console.error(err);
            alert("ERROR: Failed to save Customer; the note will not be generated.");
        })
        .finally(() => {
            setIsSaving(false);
        })
    }

    return (
        <div>
            {newStanding === 'bad' ?
            <FormField
                type="textarea"
                name="text"
                label="Message"
                value={text}
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
                resize="vertical"
                fullWidth
            />
            :null}
            <button className="btn btn-primary" onClick={handleSave} disabled={isSaving}>
                <i className={isSaving ? 'fas fa-spinner' : 'fas fa-arrow-right'}/>&nbsp;{newStanding === 'bad' ? "Levy Bad Standing" : "Restore Good Standing"}
            </button>
        </div>
    )
}

export default NonpaymentNoticeForm;