import React, {useState, useContext} from "react";
import ToolPage from '../ToolPage';
import ScreenGroup from "../../ScreenGroup";
import useScreens from "../../../hooks/useScreens";
import MonthSelector from "../../MonthSelector";
import ButtonOptionList from "../../ButtonOptionList";
import BannerContext from "../../BannerLogContext";
import ManageEntries from "./ManageEntries";
import RetrieveData from "./RetrieveData";
import DownloadReport from "./DownloadReport";

const RevenueDeltaBody = ({}) => {
    const banners = useContext(BannerContext);
    const [month, setMonth] = useState('');
    const screenGroupProps = useScreens('START', ['START', 'SELECT_MONTH', 'RETRIEVE_DATA', 'DOWNLOAD_REPORT', 'MANAGE'], () => {
        setMonth('');
        banners.clearBanners();
    });
    const {setScreen} = screenGroupProps;

    return (
        <ScreenGroup {...screenGroupProps}>
            <ScreenGroup.Screen name='START' header="Choose an Option" hideBackBtn>
                <ButtonOptionList options={[
                    {
                        text: 'Run the Tool',
                        icon: 'fas fa-arrow-right',
                        func: (e) => {
                            e.preventDefault();
                            setScreen('SELECT_MONTH');
                        }
                    },
                    {
                        text: 'Download Report',
                        icon: 'fas fa-download',
                        func: (e) => {
                            e.preventDefault();
                            setScreen('DOWNLOAD_REPORT');
                        }
                    },
                    {
                        text: 'Manage Data',
                        icon: 'fas fa-gear',
                        func: (e) => {
                            e.preventDefault();
                            setScreen('MANAGE');
                        }
                    }
                ]}/>
            </ScreenGroup.Screen>
            <ScreenGroup.Screen name='SELECT_MONTH' header="Select the Month">
                <p className="fs-3"><MonthSelector relativeDefaultMonth={-1} setMonth={setMonth}/></p>
                <button className="btn btn-primary" onClick={(e) => {e.preventDefault(); setScreen('RETRIEVE_DATA')}}>
                    <i className="fas fa-arrow-right"/>&nbsp;Retrieve Data
                </button>
            </ScreenGroup.Screen>
            <ScreenGroup.Screen name='RETRIEVE_DATA' header="Retrieving Data...">
                <RetrieveData month={month}/>
            </ScreenGroup.Screen>
            <ScreenGroup.Screen name="DOWNLOAD_REPORT" header="Download Report">
                <DownloadReport/>
            </ScreenGroup.Screen>
            <ScreenGroup.Screen name="MANAGE" header="Manage Data">
                <ManageEntries/>
            </ScreenGroup.Screen>
        </ScreenGroup>
    )
}

const RevenueDeltaTool = ({}) => {
    const toolName = "Revenue Delta Report Generator";
    const toolId = "revenuedelta";
    return (
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image="/images/revenue_delta.png" alt="Revenue Delta image" toolName={toolName}>
                Generates a report that keeps a running tally on month-to-month revenue changes.
            </ToolPage.Header>
            <ToolPage.How>
                <h3>Methodology</h3>
                <p>
                    Chimera tracks individual data entries and this tool uses an algorithm to dynamically compose them together into a cohesive report.
                </p>
                <p>
                    When you <b>Run the Tool</b>, a series of queries are made according to the Month you are running it for.
                    The tool will fetch all (active) Chimera Customers that have active QuickBooks integrations. For each of these customers,
                    it will find all SalesReceipts created within the Month as well as all Invoices that were Paid within the Month. It will comb
                    through these transactions and total how much revenue is counted towards each Service Type. For detecting what amounts count towards
                    which Service Type, the tool uses the settings defined in the Admin Panel. All of this data is stored in the database as individual entries
                    that each contain the Customer, the Month it was ran for, and information on the applicable Invoices and SalesReceipts, including
                    the contributions per Service Type for each transaction.
                </p>
                <p>
                    If an entry for a given Customer and Month has already been created, it will either be updated (if the data has changed) or nothing will happen, preventing
                    the duplication of data. This means that you should feel free to rerun the tool as often as you need if anything goes wrong.
                </p>
                <p>
                    All of these entries can then be used by this tool to dynamically compose the final report. The logic is as follows:
                </p>
                <ul>
                    <li>For the first/earliest months, all revenue is established for the first time. You will see the initial totals for every customer, broken out by Service Type.</li>
                    <li>For every month afterwards, the report only includes relevant differences (deltas).</li>
                    <li>Deltas are calculated by subtracting the total of the current month from the total of the previous month, per Service Type.</li>
                    <li>Customers that don't have an entry in a given month are treated as having totals equal to 0.</li>
                    <li>Only changes are listed. If a Customer's total for a given Service Type is the same as the previous month, it is not listed.</li>
                </ul>
                <p>The Report is downloadable as an Excel spreadsheet.</p>
                <h3>Usage</h3>
                <p>
                    Your main options are to <b>Run the Tool</b>, <b>Download Report</b>, and <b>Manage Data</b>.
                </p>
                <h4>Run the Tool</h4>
                <p>
                    This is the main way to use the tool on a monthly basis. It will collect all new data and provide an updated report.
                </p>
                <ol>
                    <li>At the main menu, choose <b>Run the Tool</b>.</li>
                    <li>Select the <b>Month</b> and click <b>Retrieve Data</b>. This will generate entries according to the Methodology.</li>
                    <li>With the new data now saved to the database, you will be given the option to <b>Download Report</b>.</li>
                </ol>
                <h4>Download Report</h4>
                <p>
                    This option is for generating and downloading a report with only the current data, skipping the step of collecting new data.
                </p>
                <ol>
                    <li>At the main menu, choose <b>Download Report</b>.</li>
                    <li>Click <b>Download</b>.</li>
                    <li>On the first click, it will need to load and the report. This can take a little bit.</li>
                    <li>On secondary clicks, the report is already ready to download, so the download is instant.</li>
                </ol>
                <h4>Manage Data</h4>
                <p>
                    This lets you review the individual data entries that have been collected. You can also delete data entries this way, which will enable a redo.
                </p>
                <ol>
                    <li>At the main menu, choose <b>Manage Entries</b>.</li>
                    <li>Click on any of the rows to view more information about the data entry.</li>
                    <li>To delete entries, use the checkboxes to select one or more entries and then click <b>Actions &gt; Delete</b>.</li>
                </ol>
            </ToolPage.How>
            <ToolPage.Body>
                <RevenueDeltaBody/>
            </ToolPage.Body>
        </ToolPage>
    );
}

export default RevenueDeltaTool;